import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	TextField,
	styled,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {
	Formik,
	Form,
	Field,
	ErrorMessageProps,
	ErrorMessage,
} from 'formik';
import { useSelector } from 'react-redux';
import {
	RootState,
	postContact,
	store,
	updateVehicleForQuote,
	updateVehicleReuqestFormVisibility,
} from '../../redux';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '../../themes';
import { contactValidationSchema } from '../../helpers';
import { LoadingButton } from '@mui/lab';

export const QuoteRequestDialog = ({ open }) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const vehicleForQuote = useSelector(
		(state: RootState) =>
			state.generic.vehicles.vehicleForQuote,
	);

	const validationSchema = contactValidationSchema(
		prompts[currentLocale],
	);

	const handleClose = () => {
		store.dispatch(
			updateVehicleReuqestFormVisibility(false),
		);
		store.dispatch(updateVehicleForQuote(undefined));
	};
	const initialValues = {
		first_name: '',
		last_name: '',
		email: '',
		message: '',
		mystery: '',
	};

	const handleSubmit = async (
		values: typeof initialValues,
		{ resetForm },
	) => {
		const quoteDetails = {
			...values,
			message: `Ajánlatkérés: ${vehicleForQuote?.name}. Üzenet: ${values.message}`,
		};
		const { payload: response } = await store.dispatch(
			postContact(quoteDetails),
		);
		if (!response.error) {
			resetForm();
			handleClose();
		}
	};
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);

	// todo: refactor this
	const Error = styled((props: ErrorMessageProps) => (
		<ErrorMessage {...props} component="span" />
	))(() => ({
		marginTop: '8px',
		display: 'block',
		color: colors['canam-on-red'].main,
	}));
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				sx={{
					marginRight: 6,
					position: 'relative',
					paddingBottom: 1,
				}}
			>
				{prompts[currentLocale].text.get_a_quote}
				{': '}
				{vehicleForQuote?.name ?? ''}
				{isSmallScreen ? null : (
					<span
						style={{
							position: 'absolute',
							left: 24,
							bottom: 0,
							width: '100px',
							height: '4px',
							backgroundColor: colors['brp-yellow'].main,
							// marginLeft: 'calc(100% - 100px - 25%)',
						}}
					></span>
				)}
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={handleClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent
				sx={{
					width: { xs: '90%', md: 400 },
					padding: { xs: 1, md: 3 },
					margin: '0 auto',
				}}
			>
				<Formik
					initialValues={initialValues}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
				>
					{({ isSubmitting }) => (
						<Form>
							<Grid
								container
								columnGap={isSmallScreen ? 0 : 2}
								sx={{
									flexDirection: {
										xs: 'column',
										md: 'row',
									},
								}}
							>
								<Grid
									container
									flexDirection="column"
									style={{ flex: 1 }}
								>
									<Field
										as={TextField}
										autoFocus
										size="small"
										margin="dense"
										label={
											prompts[currentLocale].forms
												.first_name
										}
										placeholder={
											prompts[currentLocale].placeholder
												.first_name
										}
										fullWidth
										name="first_name"
									/>
									<Error name="first_name" />
								</Grid>
								<Grid
									container
									flexDirection="column"
									style={{ flex: 1 }}
								>
									<Field
										as={TextField}
										size="small"
										margin="dense"
										label={
											prompts[currentLocale].forms.last_name
										}
										placeholder={
											prompts[currentLocale].placeholder
												.last_name
										}
										fullWidth
										name="last_name"
									/>
									<Error name="last_name" />
								</Grid>
							</Grid>
							<Field
								as={TextField}
								margin="dense"
								size="small"
								label={prompts[currentLocale].forms.email}
								placeholder={
									prompts[currentLocale].placeholder.email
								}
								fullWidth
								name="email"
							/>
							<Error name="email" />
							<Field
								as={TextField}
								size="small"
								margin="dense"
								label={prompts[currentLocale].forms.message}
								placeholder={
									prompts[currentLocale].placeholder.message
								}
								multiline
								rows={4}
								fullWidth
								name="message"
							/>
							<Error name="message" />
							<Field
								name="mystery"
								style={{ display: 'none' }}
							/>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
								>
									{prompts[currentLocale].buttons.cancel}
								</Button>
								<LoadingButton
									type="submit"
									color="primary"
									variant="brp-yellow"
									loading={isSubmitting}
								>
									{prompts[currentLocale].buttons.submit}
								</LoadingButton>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</DialogContent>
		</Dialog>
	);
};
