import {
	createSelector,
	createSlice,
} from '@reduxjs/toolkit';
import { Order, RootState } from '../types';
import {
	loginUser,
	registerUser,
	loginViaToken,
	logout,
	getUserOrders,
	forgottenPassword,
	updateUser,
	passwordReset,
} from '../thunks';

const token = localStorage.getItem('userToken')
	? localStorage.getItem('userToken')
	: null;

type AuthState = {
	token: any;
	status: null | 'fulfilled' | 'loading' | 'failed';
	details: any;
	auth: any;
	orders: any;
	// object & {//(Order | undefined) & {
	// 	isBeingFetched: boolean;
	// };
	passwordResetResponse:
		| 'fulfilled'
		| 'loading'
		| 'rejected'
		| undefined;
	passwordReset:
		| 'fulfilled'
		| 'loading'
		| 'rejected'
		| undefined;
};
const initialState: AuthState = {
	token, // for storing the JWT
	status: null,
	orders: {
		items: null,
		isBeingFetched: false,
	},
	passwordResetResponse: undefined,
	passwordReset: undefined,
	details: null,
	auth: null,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setCredentials: (state, { payload }) => {
			state.auth = payload;
		},
		userLogout: (state) => {
			localStorage.removeItem('userToken');
			state.status = null;
			state.token = null;
			state.details = null;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(registerUser.pending, (authState) => {
				authState.status = 'loading';
			})
			.addCase(
				registerUser.fulfilled,
				(authState, { payload }) => {
					authState.status = 'fulfilled';
					// perhaps we shouldn't set this but
					// navigate user to login screen
					// authState.details = payload.data;
				},
			)
			.addCase(registerUser.rejected, (authState) => {
				authState.status = 'failed';
			})
			.addCase(passwordReset.pending, (authState) => {
				authState.passwordReset = 'loading';
			})
			.addCase(passwordReset.fulfilled, (authState) => {
				authState.passwordReset = 'fulfilled';
			})
			.addCase(passwordReset.rejected, (authState) => {
				authState.passwordReset = 'rejected';
			})
			.addCase(
				loginUser.fulfilled,
				(authState, { payload }) => {
					authState.status = 'fulfilled';
					authState.token = payload.access_token;
					authState.details = payload.user;
				},
			)
			.addCase(
				updateUser.fulfilled,
				(authState, { payload }) => {
					authState.status = 'fulfilled';
					authState.details = payload.data;
				},
			)
			.addCase(
				loginViaToken.fulfilled,
				(authState, { payload }) => {
					authState.status = 'fulfilled';
					authState.token = payload.access_token;
					authState.details = payload.data;
				},
			)
			.addCase(logout.fulfilled, (authState) => {
				authState.status = null;
				authState.token = null;
				authState.details = null;
				authState.orders = {
					items: null,
					isBeingFetched: false,
				};
			})
			.addCase(logout.rejected, (authState) => {
				authState.status = null;
				authState.token = null;
				authState.details = null;
				authState.orders = {
					items: null,
					isBeingFetched: false,
				};
			})
			// means that it was at least initiated, so reset
			.addCase(logout.pending, (authState) => {
				authState.status = null;
				authState.token = null;
				authState.details = null;
				authState.orders = {
					items: null,
					isBeingFetched: false,
				};
			})
			// todo: maybe move this to userSlice?
			.addCase(getUserOrders.rejected, (authState) => {
				authState.orders = {
					items: null,
					isBeingFetched: false,
				};
			})
			.addCase(getUserOrders.pending, (authState) => {
				authState.orders = {
					...authState.orders,
					isBeingFetched: true,
				};
			})
			.addCase(
				getUserOrders.fulfilled,
				(authState, { payload }) => {
					authState.orders = {
						items: payload.data,
						isBeingFetched: false,
					};
				},
			)
			.addCase(forgottenPassword.rejected, (authState) => {
				authState.passwordResetResponse = 'rejected';
			})
			.addCase(forgottenPassword.pending, (authState) => {
				authState.passwordResetResponse = 'loading';
			})
			.addCase(forgottenPassword.fulfilled, (authState) => {
				authState.passwordResetResponse = 'fulfilled';
			});
	},
});

const getAccountDetails = (state: RootState) => {
	const details = state.auth?.details;
	return details;
};
const userOrdersSelector = (state: RootState) => {
	const details = state.auth?.orders.items;
	return details;
};
export const isUserLoggedIn = createSelector(
	[getAccountDetails],
	(details) => !!details?.id,
);

export const selectUserOrders = createSelector(
	[userOrdersSelector],
	(orders) => orders,
);
export const selectAccountDetails = createSelector(
	[getAccountDetails],
	(details) => details,
);

export const { userLogout, setCredentials } =
	authSlice.actions;

export const authReducer = authSlice.reducer;
