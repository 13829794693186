import {
	Box,
	Button,
	Container,
	Grid,
	List,
	ListItem,
	Tab,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useEffect, useState } from 'react';
import {
	Product as ProductType,
	selectProducts,
	RootState,
	selectProductImages,
	fetchProduct,
	store,
	selectCurrentProduct,
	calculateDiscountPrice,
	getImagesForProduct,
	getProduct,
	updateCurrentProductBasedOnVariant,
	updateCurrentProduct,
} from '../../../redux';
import { colors } from '../../../themes';
import {
	AddToCart,
	Gallery,
	Heading,
	ShopNow,
	QuantityButtons,
	ProductSkeleton,
	ProductCard,
} from '../../../components';
import {
	Link,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { YouMayLike } from '../components';
import brpWorld_thumbnail from '../../../assets/brp.png';
import { VariantDropdown } from '../VariantDropdown';
import { parseHtml } from '../../helpers';
import { formatPriceToForint } from '../../../helpers';

const Variants = ({ variants }) => {
	const variantItems = variants.map((variant) => {
		return (
			<Link
				key={variant.id}
				to={`/shop/products/${variant.id}`}
			>
				<img
					style={{
						width: '48px',
						height: '48px',
						objectFit: 'contain',
					}}
					src={
						variant?.main_image?.src ?? brpWorld_thumbnail
					}
					alt="Variant"
				/>
			</Link>
		);
	});
	return (
		<Grid container marginTop={1} rowGap={1} columnGap={2}>
			{variantItems}
		</Grid>
	);
};

export const Product = () => {
	const productsState = useSelector(
		(state: RootState) => state.products,
	);
	const isFetching =
		productsState.currentProduct.isBeingFetched;
	const productToSwitchTo =
		productsState.currentProduct.variantSelected;

	const navigate = useNavigate();
	const theme = useTheme();
	const smallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const { state } = useLocation();
	const currentProduct: any = useSelector(
		selectCurrentProduct,
	).details;

	const url = window.location.href;
	const idParam = url.match(/\/(\d+)(?:\/|$)/);
	let id = state?.productId;
	if (!id && idParam && idParam.length > 0) {
		id = idParam[1];
	}

	useEffect(() => {
		if (!isNaN(id)) {
			const productInState = productsState.items.find(
				(item) => item.id === parseInt(id),
			);
			if (!productInState) {
				store.dispatch(
					fetchProduct(parseInt(id) ?? currentProduct?.id),
				);
			}
			if (productInState) {
				store.dispatch(
					updateCurrentProduct(productInState),
				);
			}
		}
	}, [id]);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);

	const galleryImages = getImagesForProduct(currentProduct);
	const sizeGuideUrl = useSelector(
		(state: RootState) =>
			state.generic.settings.details?.size_chart?.src,
	);
	const [tabContextValue, setTabContextValue] =
		useState('1');
	const handleChange = (
		event: React.SyntheticEvent,
		newTabContextValue: string,
	) => {
		setTabContextValue(newTabContextValue);
	};

	return isFetching ? (
		<ProductSkeleton />
	) : Object.keys(currentProduct)?.length > 1 ? (
		<Container
		//  style={{ width: '100%', padding: 0 }}
		>
			<Grid
				container
				style={{
					width: '100%',
					//  padding: 0, margin: 0
				}}
				sx={{ marginTop: { xs: 2, md: 0 } }}
			>
				<Link
					to="/shop/products"
					style={{
						width: 'max-content',
						paddingLeft: 6,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<FontAwesomeIcon
						icon={faAngleLeft}
						size="1x"
						color={colors['brp-black'].main}
						// style={{ fontSize: 16 }}
						// className={styles.icon}
					/>
					<Typography
						gutterBottom
						variant="h6"
						component="p"
						sx={{
							margin: 0,
							marginLeft: '4px',
							marginBottom: '-5px',
						}}
					>
						{prompts[currentLocale].buttons.back}
					</Typography>
				</Link>
				<Typography
					gutterBottom
					variant="h6"
					component="p"
					color={colors['brp-black'].main}
					sx={{
						width: 'max-content',
						margin: 0,
						marginLeft: 2,
						marginBottom: '-5px',
					}}
				>
					{currentProduct.category.full_path}
				</Typography>
			</Grid>
			<Grid
				container
				maxWidth="lg"
				spacing={1}
				sx={{
					display: 'flex',
					// margin: '0 auto',
					padding: { md: 6 },
					justifyContent: 'space-between',
				}}
			>
				<Grid
					item
					xs={12}
					md={7}
					sx={{
						minHeight: '30vh',
						height: 'max-content',
						justifyContent: 'center',
						alignItems: 'flex-start',
					}}
				>
					<Gallery
						items={galleryImages}
						showPlayButton={false}
						showFullscreenButton={false}
						thumbnailPosition={
							smallScreen ? 'bottom' : 'left'
						}
					/>
				</Grid>
				<Grid
					xs={12}
					md={5}
					item
					sx={{
						height: 'max-content',
						padding: 2,
						justifyContent: 'center',
						alignItems: 'flex-start',
					}}
				>
					<Grid container sx={{ flexDirection: 'column' }}>
						<Grid container justifyContent="space-between">
							<Typography
								component="p"
								color={
									currentProduct?.available_stock > 0 &&
									productToSwitchTo?.id !== -1
										? colors['green'].main
										: 'error'
								}
								sx={{ textTransform: 'capitalize' }}
							>
								•{' '}
								{currentProduct?.available_stock > 0 &&
								productToSwitchTo?.id !== -1
									? prompts[currentLocale].shop.in_stock
									: prompts[currentLocale].shop
											.out_of_stock}
							</Typography>
							<Link
								to={sizeGuideUrl}
								target="_blank"
								rel="noopener noreferrer"
							>
								{prompts[currentLocale].text.size_guide}
							</Link>
						</Grid>
						<Typography component="h2" variant="h4">
							{currentProduct?.name}
						</Typography>
						<Typography component="p">
							{currentProduct?.catalogue_number}
						</Typography>
						<section>
							{parseHtml(currentProduct?.description)}
						</section>
						{currentProduct?.variants &&
						currentProduct.variants.length > 0 ? (
							<Variants
								variants={currentProduct.variants}
							/>
						) : null}
						<Grid container sx={{ my: 2 }}>
							<VariantDropdown
								variants={currentProduct.variants}
								attributes={currentProduct.attributes}
								locale={currentLocale}
							/>
						</Grid>
						<Grid
							container
							style={{
								padding: 0,
								justifyContent: 'space-between',
							}}
						>
							{currentProduct?.active_discount ? (
								<Grid
									container
									justifyContent="space-between"
									marginTop={1}
									width="100%"
									flexWrap="wrap"
								>
									<Grid
										container
										flex={1}
										alignItems="center"
									>
										<Typography
											variant="h4"
											component="h2"
											style={{ marginRight: '10%' }}
										>
											{calculateDiscountPrice(
												currentProduct,
											)}
										</Typography>
										<Typography
											variant="h5"
											component="h2"
											style={{
												textDecoration: 'line-through',
												// marginTop: 'auto',
												color: colors['brp-grey'].faded,
												padding: 0,
											}}
										>
											{currentProduct.formatted?.price ??
												currentProduct.price}
										</Typography>
									</Grid>
									{(currentProduct.current_price_vat_inclusive ||
										currentProduct.price_vat_inclusive) &&
									currentProduct?.active_discount ? (
										<Typography
											variant="h6"
											component="span"
										>
											{prompts[currentLocale].shop.vat}:{' '}
											{currentProduct?.formatted
												?.current_price_vat_inclusive ??
												currentProduct.current_price_vat_inclusive}
										</Typography>
									) : (
										<Typography
											variant="h6"
											component="span"
										>
											({prompts[currentLocale].shop.vat}:
											{currentProduct?.formatted
												?.current_price_vat_inclusive ??
												currentProduct.current_price_vat_inclusive}{' '}
											Ft)
										</Typography>
									)}
								</Grid>
							) : (
								<>
									<Typography
										variant="h4"
										component="h2"
										style={{ marginTop: '8px' }}
									>
										{formatPriceToForint(
											currentProduct.price,
										)}
									</Typography>
									{currentProduct.current_price_vat_inclusive ||
									(currentProduct.price_vat_inclusive &&
										currentProduct?.active_discount) ? (
										<Typography
											variant="h6"
											component="span"
										>
											{prompts[currentLocale].shop.vat}:{' '}
											{currentProduct?.formatted
												?.current_price_vat_inclusive ??
												currentProduct.current_price_vat_inclusive}
										</Typography>
									) : null}
								</>
							)}
						</Grid>
						{currentProduct?.available_stock > 0 &&
						productToSwitchTo?.id !== -1 ? (
							<Container
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									padding: 0,
									marginTop: 16,
								}}
							>
								<AddToCart id={currentProduct?.id} />
								<QuantityButtons
									style={{ height: 'max-content' }}
									currentQuantity={currentProduct?.quantity}
									// todo: this id shouldn't be optional
									productId={currentProduct?.id}
								/>
								{/* <ShopNow /> */}
							</Container>
						) : null}
					</Grid>
				</Grid>
			</Grid>
			<Container maxWidth="lg">
				<TabContext value={tabContextValue}>
					<Box
						sx={{
							borderBottom: 1,
							borderColor: 'divider',
						}}
					>
						<TabList
							onChange={handleChange}
							// aria-label="lab API tabs example"
						>
							<Tab
								label={
									prompts[currentLocale].text.description
								}
								value="1"
							/>
							<Tab
								label={
									prompts[currentLocale].text.specification
								}
								value="2"
							/>
						</TabList>
					</Box>
					<TabPanel value="1">
						<Typography
							maxWidth="sm"
							variant="body1"
							sx={{ padding: 0 }}
							color="brp-black"
						>
							{/* 
                            maybe have a breaking value like
                            \n that creates a new typography element 
                            */}
							{currentProduct.category?.description ||
							currentProduct.category?.description
								.length === 0
								? prompts[currentLocale].text.coming_soon
								: parseHtml(
										currentProduct.category.description,
								  )}
						</Typography>
					</TabPanel>
					<TabPanel value="2">
						<Grid container>
							{currentProduct.attributes.length > 0 ? (
								currentProduct.attributes.map(
									(currentAttribute) => {
										return (
											// <Grid item md={3}>
											<ListItem
												key={currentAttribute.id}
												sx={{
													mb: 1,
													justifyContent: {
														xs: 'space-between',
														md: 'flex-start',
													},
													padding: 0,
												}}
											>
												<Grid item xs={4} md={2}>
													<Typography
														maxWidth="sm"
														variant="body2"
														sx={{
															padding: 0,
															// my: 1,
															// mr: 1,
															fontWeight: 'bold',
														}}
														color="brp-black"
													>
														{
															currentAttribute.attribute
																.label[currentLocale]
														}
													</Typography>
												</Grid>

												<Grid item xs={6} md={8}>
													<Typography
														maxWidth="sm"
														variant="body2"
														sx={{
															padding: 0,
														}}
														color="brp-black"
													>
														{typeof currentAttribute.value ===
														'string'
															? currentAttribute.value
															: currentAttribute.value[
																	currentLocale
															  ]}
													</Typography>
												</Grid>
											</ListItem>
											// </Grid>
										);
									},
								)
							) : (
								<Typography
									maxWidth="sm"
									variant="body1"
									sx={{ paddingBottom: 0 }}
									color="brp-black"
								>
									{
										prompts[currentLocale].text
											.no_specification
									}
								</Typography>
							)}
						</Grid>
					</TabPanel>
				</TabContext>
			</Container>
			<YouMayLike />
		</Container>
	) : (
		<Grid
			container
			flexDirection="column"
			alignItems="center"
			paddingTop={2}
			paddingBottom={4}
		>
			<Typography
				variant="h5"
				component="p"
				sx={{
					textTransform: 'uppercase',
					color: colors['brp-black'].main,
					marginBottom: '16px',
					position: 'relative',
					width: 'max-content',
				}}
			>
				{prompts[currentLocale].shop.product_not_found}
			</Typography>
			<Button
				variant="brp-yellow"
				onClick={() => navigate('/shop/products')}
			>
				{prompts[currentLocale].shop.back_to_shop}
			</Button>
		</Grid>
	);
};
