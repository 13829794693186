import { Container, Grid, Skeleton } from '@mui/material';

export const FilterSkeleton = () => {
	return (
		<Container>
			<Skeleton
				variant="rectangular"
				width="100%"
				height={40}
				sx={{ marginTop: 2 }}
			/>
			<Skeleton
				variant="rectangular"
				width="100%"
				height={40}
				sx={{ marginTop: 2 }}
			/>
			<Skeleton
				variant="rectangular"
				width="100%"
				height={40}
				sx={{ marginTop: 2 }}
			/>
			<Skeleton
				variant="rectangular"
				width="100%"
				height={40}
				sx={{ marginTop: 2 }}
			/>
			<Grid
				container
				sx={{ marginTop: 2 }}
				alignItems="center"
			>
				<Skeleton
					variant="circular"
					// width="5%"
					width={16}
					height={16}
				/>
				<Skeleton
					variant="rectangular"
					width="auto"
					sx={{ flex: 1 }}
					height={4}
				/>
				<Skeleton
					variant="circular"
					width={16}
					height={16}
				/>
			</Grid>
			<Grid
				container
				marginTop={4}
				justifyContent="space-between"
			>
				<Skeleton
					variant="rectangular"
					width="45%"
					height={40}
				/>
				<Skeleton
					variant="rectangular"
					width="45%"
					height={40}
				/>
			</Grid>
		</Container>
	);
};
