import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
	Home,
	Contact,
	NotFound,
	Login,
	Signup,
	VirtualRoom,
	Shop,
	Blog,
	Services,
	ComingSoon,
	Product,
	Cart,
	Partners,
	Impressum,
	Checkout,
	Account,
	OrderHistory,
	DeliveryAddresses,
	AccountDetails,
	Completion,
	ForgottenPassword,
	PartsFinder,
	AccountActivation,
	Overview,
	Dynamic,
	Vehicle,
	Grants,
	PasswordReset,
	NewsletterUnsubscribe,
} from '../../views';
import { Layout } from '..';
import {
	DynamicPage,
	RootState,
	selectAccountDetails,
} from '../../redux';
import { useSelector } from 'react-redux';

export const RouteHandler = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const dynamicPages: DynamicPage[] = useSelector(
		(state: RootState) =>
			state.locale.languages?.[currentLocale].pages,
	);

	const accountDetails = useSelector(selectAccountDetails);
	const userIsLoggedIn = !!accountDetails?.id;
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route path="/" element={<Home />} />
				{/* <Route path="services" element={<Services />} /> */}
				<Route path="search" element={<Home />} />
				<Route path="grants" element={<Grants />} />
				<Route path="sponsors" element={<Grants />} />
				<Route path="checkout" element={<Checkout />} />
				<Route
					path="checkout/payment"
					element={<Checkout />}
				/>
				<Route path="blog" element={<Blog />} />
				<Route path="shop" element={<Shop />} />
				{/* <Route
					path="shop/vehicles"
					element={<Shop isVehicles />}
				/> */}
				<Route path="shop/vehicles">
					<Route
						index
						element={<Shop isVehicles={true} />}
					/>
					<Route path=":id" element={<Vehicle />} />
				</Route>
				<Route
					path="shop/categories"
					element={<Overview />}
				/>
				{/* <Route
					path="product"
					element={<Product  />}
				/> */}
				<Route path="shop/products">
					<Route index element={<Shop />} />
					<Route path=":id" element={<Product />} />
				</Route>
				<Route path="services" element={<Services />} />
				<Route
					path="password-reset"
					element={<PasswordReset />}
				/>
				<Route
					path="newsletter-unsubscribe"
					element={<NewsletterUnsubscribe />}
				/>
				<Route
					path="forgotten-password"
					element={<ForgottenPassword />}
				/>
				{/* overrides */}
				<Route path="partners" element={<Partners />} />
				<Route path="contact" element={<Contact />} />
				{dynamicPages.map((page: DynamicPage) => {
					return (
						<Route
							key={page.slug}
							path={page.slug}
							element={<Dynamic page={page} />}
						/>
					);
				})}
				{/* todo: should this be disabled if user is logged in? */}
				<Route
					path="account-activation"
					element={<AccountActivation />}
				/>
				<Route
					path="login"
					element={
						userIsLoggedIn ? (
							<Navigate to="/account" />
						) : (
							<Login />
						)
					}
				/>

				<Route
					path="register"
					element={
						userIsLoggedIn ? (
							<Navigate to="/account" />
						) : (
							<Signup />
						)
					}
				/>

				{/* <Route
					path="terms-of-use"
					element={<ComingSoon />}
				/> */}
				{/* <Route
					path="privacy-policy"
					element={<ComingSoon />}
				/> */}
				<Route
					path="virtual-tour"
					element={<VirtualRoom />}
				/>
				<Route
					path="parts-finder"
					element={<PartsFinder />}
				/>
				<Route path="completion" element={<Completion />} />
				<Route path="cart" element={<Cart />} />

				{/*
                todo: 
                on redirect login may
                not be displayed straight away
                */}
				<Route
					path="account"
					element={
						userIsLoggedIn ? (
							<Account />
						) : (
							<Navigate to="/login" />
						)
					}
				>
					{/* <Route index path="account" element={<Account />}> */}
					<Route
						path="order-history"
						element={<OrderHistory />}
					/>
					<Route
						path="delivery-details"
						element={<DeliveryAddresses />}
					/>
					<Route index element={<AccountDetails />} />
					<Route
						index
						path="details"
						element={<AccountDetails />}
					/>
				</Route>

				{/* Fallback */}
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
};

// export default RouteHandler;
