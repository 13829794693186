import React from 'react';
import {
	Grid,
	IconButton,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
	RootState,
	cancelPayment,
	store,
} from '../../../../redux';
import { RenderDetails } from './RenderDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../../../themes';
import EditIcon from '@mui/icons-material/Edit';
import { doScrollToTop } from '../../../../helpers';

export const BillingSummary = () => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const checkoutDetails = useSelector(
		(state: RootState) => state.checkout,
	);

	const billingDetails =
		checkoutDetails.orderUserResponse?.billing_address;
	const { tax_number, isBusiness, company_name } =
		checkoutDetails.business;

	const { name } = checkoutDetails.orderUserResponse;

	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);

	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);

	const translations = prompts[currentLocale];
	const businessDetails =
		isBusiness && tax_number.vatExempt
			? {
					tax_number: tax_number.value,
					company_name,
			  }
			: undefined;
	const navigateBack = () => {
		store.dispatch(cancelPayment());
		doScrollToTop();
	};
	return (
		<Paper
			variant="outlined"
			square
			sx={
				isSmallScreen
					? { padding: 2, border: 'none' }
					: {
							marginBottom: 4,
							padding: 4,
							display: 'flex',
							flexDirection: 'column',
							flex: 1,
					  }
			}
		>
			<Grid
				alignItems="center"
				container
				sx={{ marginBottom: 2 }}
			>
				<FontAwesomeIcon
					icon={faFileInvoice}
					style={{
						padding: 8,
						marginRight: 8,
						background: colors['brp-yellow'].main,
					}}
				/>
				<Typography
					variant="body1"
					component="span"
					sx={{
						textTransform: 'capitalize',
						color: colors['brp-black'].main,
					}}
					marginBottom="-5px"
				>
					{translations.navigation.billing_details}
				</Typography>
				<IconButton
					sx={{ marginLeft: 'auto' }}
					onClick={navigateBack}
				>
					<EditIcon />
				</IconButton>
			</Grid>
			<RenderDetails
				title={null}
				payload={{
					name,
					company_name: businessDetails?.company_name,
					...billingDetails,
					country: billingDetails?.country.name,
					tax_number: businessDetails?.tax_number,
				}}
			/>
		</Paper>
	);
};
