import {
	Button,
	Checkbox,
	Dialog,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { colors } from '../../../themes';
import {
	billingDetailsValidationSchema,
	deliveryDetailsValidationSchema,
	doScrollToTop,
	phoneNumberValidation,
} from '../../../helpers';
import {
	CartSummary,
	CartSummarySection,
	EmptyCart,
} from '../cart-page';
import {
	PersonalDetails,
	DeliveryDetails,
	PaymentDetails,
} from '..';
import {
	getIsBillingSame,
	personalDetailsForOrderUser,
	store,
	DeliveryDetails as DeliveryDetailsType,
	prepOrder,
	getItemsInCart,
	postOrder,
	getCurrentOrder,
	setCheckoutLoading,
	getIsCheckoutLoading,
	selectAccountDetails,
	getNumberOfItemsInCart,
	getSelectedDelivery,
	deliveryDetailsForOrderUser,
	billingDetailsForOrderUser,
	RootState,
	fetchCountries,
	validateTaxNumber,
	isUserLoggedIn,
} from '../../../redux';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { personalDetailsValidationSchema } from '../../../helpers';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { CheckoutAs, DetailsSummary } from './components';
import { GridCloseIcon } from '@mui/x-data-grid';
import {
	convertLockerToShippingAddress,
	getLocation,
	placeOrder,
	scrollToElementWhenErrors,
} from './helpers';
import { useFormik } from 'formik';
import { ConfirmDetails } from './components';

type PersonalDetails = {
	first_name?: string;
	last_name?: string;
	phone_number?: string;
	email?: string;
};

export const Checkout = () => {
	const navigate = useNavigate();
	const [continueAsGuest, setContinueAsGuest] =
		useState(null);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const shouldSubscribe = useSelector(
		(state: RootState) =>
			state.checkout.shouldSubscribe ?? false,
	);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const personalDetailsRef = useRef();
	const deliveryDetailsRef = useRef();
	const billingDetailsRef = useRef();
	const isCheckoutLoading = useSelector(
		getIsCheckoutLoading,
	);
	const userIsLoggedIn = useSelector(isUserLoggedIn);
	const numOfItemsInCart = useSelector(
		getNumberOfItemsInCart,
	);
	const accountDetails = useSelector(selectAccountDetails);
	const businessDetails = useSelector(
		(state: RootState) => state.checkout.business,
	);
	const selectedLocker = useSelector(
		(state: RootState) =>
			state.checkout.parcelShops.selected,
	);
	const deliveryDetails = useSelector(
		(state: RootState) => state.checkout.deliveryDetails,
	);
	const discount = useSelector(
		(state: RootState) => state.checkout.discount,
	);
	const anOrderHasBeenInitiated = useSelector(
		(state: RootState) => state.checkout?.orderUserResponse,
	);
	// shipping is same as billing
	const isBillingSame = useSelector(getIsBillingSame);
	const currentOrder = useSelector(getCurrentOrder);
	const selectedDelivery = useSelector(getSelectedDelivery);
	const orderUserDetails = useSelector(
		(state: RootState) => state.checkout.orderUserResponse,
	);
	const isOnlinePayment =
		!selectedDelivery?.is_cod &&
		!selectedDelivery?.is_locker;
	const isPaymentReady = isOnlinePayment
		? currentOrder?.payment?.service_payment_id
		: orderUserDetails?.id !== undefined;

	const cartItems = useSelector(getItemsInCart);
	useEffect(() => {
		// doScrollToTop();
		//status check is protecting against returning to shop after placing an order
		isPaymentReady && anOrderHasBeenInitiated
			? // perhaps we need to pass state back and forth
			  // so that details don't need to be re-entered again
			  navigate('/checkout/payment')
			: navigate('/checkout');
	}, [isPaymentReady, anOrderHasBeenInitiated]);
	// console.log(areFieldsSet);
	// todo: replace with a selector for order details
	// and if present, enable payment button

	const ddFormik = useFormik({
		initialValues: {
			shipping: {
				street: '',
				street_extra: '',
				city: '',
				post_code: '',
				state: '',
				phone_number: '',
				country: {
					id: 101,
					name: 'Hungary',
					code: 'HU',
				},
				delivery_method_id: '',
			},
		},
		validationSchema: isBillingSame
			? phoneNumberValidation(prompts, currentLocale)
			: deliveryDetailsValidationSchema(
					prompts,
					currentLocale,
					selectedDelivery?.is_locker ||
						selectedDelivery?.is_cod,
			  ),
		onSubmit: () => {
			console.log('here in ddformik');
		},
	});

	const bdFormik = useFormik({
		initialValues: {
			billing: {
				street: '',
				street_extra: '',
				city: '',
				post_code: '',
				state: '',
				country: {
					id: 101,
					name: 'Hungary',
					code: 'HU',
				},
			},
		},
		validationSchema: billingDetailsValidationSchema(
			prompts,
			currentLocale,
		),
		onSubmit: () => {
			// console.log('here in bdformik');
		},
	});

	const pdFormik = useFormik({
		initialValues: {
			first_name: accountDetails?.first_name ?? '',
			last_name: accountDetails?.last_name ?? '',
			email: accountDetails?.email ?? '',
			phone_number: accountDetails?.phone_number ?? '',
			tax_number: businessDetails?.tax_number.value ?? '',
			company_name: businessDetails?.company_name ?? '',
		},
		validationSchema: personalDetailsValidationSchema(
			prompts,
			currentLocale,
			businessDetails.isBusiness,
		),
		onSubmit: (formData) => {
			store.dispatch(personalDetailsForOrderUser(formData));
		},
	});

	const validateForms = async () => {
		let errors = {};
		const arePDready = await pdFormik.validateForm();
		const areBDready = await bdFormik.validateForm();
		const areDDready = await ddFormik.validateForm();
		if (Object.keys(areBDready).length > 0) {
			// return areBDready;
			errors = {
				...errors,
				areBDready,
			};
		}
		if (Object.keys(arePDready).length > 0) {
			errors = {
				...errors,
				arePDready,
			};
		}
		// todo: DD validation needs to be better
		// currently if isBillingSame is true we're fine but
		// the phone_number validation will show above shipping
		// input fields too, not just above phone number
		// when it's missing - refer to screenshot
		// 'phone_number_validation'
		if (Object.keys(areDDready).length > 0) {
			errors = {
				...errors,
				areDDready,
			};
		}
		if (!isBillingSame) {
			ddFormik.values = {
				...ddFormik.values,
				// @ts-ignore
				delivery_method_id: selectedDelivery?.id,
			};
			const areDDready = await ddFormik.validateForm();
			if (Object.keys(areDDready).length > 0) {
				// return areDDready;
				errors = {
					...errors,
					areDDready,
				};
			}
		}
		return errors;
	};

	// todo: this is a bit shitty
	const handleNextStep = async () => {
		store.dispatch(setCheckoutLoading(true));
		const validated = await validateForms();
		if (Object.keys(validated).length > 0) {
			scrollToElementWhenErrors({ errors: validated });
			store.dispatch(setCheckoutLoading(false));
			return;
		} else {
			store.dispatch(
				personalDetailsForOrderUser(pdFormik.values),
			);
			store.dispatch(
				deliveryDetailsForOrderUser(ddFormik.values),
			);
			store.dispatch(
				billingDetailsForOrderUser(bdFormik.values),
			);

			const { checkout } = store.getState() as RootState;
			const shippingDetails = isBillingSame
				? checkout.billingDetails
				: checkout.deliveryDetails;
			const phone_number =
				checkout.deliveryDetails.phone_number;

			const orderDetails = {
				shipping: selectedDelivery?.is_locker
					? convertLockerToShippingAddress({
							lockerDetails: selectedLocker,
					  })
					: {
							...shippingDetails,
							country: checkout?.deliveryDetails?.countryId,
					  },
				billing: {
					...checkout.billingDetails,
					country: checkout?.billingDetails?.countryId,
				},
				name: businessDetails.isBusiness
					? checkout?.personalDetails?.company_name ===
							'' ||
					  checkout?.personalDetails?.company_name ===
							undefined
						? null
						: checkout?.personalDetails?.company_name
					: `${checkout.personalDetails.last_name} ${checkout.personalDetails.first_name}`,
				email: checkout.personalDetails.email,
				phone_number:
					phone_number ??
					shippingDetails.phone_number ??
					null,
				tax_number:
					checkout?.personalDetails?.tax_number === '' ||
					checkout?.personalDetails?.tax_number ===
						undefined
						? null
						: checkout?.personalDetails?.tax_number.replace(
								/-/g,
								'',
						  ),
			};

			if (businessDetails.isBusiness) {
				const { payload } = await store.dispatch(
					validateTaxNumber({
						tax_number: orderDetails.tax_number,
						company_name: orderDetails.name,
					}),
				);
				// todo: enhance this once issue is resolved
				if (payload === 'invalid') {
					store.dispatch(setCheckoutLoading(false));
					return;
				}
			}
			if (orderDetails) {
				const { payload } = await store.dispatch(
					prepOrder(orderDetails),
				);
				if (
					!selectedDelivery?.is_pickup &&
					!selectedDelivery?.is_cod
				) {
					await placeOrder({
						orderUserDetails: payload.data,
						cartItems,
						selectedDelivery,
						shouldSubscribe,
						selectedLocker,
						deliveryDetails,
						phone_number,
						promo_code: discount?.code,
					});
				}
			}
			store.dispatch(setCheckoutLoading(false));
		}
		store.dispatch(setCheckoutLoading(false));
	};

	const [isCartSummaryOpen, setIsCartSummaryOpen] =
		useState(false);
	const toggleCartSummaryDialog = () => {
		setIsCartSummaryOpen((prevValue) => !prevValue);
	};

	const { items: countries } = useSelector(
		(state: RootState) => state.checkout.countries,
	);

	useEffect(() => {
		if (!countries) {
			store.dispatch(fetchCountries());
		}
	}, []);

	useEffect(() => {
		if (selectedDelivery?.is_locker) {
			getLocation();
		}
	}, [selectedDelivery]);

	return numOfItemsInCart > 0 ? (
		<Grid
			container
			maxWidth="lg"
			columnSpacing={isSmallScreen ? 0 : 2}
			margin="0 auto"
			width="100%"
			justifyContent="space-between"
		>
			<Typography
				variant="h5"
				component="h2"
				sx={{
					textTransform: 'uppercase',
					color: colors['brp-black'].main,
					marginBottom: isSmallScreen ? 2 : '16px',
					marginTop: isSmallScreen ? 2 : 0,
					width: isSmallScreen ? 'max-content' : '100%',
					paddingX: 2,
				}}
			>
				{continueAsGuest
					? prompts[currentLocale].shop.checkout_as_guest
					: prompts[currentLocale].shop.checkout}
			</Typography>

			{isSmallScreen ? (
				<>
					<Grid
						width="max-content"
						alignItems="center"
						container
						paddingX={2}
					>
						<Button
							onClick={toggleCartSummaryDialog}
							variant="brp-yellow"
							component="h5"
						>
							See my cart
						</Button>
					</Grid>
					<Dialog
						open={isCartSummaryOpen}
						onClose={toggleCartSummaryDialog}
						fullScreen
					>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={toggleCartSummaryDialog}
								aria-label="close"
							>
								<GridCloseIcon />
							</IconButton>
						</Toolbar>
						<CartSummary
							isCheckout={true}
							disableQuantityButtons={!!isPaymentReady}
						/>
					</Dialog>
				</>
			) : isPaymentReady ? null : (
				<Grid item md={4} order={2}>
					<CartSummary
						isCheckout={true}
						disableQuantityButtons={!!isPaymentReady}
					/>
				</Grid>
			)}
			<Grid
				item={isSmallScreen ? false : true}
				// item
				md={8}
				xs={12}
				order={1}
				sx={{
					marginBottom: 4,
					// paddingLeft: isSmallScreen ? 0 : 2,
				}}
			>
				{isPaymentReady ? (
					<>
						<DetailsSummary />
						<CartSummarySection />
						{currentOrder?.payment?.service_payment_id ? (
							<Grid container flexDirection="column">
								<ConfirmDetails
									isOnlinePayment={isOnlinePayment}
									showButton={false}
								/>
								<PaymentDetails />
							</Grid>
						) : (
							<ConfirmDetails showButton />
						)}
					</>
				) : (
					<>
						{userIsLoggedIn || continueAsGuest ? null : (
							<CheckoutAs
								setContinueAsGuest={setContinueAsGuest}
							/>
						)}
						<PersonalDetails
							pdFormik={pdFormik}
							ref={personalDetailsRef}
							prompts={prompts}
							currentLocale={currentLocale}
						/>
						{/* Billing */}
						<DeliveryDetails
							isDelivery={false}
							isCheckout={true}
							ddFormik={bdFormik}
							ref={billingDetailsRef}
							prompts={prompts}
							currentLocale={currentLocale}
						/>

						{/* Delivery */}
						<DeliveryDetails
							isLocker={
								selectedDelivery?.is_locker ?? false
							}
							isCod={selectedDelivery?.is_cod ?? false}
							isDelivery={true}
							isCheckout={true}
							ddFormik={ddFormik}
							ref={deliveryDetailsRef}
							prompts={prompts}
							currentLocale={currentLocale}
						/>
						<LoadingButton
							color="secondary"
							onClick={handleNextStep}
							loading={isCheckoutLoading}
							loadingPosition="start"
							startIcon={<SaveIcon />}
							variant="brp-yellow"
							sx={{
								marginLeft: isSmallScreen ? 2 : 0,
							}}
							className="capitalise"
						>
							<span style={{ marginTop: 2 }}>
								{
									prompts[currentLocale].buttons
										.review_order
								}
							</span>
						</LoadingButton>
					</>
				)}
			</Grid>
		</Grid>
	) : (
		<Grid
			container
			maxWidth="lg"
			justifyContent="center"
			margin="0 auto"
		>
			<EmptyCart />
		</Grid>
	);
};
