import {
	Button,
	Grid,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { colors } from '../../../themes';
import { Elements } from '@stripe/react-stripe-js';
import { PaymentForm } from './PaymentForm';
import { stripePromise } from '../../../helpers';
import { useSelector } from 'react-redux';
import {
	RootState,
	stripeSecretSelector,
} from '../../../redux';

export const PaymentDetails = () => {
	const stripeSecret = useSelector(stripeSecretSelector);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];

	return (
		<Paper
			variant="outlined"
			sx={
				isSmallScreen
					? {
							border: 'none',
							padding: 2,
							marginBottom: 4,
					  }
					: {
							padding: 4,
							marginBottom: 4,
							// flex: 4,
					  }
			}
		>
			<Formik
				initialValues={{}}
				// validationSchema={validationSchema}
				// todo: resolve this ts issue below
				// @ts-ignore
				onSubmit={(data) => {
					// console.log('here with: ', data);
					// formik.handleSubmit;
				}}
			>
				{() => (
					<>
						<Grid
							container
							sx={{
								alignItems: 'flex-start',
								justifyContent: 'space-between',
							}}
							// sx={{ width: 'max-content' }}
						>
							<Grid
								alignItems="center"
								minWidth="200px"
								container
								sx={{
									width: 'max-content',
									marginBottom: 2,
								}}
							>
								<FontAwesomeIcon
									icon={faCreditCard}
									style={{
										padding: 8,
										marginRight: 8,
										background: colors['brp-yellow'].main,
									}}
								/>
								<Typography
									variant="body1"
									component="span"
									sx={{
										textTransform: 'capitalize',
										color: colors['brp-black'].main,
									}}
									marginBottom="-5px"
								>
									{translations.shop.payment}
								</Typography>
							</Grid>
						</Grid>
						{stripeSecret && (
							<Elements
								stripe={stripePromise}
								options={{
									clientSecret: stripeSecret,
									loader: 'always',
								}}
							>
								<PaymentForm />
							</Elements>
						)}
					</>
				)}
			</Formik>
		</Paper>
	);
};
