import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
	AppBar,
	Box,
	Toolbar,
	Typography,
	Menu,
	Container,
	Grid,
	List,
	ListItem,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import jetpower from '../../assets/jetpower.png';
import styles from './header.module.scss';
import { cx } from '../../helpers';
import { getPages } from './headerHelpers';
import { colors } from '../../themes';
import atv from '../../assets/atv-dust.png';
import { HeaderButtons } from './HeaderButtons';
import { Category, RootState, store } from '../../redux';
import { useSelector } from 'react-redux';
import SearchForm from '../search/SearchForm';

// const categories = [
// 	'can-am off-road',
// 	'can-am on-road',
// 	'ski doo',
// 	'sea doo',
// ];

const explore = [
	'maverick ds turbo rr 1',
	'ryker rally',
	'renegade x-rs',
	'spark trixx',
];

export const Header: React.FC = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);

	const mostPopularProduct = useSelector(
		(state: RootState) =>
			state.products.mostPopular ?? state.products.items[0],
	);
	const pages = getPages(prompts[currentLocale]);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const [menuOpen, setMenuOpen] = useState(false);
	const [anchorEl, setAnchorEl] =
		useState<HTMLButtonElement | null>(null);

	const handleOpenNavMenu = (event) => {
		setMenuOpen(true);
		setAnchorEl(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setMenuOpen(false);
		setAnchorEl(null);
	};

	const linkItemStyle: any = {
		marginBottom: 8,
		fontSize: 24,
		textTransform: 'uppercase',
		color: colors['brp-black'].main,
	};

	return (
		<>
			{/* <InfoLine
				closeMenu={handleCloseNavMenu}
				openMenu={handleOpenNavMenu}
			/> */}
			<AppBar
				position="static"
				sx={{
					boxShadow: 'none',
				}}
				id={cx(styles.jetpowerHeader)}
			>
				{isSmallScreen ? null : (
					<Container maxWidth="lg" sx={{ padding: 2 }}>
						<Toolbar
							disableGutters
							sx={{
								justifyContent: 'space-between',
								display: 'flex',
								width: '100%',
							}}
						>
							<Box
								sx={{
									flexBasis: '20%',
									display: { xs: 'none', md: 'flex' },
									paddingRight: { xs: 'none', md: '20px' },
								}}
							>
								<Link
									to="/"
									style={{
										margin: '4px',
										maxWidth: '200px',
									}}
								>
									<img
										style={{
											width: '100%',
											filter: 'invert(0.7)',
										}}
										src={jetpower}
										alt="Jetpower"
									/>
								</Link>
							</Box>
							<Box
								sx={{
									// flexGrow: 1,
									display: { xs: 'none', md: 'flex' },
								}}
							>
								<Grid container alignItems={'center'}>
									{pages.map((page) => (
										<Link
											key={page.text}
											onClick={(e) =>
												page.id === 2
													? handleOpenNavMenu(e)
													: void 0
											}
											to={page.route}
											id={`${page.text}-nav-item`}
											// id={page.id}
											className={styles.navigationItem}
										>
											{page.text}
										</Link>
									))}
								</Grid>
							</Box>
							{isSmallScreen ? null : (
								<HeaderButtons
									style={{ flexBasis: '20%' }}
								/>
							)}
						</Toolbar>
					</Container>
				)}
			</AppBar>
		</>
	);
};
