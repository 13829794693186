import React, { useEffect } from 'react';
import {
	Button,
	Typography,
	Grid,
	CircularProgress,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	RootState,
	newsletter_unsubscribe,
	store,
} from '../../redux';
import { useSelector } from 'react-redux';

export const NewsletterUnsubscribe = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get('id');
	const state = useSelector(
		(state: RootState) => state.generic.unsubscribe,
	);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];

	useEffect(() => {
		if (id) {
			store.dispatch(newsletter_unsubscribe(id));
		}
	}, []);

	return (
		<Grid
			container
			sx={{
				height: '100%',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
				paddingTop: 2,
			}}
		>
			{!id || state === 'rejected' ? (
				<Typography variant="h5" component="h2">
					{translations.forms.unsubscribe_invalid_link}
				</Typography>
			) : state === 'fulfilled' ? (
				<Typography variant="h5" component="h2">
					Successfully unsubscribed
				</Typography>
			) : (
				<CircularProgress />
			)}
			{state !== 'loading' ? (
				<Button
					sx={{
						justifyContent: 'center',
						marginTop: 1,
					}}
					variant="brp-white"
					size="small"
					onClick={() => navigate('/')}
				>
					{translations.shop.back_to_home}
				</Button>
			) : null}
		</Grid>
	);
};
