import {
	Order,
	OrderUser,
	PromoCode,
	RootState,
	SortOrder,
	SortType,
} from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getBaseUrl,
	getProduct,
	updateDialogDetails,
} from './slices';
import { getDefaultServerUrl } from '../bindings';

const getTokenInHeader = (state: RootState) => {
	const token =
		// @ts-ignore
		state?.auth?.access_token ??
		localStorage.getItem('userToken');
	let extraHeaders = {};
	if (token) {
		// include token in req header
		extraHeaders = {
			Authorization: `Bearer ${token}`,
		};
	}
	return extraHeaders;
};

const getTranslations = (state: RootState) => {
	const prompts = state.generic.languagePrompts;
	const currentLocale =
		state.locale.currentLanguage.toLowerCase();
	return prompts[currentLocale];
};

export const getUserOrders = createAsyncThunk(
	'user/getUserOrders',
	async (_arg, { rejectWithValue, getState }) => {
		try {
			const url = getBaseUrl(getState() as RootState);

			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			const response = await fetch(`${url}/orders`, {
				method: 'GET',
				headers: {
					...extraHeaders,
					'Content-Type': 'application/json',
				},
			});

			const data = await response.json();

			if (!response.ok) {
				return rejectWithValue(data); // This will be handled as an error
			}

			return data;
		} catch (error: any) {
			return rejectWithValue({ message: error.message });
		}
	},
);
export const loginViaToken = createAsyncThunk(
	'auth/loginViaToken',
	async (_arg, { rejectWithValue, getState }) => {
		try {
			const url = getBaseUrl(getState() as RootState);

			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			const response = await fetch(`${url}/me`, {
				method: 'GET',
				headers: {
					...extraHeaders,
					'Content-Type': 'application/json',
				},
			});

			const data = await response.json();

			if (!response.ok) {
				return rejectWithValue(data); // This will be handled as an error
			}

			return data;
		} catch (error: any) {
			return rejectWithValue({ message: error.message });
		}
	},
);

export const logout = createAsyncThunk(
	'auth/logout',
	async (_arg, { rejectWithValue, getState }) => {
		try {
			const url = getBaseUrl(getState() as RootState);

			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			// Replace this with your actual API call to register the user
			const response = await fetch(`${url}/logout`, {
				method: 'GET',
				headers: {
					...extraHeaders,
					'Content-Type': 'application/json',
				},
			});

			const data = await response.json();

			if (!response.ok) {
				return rejectWithValue(data);
			}

			// todo: login response doesn't match /me endpoint
			return data;
		} catch (error: any) {
			return rejectWithValue({ message: error.message });
		} finally {
			window.localStorage.removeItem('userToken');
			window.sessionStorage.removeItem('personalDetails');
			window.sessionStorage.removeItem('billingDetails');
			window.sessionStorage.removeItem('deliveryDetails');
		}
	},
);
export const loginUser = createAsyncThunk(
	'auth/loginUser',
	async (
		loginData: object,
		{ rejectWithValue, getState },
	) => {
		try {
			const url = getBaseUrl(getState() as RootState);

			// Replace this with your actual API call to register the user
			const response = await fetch(`${url}/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(loginData),
			});

			const data = await response.json();
			localStorage.setItem('userToken', data.access_token);

			if (!response.ok) {
				return rejectWithValue(data);
			}

			// todo: login response doesn't match /me endpoint
			return data;
		} catch (error: any) {
			return rejectWithValue({ message: error.message });
		}
	},
);
export const registerUser = createAsyncThunk(
	'auth/registerUser',
	async (userData: object, { dispatch, getState }) => {
		try {
			const url = getBaseUrl(getState() as RootState);

			// Replace this with your actual API call to register the user
			const response = await fetch(`${url}/register`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(userData),
			});

			const data = await response.json();

			if (!response.ok) {
				dispatch(
					updateDialogDetails({
						visible: true,
						content:
							data?.error ??
							'Something went wrong during registration',
						isError: true,
					}),
				);
			}

			return data; // Return the user data if registration is successful
		} catch (e: any) {
			dispatch(
				updateDialogDetails({
					visible: true,
					content: e?.error ?? 'Something went wrong',
					isError: true,
				}),
			);
		}
	},
);
export const fetchProducts = createAsyncThunk(
	'data/products',
	async (_arg, { getState }) => {
		const state = getState() as RootState;
		const pageSize = state.products.pageSize;
		const pageNumber = state.products.pageNumber;
		const url = getBaseUrl(state);
		// Perform your asynchronous operation here (e.g., fetch data from an API)
		const response = await fetch(
			`${url}/webshop_products?pageSize=${pageSize}&page=${pageNumber}`,
		);
		const data = await response.json();
		const updatedProductsWithFormattedPrices = data;
		return data; // The value returned from this function will be the action payload
	},
);
export const fetchCountries = createAsyncThunk(
	'data/countries',
	async (_arg, { getState }) => {
		const state = getState() as RootState;
		const url = getBaseUrl(state);

		const response = await fetch(
			`${url}/countries?page=all`,
		);
		const data = await response.json();

		return data; // The value returned from this function will be the action payload
	},
);
export const fetchVehicles = createAsyncThunk(
	'data/vehicles',
	async (_arg, { getState }) => {
		const state = getState() as RootState;
		const pageSize = state.products.pageSize;
		const pageNumber = state.products.pageNumber;
		const url = getBaseUrl(state);
		const response = await fetch(
			`${url}/vehicles?pageSize=${pageSize}&page=${pageNumber}`,
		);
		const vehicles = await response.json();
		// "SQLSTATE[42S02]: Base table or view not found:
		// 1146 Table 'jetpower_dev.vehicle_attributes'
		// doesn't exist (Connection: mysql, SQL: select * from
		//`vehicle_attributes` where `vehicle_attributes`.`vehicle_id` = 18
		// and `vehicle_attributes`.`vehicle_id` is not null)"
		return vehicles; // The value returned from this function will be the action payload
	},
);
export const fetchVehicleCategory = createAsyncThunk(
	'data/vehicles_category',
	async (
		vehicleCategoryId: string | number,
		{ getState },
	) => {
		// pagination needs to work
		const state = getState() as RootState;
		const pageSize = state.products.pageSize;
		const pageNumber = state.products.pageNumber;
		const url = getBaseUrl(state);
		const response = await fetch(
			`${url}/vehicles?filter[category_id]=${vehicleCategoryId}&sort=-created_at&pageSize=${pageSize}&page=${pageNumber}`,
		);
		const vehicles = await response.json();
		return vehicles;
	},
);
export const fetchVehicle = createAsyncThunk(
	'data/vehicle',
	async (id: number, { getState }) => {
		const url = getBaseUrl(getState() as RootState);
		const response = await fetch(`${url}/vehicles/${id}`);
		const vehicles = await response.json();

		return vehicles;
	},
);

export const fetchSpecificProducts = createAsyncThunk(
	'data/selected_products',
	async (specificProducts: number[], { getState }) => {
		const url = getBaseUrl(getState() as RootState);
		const productsToFetch = specificProducts.join(',');
		const response = await fetch(
			`${url}/webshop_products?filter[ids]=${productsToFetch}&filter[children]=false`,
		);
		const data = await response.json();

		return data;
	},
);
export const fetchMostPopularProducts = createAsyncThunk(
	'data/most_popular_products',
	async ({ limit }: { limit?: number }, { getState }) => {
		const url = getBaseUrl(getState() as RootState);
		const addLimit = limit ? `&pageSize=${limit}` : '';
		const response = await fetch(
			`${url}/webshop_products?sort=-views${addLimit}`,
		);
		const data = await response.json();

		return data; // The value returned from this function will be the action payload
	},
);
export const getBlogPosts = createAsyncThunk(
	'generic/blogPosts',
	async (_args, { getState }) => {
		const url = getBaseUrl(getState() as RootState);
		const response = await fetch(`${url}/posts`);
		const data = await response.json();

		return data; // The value returned from this function will be the action payload
	},
);
export const fetchSettings = createAsyncThunk(
	'data/settings',
	async (_arg, { getState }) => {
		const url = getBaseUrl(getState() as RootState);

		const response = await fetch(`${url}/settings`);
		const data = await response.json();
		return data; // The value returned from this function will be the action payload
	},
);

//Allowed sort(s) are
// `created_at, name, weight, colour, price, current_stock,
// total_stock, id, width, height, reference, unique_views`.",
// todo: deal with filters here too?
export const fetchFilteredProducts = createAsyncThunk(
	'data/sorted_filtered_products',
	async (sortAndFilters: any, { getState }) => {
		try {
			const state = getState() as RootState;
			const pageSize = state.products.pageSize;
			const pageNumber = state.products.pageNumber;
			const { filters, sort } = sortAndFilters;
			let sortURL: string | undefined = undefined;
			if (sort && sort.order && sort.type) {
				const sortPrefix = sort.order === 'DESC' ? '-' : '';
				const sortValue = `${sortPrefix}${sort.type}`;
				sortURL = `&sort=${sortValue}`;
			}

			let filterURL = '';
			// filters will always be present
			if (filters) {
				const filterKeys = Object.keys(filters).filter(
					(key) => {
						if (Array.isArray(filters[key])) {
							return filters[key].length > 0;
						}
						return;
					},
				);

				const filterParams = filterKeys.flatMap((key) => {
					const prefix = `filter[${
						key === 'categories' ? 'category_id' : key
					}]=`;
					const joinedValues = filters[key].join(',');
					return `${prefix}${joinedValues}`;
				});

				if (filters.inStock) {
					filterParams.push('filter[inStock]=true');
				}
				if (filters.active_discount) {
					filterParams.push('filter[active_discount]=true');
				}
				filterURL = filterParams.join('&');
			}

			const sortAndFilterParams = [sortURL, filterURL]
				.filter(Boolean)
				.join('&');

			const baseUrl = getBaseUrl(state);
			const url = `${baseUrl}/webshop_products?pageSize=${pageSize}&page=${pageNumber}${sortAndFilterParams}`;

			const response = await fetch(url);
			const data = await response.json();

			return data;
		} catch (e) {
			console.log(e);
		}
	},
);

export const fetchProduct = createAsyncThunk(
	'data/product',
	async (id: number, { dispatch, getState }) => {
		try {
			const state = getState() as RootState;
			// const product = getProduct(state, id);
			// console.log('product:', product);
			// if (product) {
			// 	return {
			// 		data: product,
			// 	};
			// }
			const url = getBaseUrl(state);

			const response = await fetch(
				`${url}/products/${id}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				},
			);
			const productData = await response.json();
			// const { variant_ids } = productData.data;

			// if (variant_ids.length > 0) {
			// 	const variants = await dispatch(
			// 		fetchSpecificProducts(variant_ids),
			// 	);
			// 	return {
			// 		...productData.data,
			// 		variants: variants.payload.data,
			// 	};
			// }

			return productData.data; // The value returned from this function will be the action payload
		} catch (e: any) {
			console.log('e: ', e);
		}
	},
);

export const postContact = createAsyncThunk(
	'generic/contact',
	// async (orderDetails: Order, { getState }) => {
	async (
		contactForm: any,
		{ rejectWithValue, getState, dispatch },
	) => {
		try {
			const state = getState() as RootState;
			const translations = getTranslations(state);
			const url = getBaseUrl(state);

			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			const response = await fetch(`${url}/contacts`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					...extraHeaders,
				},
				body: JSON.stringify(contactForm),
			});
			const data = await response.json();
			if (!response.ok) {
				dispatch(
					updateDialogDetails({
						visible: true,
						content: data?.error ?? '',
						isError: true,
					}),
				);
			} else {
				dispatch(
					updateDialogDetails({
						visible: true,
						content: translations.contact.success,
						isError: false,
					}),
				);
			}
			return data;
		} catch (e: any) {
			dispatch(
				updateDialogDetails({
					visible: true,
					content: e.error,
					isError: true,
				}),
			);
		}
	},
);
export const fetchCategories = createAsyncThunk(
	'data/categories',
	async (_arg, { getState }) => {
		const url = getBaseUrl(getState() as RootState);

		const response = await fetch(
			`${url}/categories?sort=id`,
		);
		const data = await response.json();

		return data;
	},
);
export const postOrder = createAsyncThunk(
	'shop/order',
	async (
		orderDetails: any,
		{ rejectWithValue, dispatch, getState },
	) => {
		try {
			const url = getBaseUrl(getState() as RootState);

			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			const response = await fetch(`${url}/orders`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					...extraHeaders,
				},
				body: JSON.stringify(orderDetails),
			});
			const data = await response.json();
			if (!response.ok) {
				dispatch(
					updateDialogDetails({
						visible: true,
						content: data?.error ?? '',
						isError: true,
					}),
				);
			}
			return data;
		} catch (error: any) {
			return rejectWithValue({ message: error.message });
		}
	},
);
export const forgottenPassword = createAsyncThunk(
	'auth/forgotten_password',
	async (
		email: { email: string },
		{ dispatch, getState },
	) => {
		try {
			const state = getState() as RootState;
			const url = getBaseUrl(state);
			const translations = getTranslations(state);
			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			const response = await fetch(
				`${url}/password_resets`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						...extraHeaders,
					},
					body: JSON.stringify(email),
				},
			);
			const data = await response.json();
			if (!response.ok) {
				dispatch(
					updateDialogDetails({
						visible: true,
						content: data?.error ?? '',
						isError: true,
					}),
				);
				return Promise.reject();
			} else {
				dispatch(
					updateDialogDetails({
						visible: true,
						content: `
                        ${translations.text.reset_password_1}
                        ${translations.text.reset_password_2}`,
						isError: false,
					}),
				);
			}
			return data;
		} catch (e: any) {
			dispatch(
				updateDialogDetails({
					visible: true,
					content:
						e?.error ??
						'Ensure the email address you have provided is correct.',
					isError: true,
				}),
			);
		}
	},
);

export const subscribe = createAsyncThunk(
	'generic/newsletter_subscribe',
	async (
		{
			email,
			showResponse = false,
		}: { email: string; showResponse?: boolean },
		{ dispatch, getState },
	) => {
		try {
			const state = getState() as RootState;
			const translations = getTranslations(state);
			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);
			const url = getBaseUrl(getState() as RootState);
			const response = await fetch(`${url}/newsletter`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					...extraHeaders,
				},
				body: JSON.stringify({ email }),
			});
			// todo future: if we want to give feedback on completion screen
			// about the status of their subscription
			if (response.ok) {
				if (showResponse) {
					dispatch(
						updateDialogDetails({
							visible: true,
							// todo: translation
							content: translations.text.newsletter_success,
							isError: false,
						}),
					);
				}
				return 'successful';
			}
			if (showResponse) {
				const data = await response.json();
				dispatch(
					updateDialogDetails({
						visible: true,
						// todo: translation
						content:
							data?.error ??
							translations.text.newsletter_failure,
						isError: true,
					}),
				);
			}
			return 'failed';
		} catch (e) {
			console.log('Failed to subscribe to newsletter ', e);
			return 'failed';
		}
	},
);

export const search = createAsyncThunk(
	'products/search',
	async (searchValue: string, { dispatch, getState }) => {
		try {
			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			const url = getBaseUrl(getState() as RootState);

			const response = await fetch(
				`${url}/webshop_products?filter[search]=${searchValue}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						...extraHeaders,
					},
				},
			);
			const data = await response.json();

			return data;
		} catch (e: any) {
			dispatch(
				updateDialogDetails({
					visible: true,
					content: e?.error ?? '',
					isError: true,
				}),
			);
		}
	},
);

export const updateUser = createAsyncThunk(
	'auth/user_update',
	async (user: any, { dispatch, getState }) => {
		try {
			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			const url = getBaseUrl(getState() as RootState);
			const { name, id } = user;
			const response = await fetch(`${url}/users/${id}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					...extraHeaders,
				},
				body: JSON.stringify({ name }),
			});
			if (response.ok) {
				const data = await response.json();

				return data;
			}
		} catch (e: any) {
			dispatch(
				updateDialogDetails({
					visible: true,
					content: e?.error ?? '',
					isError: true,
				}),
			);
		}
	},
);

export const validateTaxNumber = createAsyncThunk(
	'checkout/tax_number_validation',
	async (
		{
			tax_number,
			company_name,
		}: { company_name: string; tax_number: number },
		{ dispatch, getState },
	) => {
		try {
			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			const url = getBaseUrl(getState() as RootState);

			const response = await fetch(
				`${url}/taxNumber/validate`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						...extraHeaders,
					},
					body: JSON.stringify({ tax_number }),
				},
			);
			if (response.ok) {
				const data = await response.json();

				return {
					...data,
					value: tax_number,
					company_name,
				};
			}
			// todo: this is temporary, due to class error
			// remove and use actual api response here if needed
			dispatch(
				updateDialogDetails({
					visible: true,
					content: 'Érvénytelen adószám',
					isError: true,
				}),
			);
			return 'invalid';
		} catch (e: any) {
			dispatch(
				updateDialogDetails({
					visible: true,
					content: e?.error ?? '',
					isError: true,
				}),
			);
			return 'invalid';
		}
	},
);

// store results in sessionStorage
export const fetchParcelShops = createAsyncThunk(
	'checkout/parcel-shops',
	async (
		{ lat, lng }: { lat: number; lng: number },
		{ dispatch, getState },
	) => {
		try {
			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			const url = getBaseUrl(getState() as RootState);

			const response = await fetch(
				`${url}/parcel-shops?lat=${lat}&long=${lng}&page=1`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						...extraHeaders,
					},
				},
			);
			const data = await response.json();

			return data;
		} catch (e: any) {
			dispatch(
				updateDialogDetails({
					visible: true,
					content: e?.error ?? '',
					isError: true,
				}),
			);
			return { data: [] };
		}
	},
);

export const prepOrder = createAsyncThunk(
	'checkout/orderUser',
	async (
		orderDetails: any,
		{ rejectWithValue, dispatch, getState },
	) => {
		try {
			// todo: this is only a temporary thing, resolve this later
			// orderDetails.shipping.country = 101;
			// orderDetails.billing.country = 101;
			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);
			const url = getBaseUrl(getState() as RootState);

			const response = await fetch(`${url}/order_user`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					...extraHeaders,
				},
				body: JSON.stringify(orderDetails),
			});
			const data = await response.json();
			if (!response.ok) {
				dispatch(
					updateDialogDetails({
						visible: true,
						content: data?.error ?? '',
						isError: true,
					}),
				);
			}
			return data;
		} catch (e: any) {
			dispatch(
				updateDialogDetails({
					visible: true,
					content: e?.error ?? '',
					isError: true,
				}),
			);
			// return rejectWithValue({ message: error.message });
		}
	},
);
export const fetchWebshopDetails = createAsyncThunk(
	'checkout/get_webshop_details',
	async (_arg, { getState }) => {
		const url = getBaseUrl(getState() as RootState);

		const response = await fetch(
			`${url}/webshop_details?filter[excludeProducts]=true`, //?sort=fee`, // or =-fee if asc
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			},
		);
		const data = await response.json();
		return data;
	},
);

export const fetchDeliveryMethods = createAsyncThunk(
	'checkout/get_delivery_methods',
	async (_arg, { getState }) => {
		const url = getBaseUrl(getState() as RootState);

		const response = await fetch(
			`${url}/delivery_methods`, //?sort=fee`, // or =-fee if asc
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			},
		);
		const data = await response.json();
		return data;
	},
);

export const applyDiscount = createAsyncThunk(
	'checkout/discount',
	async (code: string, { dispatch, getState }) => {
		try {
			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			const url = getBaseUrl(getState() as RootState);

			const response = await fetch(`${url}/promo/${code}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					...extraHeaders,
				},
			});
			const data = await response.json();
			if (!response.ok) {
				dispatch(
					updateDialogDetails({
						visible: true,
						content: 'Invalid promo code',
						isError: true,
					}),
				);
			}
			return data;
		} catch (e: any) {
			dispatch(
				updateDialogDetails({
					visible: true,
					content: e?.error ?? 'Something went wrong',
					isError: true,
				}),
			);
		}
	},
);
// todo: remove this as method below replaces it
/**
 * @deprecated Use the getLangFile instead.
 */
export const fetchDynamicContent = createAsyncThunk(
	'generic/content',
	async (_arg: any, { getState }) => {
		const extraHeaders = getTokenInHeader(
			getState() as RootState,
		);

		const url = getBaseUrl(getState() as RootState);

		const response = await fetch(`${url}/pages`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				...extraHeaders,
			},
		});
		const data = await response.json();
		return data;
	},
);

export const getLangFile = createAsyncThunk(
	'generic/locale',
	async (_arg, { rejectWithValue }) => {
		const now = Date.now();
		try {
			const response = await fetch(
				`${getDefaultServerUrl()}/storage/lang.json?v=${now}`,
			);
			if (!response.ok) {
				throw new Error('Failed to fetch language file');
			}
			const translationFile = await response.json();
			return translationFile;
		} catch (error: any) {
			console.log(error);
			return rejectWithValue(error.message);
		}
	},
);

export const getAttributes = createAsyncThunk(
	'shop/attributes',
	async (_arg, { getState }) => {
		const extraHeaders = getTokenInHeader(
			getState() as RootState,
		);

		const url = getBaseUrl(getState() as RootState);

		const response = await fetch(`${url}/attributes`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				...extraHeaders,
			},
		});
		const data = await response.json();
		return data;
	},
);
export const getPromoMessages = createAsyncThunk(
	'generic/promo_messages',
	async (_arg, { getState }) => {
		try {
			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			const url = getBaseUrl(getState() as RootState);

			const response = await fetch(
				`${url}/promo_messages`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						...extraHeaders,
					},
				},
			);
			const data = await response.json();
			return data;
		} catch (e: any) {
			console.log(e);
		}
	},
);

export const activateAccount = createAsyncThunk(
	'user/activate',
	async (
		activationDetails: {
			activation_token: string;
			password: string;
			password_confirmation: string;
		},
		{ getState, dispatch },
	) => {
		try {
			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			const url = getBaseUrl(getState() as RootState);

			const response = await fetch(
				`${url}/users/activate`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						...extraHeaders,
					},
					body: JSON.stringify(activationDetails),
				},
			);
			const data = await response.json();
			if (!response.ok) {
				dispatch(
					updateDialogDetails({
						visible: true,
						content:
							data?.error ?? 'Invalid activation token',
						isError: true,
					}),
				);
			}
			return data;
		} catch (e: any) {
			dispatch(
				updateDialogDetails({
					visible: true,
					content: e?.error ?? 'Invalid activation token',
					isError: true,
				}),
			);
		}
	},
);

export const passwordReset = createAsyncThunk(
	'user/password_reset',
	async (
		resetDetails: {
			password: string;
			password_confirmation: string;
			token: string | null;
		},
		{ getState, dispatch },
	) => {
		try {
			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);
			const state = getState() as RootState;
			const url = getBaseUrl(state);
			const translations = getTranslations(state);

			const response = await fetch(
				`${url}/password_resets`,
				{
					method: 'PATCH',
					headers: {
						'Content-Type': 'application/json',
						...extraHeaders,
					},
					body: JSON.stringify(resetDetails),
				},
			);
			const data = await response.json();
			if (!response.ok) {
				dispatch(
					updateDialogDetails({
						visible: true,
						content:
							data?.error ??
							'Something went wrong during resetting your password',
						isError: true,
					}),
				);
			} else {
				dispatch(
					updateDialogDetails({
						visible: true,
						content: `
                        ${translations.forms.password_reset_successful}`,
						isError: false,
					}),
				);
			}
			return data;
		} catch (e: any) {
			dispatch(
				updateDialogDetails({
					visible: true,
					content:
						e?.error ??
						'Something went wrong during resetting your password',
					isError: true,
				}),
			);
		}
	},
);

export const newsletter_unsubscribe = createAsyncThunk(
	'generic/newsletter_unsubscribe',
	async (id: string, { getState, dispatch }) => {
		try {
			const extraHeaders = getTokenInHeader(
				getState() as RootState,
			);

			const url = getBaseUrl(getState() as RootState);

			const response = await fetch(
				`${url}/newsletter/${id}`,
				{
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
						...extraHeaders,
					},
				},
			);

			if (!response.ok) {
				// dispatch(
				// 	updateDialogDetails({
				// 		visible: true,
				// 		content: 'Invalid user to unsubscribe',
				// 		isError: true,
				// 	}),
				// );
				return Promise.reject();
			}
			return;
		} catch (e: any) {
			dispatch(
				updateDialogDetails({
					visible: true,
					content:
						e?.error ?? 'Invalid user to unsubscribe',
					isError: true,
				}),
			);
			return;
		}
	},
);
