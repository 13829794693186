import {
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import grant1 from '../../assets/government-grant.png';
import pressRelease1 from '../../assets/press-release.png';
import grant2 from '../../assets/government-grant-2.jpeg';
import pressRelease2 from '../../assets/press-release-2.jpeg';
import construction1 from '../../assets/construction-1.jpeg';
import construction2 from '../../assets/construction-2.jpeg';
import construction3 from '../../assets/construction-3.jpeg';
import vehicle1 from '../../assets/vehicle-1.jpeg';
import vehicle2 from '../../assets/vehicle-2.jpeg';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

export const Grants = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);
	return (
		<Grid
			container
			maxWidth="lg"
			justifyContent="space-between"
			// sx={{
			//     margin: '0 auto 32px',
			// }}
			paddingX={3}
			paddingY={1}
			sx={{
				flexDirection: 'column',
				marginX: 'auto',
				marginBottom: { xs: 2, md: 3 },
				marginTop: isSmallScreen ? 2 : 0,
			}}
		>
			<Typography variant="h3" component="h1">
				{translations.grants.title}
			</Typography>
			<Typography
				variant="h4"
				component="h2"
				sx={{ marginTop: 4 }}
			>
				{translations.grants.site}
			</Typography>
			<Typography
				variant="body1"
				component="p"
				maxWidth="md"
			>
				{translations.grants.site_desc}
			</Typography>
			<Grid container flexDirection="column" maxWidth="sm">
				<img src={grant1} style={{ width: '100%' }} />
				<img
					src={pressRelease1}
					style={{ width: '100%' }}
				/>
				<img
					src={construction1}
					style={{ width: '100%', marginTop: '16px' }}
				/>
				<img
					src={construction2}
					style={{ width: '100%', marginTop: '16px' }}
				/>
				<img
					src={construction3}
					style={{ width: '100%', marginTop: '16px' }}
				/>
			</Grid>
			<Typography
				variant="h4"
				component="h2"
				sx={{ marginTop: 4 }}
			>
				{translations.grants.service}
			</Typography>
			<Typography
				variant="body1"
				component="p"
				maxWidth="md"
			>
				{translations.grants.service_desc}
			</Typography>
			<Grid container flexDirection="column" maxWidth="sm">
				<img src={grant2} style={{ width: '100%' }} />
				<img
					src={pressRelease2}
					style={{ width: '100%' }}
				/>
				<img
					src={vehicle1}
					style={{ width: '100%', marginTop: '16px' }}
				/>
				<img
					src={vehicle2}
					style={{ width: '100%', marginTop: '16px' }}
				/>
			</Grid>
		</Grid>
	);
};
