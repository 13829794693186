import {
	Checkbox,
	Container,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	styled,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { colors } from '../../themes';
import {
	ErrorMessage,
	ErrorMessageProps,
	Field,
	Form,
	Formik,
} from 'formik';
import styles from '../login/login.module.scss';
import { ToggleablePasswordField } from '../../components';
import {
	EmailOutlined,
	PersonOutlined,
} from '@mui/icons-material';
import {
	Link,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import {
	store,
	registerUser,
	RootState,
	loginUser,
} from '../../redux';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { useSelector } from 'react-redux';
import { signUpValidationSchema } from '../../helpers';

export const SignupForm = ({
	translations,
	isSmallScreen,
}) => {
	const navigate = useNavigate();
	const initialValues = {
		first_name: '',
		last_name: '',
		email: '',
		password: '',
		password_confirmation: '',
		agreeTerms: false,
	};

	const location = useLocation();
	const from = location.state?.from || '/';

	const validationSchema =
		signUpValidationSchema(translations);

	const handleSubmit = async (values, actions) => {
		const { resetForm } = actions;

		const { payload: response } = await store.dispatch(
			registerUser({
				...values,
				name: `${values.last_name} ${values.first_name}`,
			}),
		);

		if (!response.error) {
			resetForm();
			await store.dispatch(
				loginUser({
					email: values.email,
					password: values.password,
				}),
			);
			if (from === '/checkout') {
				navigate(from, {
					replace: true,
				});
			} else {
				navigate('/login');
			}
		}
	};

	const Error = styled((props: ErrorMessageProps) => (
		<ErrorMessage {...props} component="span" />
	))(() => ({
		marginTop: '8px',
		display: 'block',
		color: colors['canam-on-red'].main,
	}));

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
			// onValidate={(errors) => console.log('errors: ', errors)}
		>
			{({ isSubmitting }) => (
				// consider increasing padding here but it causes issues with height
				<Form
					style={{
						padding: isSmallScreen ? '32px 16px' : 16,
						boxSizing: 'border-box',
					}}
				>
					<Typography
						variant="h5"
						component="h5"
						sx={{
							textTransform: 'uppercase',
							color: colors['brp-black'].main,
							marginBottom: '16px',
						}}
					>
						{translations.navigation.register}
					</Typography>

					<Field
						inputProps={{ tabIndex: 1 }}
						className={styles.noBorderRadius}
						name="last_name"
						as={TextField}
						fullWidth
						required
						sx={{ marginTop: 3 }}
						label={translations.forms.last_name}
						placeholder={translations.placeholder.last_name}
						InputProps={{
							startAdornment: (
								<InputAdornment
									position="start"
									sx={{ pointerEvents: 'none' }}
								>
									<IconButton
										tabIndex={-1}
										aria-label="Last name field"
									>
										<PersonOutlined />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<Error name="last_name" />
					<Field
						inputProps={{ tabIndex: 2 }}
						name="first_name"
						as={TextField}
						label={translations.forms.first_name}
						placeholder={
							translations.placeholder.first_name
						}
						fullWidth
						required
						sx={{ marginTop: 3 }}
						className={styles.noBorderRadius}
						InputProps={{
							startAdornment: (
								<InputAdornment
									sx={{ pointerEvents: 'none' }}
									position="start"
								>
									<IconButton
										tabIndex={-1}
										aria-label="First name field"
									>
										<PersonOutlined />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<Error name="first_name" />
					<Field
						inputProps={{ tabIndex: 3 }}
						name="email"
						as={TextField}
						variant="outlined"
						fullWidth
						margin="normal"
						required
						type="email"
						sx={{ marginTop: 3, marginBottom: 0 }}
						label={translations.forms.email}
						placeholder={translations.placeholder.email}
						InputProps={{
							startAdornment: (
								<InputAdornment
									sx={{ pointerEvents: 'none' }}
									position="start"
								>
									<IconButton
										tabIndex={-1}
										aria-label="Email field"
									>
										<EmailOutlined />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<Error name="email" />
					<Field
						inputProps={{ tabIndex: 4 }}
						as={TextField}
						sx={{ marginTop: 3 }}
						component={ToggleablePasswordField}
						type="password"
						name="password"
						label={translations.forms.password}
						placeholder={translations.placeholder.password}
						fullWidth
						variant="outlined"
					/>
					<Error name="password" />
					<Field
						sx={{ marginTop: 3 }}
						inputProps={{ tabIndex: 5 }}
						as={TextField}
						component={ToggleablePasswordField}
						type="password"
						name="password_confirmation"
						label={translations.forms.password}
						placeholder={translations.placeholder.password}
						fullWidth
						variant="outlined"
					/>
					<Error name="password_confirmation" />
					<FormControlLabel
						control={
							<Field
								type="checkbox"
								name="agreeTerms"
								as={Checkbox}
								color="primary"
								required
								inputProps={{ tabIndex: 6 }}
							/>
						}
						sx={{ marginY: 1, width: '100%' }}
						label={
							<Typography
								variant="body1"
								component="p"
								sx={{ marginBottom: '-3px' }}
							>
								{translations.text.privacy_acknowledged_1}
								<Link
									to="/privacy-policy"
									target="_blank"
									rel="noreferrer"
									style={{ textDecoration: 'underline' }}
								>
									{translations.text.privacy_acknowledged_2}
								</Link>
								{translations.text.privacy_acknowledged_3}
							</Typography>
						}
					/>
					<Error
						sx={{ marginTop: 0, marginBottom: 2 }}
						name="agreeTerms"
					/>
					<Container
						style={{
							padding: 0,
							justifyContent: 'space-between',
							display: 'flex',
						}}
						sx={{
							marginTop: { xs: 1, md: 0 },
						}}
					>
						<LoadingButton
							style={{ textTransform: 'capitalize' }}
							tabIndex={7}
							type="submit"
							loading={isSubmitting}
							variant="brp-yellow"
						>
							<span>
								{translations.buttons.create_account}
							</span>
						</LoadingButton>
						<Grid
							container
							sx={{
								alignItems: 'center',
								// justifyContent: 'space-between',
								width: 'max-content',
							}}
						>
							<Typography
								variant="subtitle1"
								component="h6"
								sx={{
									// textTransform: 'uppercase',
									color: colors['brp-grey'].main,
								}}
							>
								{translations.text.already_account}
							</Typography>
							<Link
								to="/login"
								style={{
									fontWeight: 'bold',
									marginLeft: 8,
								}}
								tabIndex={8}
							>
								{translations.navigation.login}
							</Link>
						</Grid>
					</Container>
				</Form>
			)}
		</Formik>
	);
};

export const Signup = () => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);

	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);

	return (
		<div
			style={{
				position: 'relative',
				backgroundColor: colors['brp-white'].main,
				height: '100%',
			}}
		>
			<Container
				style={{
					paddingLeft: 0,
					paddingRight: 0,
					marginLeft: 0,
					marginRight: 0,
					height: '100%',
					maxWidth: 'none',
				}}
				// todo: look into how to achieve this with
				// clip path background for left element
				// maxWidth="lg"
			>
				<Grid container style={{ height: '100%' }}>
					<Grid
						item
						xs={12}
						sm={7}
						sx={{
							// ...imageStyle,
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
							paddingRight: '20%',
							minHeight: isSmallScreen ? '30vh' : '50vh',
							background: colors['brp-black'].main,
							clipPath: isSmallScreen
								? 'none'
								: 'polygon(0 0, 75% 0, 100% 100%, 0% 100%);',
						}}
					>
						<Container
							style={{
								width: 'max-content',
								// alignItems: 'center',
							}}
						>
							<Typography
								variant="h3"
								component="h1"
								sx={{
									textTransform: 'uppercase',
									color: colors['brp-white'].main,
									marginBottom: '16px',
								}}
							>
								{
									prompts[currentLocale].text
										.register_welcome_1
								}
							</Typography>
							<Typography
								variant="h3"
								component="h1"
								sx={{
									textTransform: 'uppercase',
									color: colors['brp-white'].main,
									marginBottom: '16px',
								}}
							>
								{
									prompts[currentLocale].text
										.register_welcome_2
								}
								<span
									style={{
										color: colors['brp-yellow'].main,
									}}
								>
									{
										prompts[currentLocale].text
											.register_welcome_3
									}
								</span>
							</Typography>
						</Container>
					</Grid>
					<Grid item sm={5} xs={12}>
						<SignupForm
							translations={prompts[currentLocale]}
							isSmallScreen={isSmallScreen}
						/>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};
