import {
	Collapse,
	Fade,
	Link,
	Typography,
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { colors } from '../../../../themes';
import { parseHtml } from '../../../helpers';

export const ExpandableCell = ({
	value,
	prompts,
	currentLocale,
}: {
	value: string;
	prompts: object;
	currentLocale: string;
}) => {
	const [expanded, setExpanded] = useState(false);

	return (
		<div style={{ transition: '300ms' }}>
			{/* <Collapse in={expanded} collapsedSize={24}> */}
			<section style={{ transition: '300ms' }}>
				{parseHtml(expanded ? value : value.slice(0, 80))}
				{value.length > 60 && !expanded && '...'}
			</section>
			{/* </Collapse> */}
			{value.length > 80 && (
				<Link
					type="button"
					component="button"
					sx={{
						textDecoration: 'none',
						color: colors['brp-black'].main,
						borderBottom: `2px solid ${colors['brp-yellow'].main}`,
						fontSize: 'inherit',
						marginLeft: 'auto',
						display: 'block',
					}}
					onClick={() => setExpanded(!expanded)}
				>
					{expanded
						? prompts[currentLocale].shop.less
						: prompts[currentLocale].shop.more}
				</Link>
			)}
		</div>
	);
};
