import {
	PaymentElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
	RootState,
	cancelPayment,
	getCurrentOrder,
	getOrderUser,
	setCheckoutLoading,
	store,
	updateDialogDetails,
} from '../../../redux';
import { Button, Grid } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import { LoadingButton } from '@mui/lab';
import { CheckoutCheckboxes } from './components';

export const PaymentForm = () => {
	const [isTandCAccepted, setIsTandCAccepted] = useState({
		isAccepted: false as boolean,
		checked: false as boolean,
		message: undefined as string | undefined,
	});
	const [
		isPrivacyPolicyAccepted,
		setIsPrivacyPolicyAccepted,
	] = useState({
		isAccepted: false as boolean,
		checked: false as boolean,
		message: undefined as string | undefined,
	});
	const [isLoading, setIsLoading] = useState(false);
	const order = useSelector(getCurrentOrder);
	const orderUser = useSelector(getOrderUser);
	const stripe = useStripe();
	const elements = useElements();
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];

	const handlePaymentSubmit = async (event) => {
		setIsLoading(true);

		if (!isTandCAccepted.isAccepted) {
			setIsTandCAccepted({
				isAccepted: false,
				checked: true,
				message:
					prompts[currentLocale].checkbox
						.checkout_t_and_c_not_checked,
			});
			store.dispatch(setCheckoutLoading(false));
			return;
		}
		if (!isPrivacyPolicyAccepted.isAccepted) {
			setIsPrivacyPolicyAccepted({
				isAccepted: false,
				checked: true,
				message:
					prompts[currentLocale].checkbox
						.checkout_privacy_not_checked,
			});
			store.dispatch(setCheckoutLoading(false));
			return;
		}

		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		const response = stripe.confirmPayment({
			elements,
			// redirect: 'if_required',
			redirect: 'always',
			confirmParams: {
				return_url: `${window.location.origin}/completion?ref=${order.reference}`,
				shipping: {
					name: `${orderUser.first_name} ${orderUser.last_name}`,
					address: {
						city: order.shipping_address.city,
						line1: order.shipping_address.street,
						line2: order.shipping_address.street_extra,
						postal_code: order.shipping_address.post_code,
						state: order.shipping_address.state,
					},
					tracking_number: order.reference,
				},
			},
		});
		const res = await response;
		if (res.error) {
			store.dispatch(
				updateDialogDetails({
					visible: true,
					content: res.error.message,
					isError: true,
				}),
			);
		}
		setIsLoading(false);
	};
	const shouldSubscribe = useSelector(
		(state: RootState) =>
			state.checkout.shouldSubscribe ?? false,
	);
	const navigateBack = () => {
		store.dispatch(cancelPayment());
	};
	return (
		<Grid container flexDirection="column">
			<form onSubmit={handlePaymentSubmit}>
				<PaymentElement id="payment-element" />
				<CheckoutCheckboxes
					shouldSubscribe={shouldSubscribe}
					translations={prompts[currentLocale]}
					isTandCAccepted={isTandCAccepted}
					isPrivacyPolicyAccepted={isPrivacyPolicyAccepted}
					setIsPrivacyPolicyAccepted={
						setIsPrivacyPolicyAccepted
					}
					setIsTandCAccepted={setIsTandCAccepted}
				/>
				<Grid
					container
					sx={{ marginTop: 2 }}
					justifyContent="space-between"
				>
					<Button
						variant="brp-black"
						onClick={navigateBack}
						disabled={isLoading}
						sx={{
							textTransform: 'capitalize',
						}}
					>
						{translations.buttons.cancel}
					</Button>
					<LoadingButton
						color="secondary"
						type="submit"
						loading={isLoading}
						loadingPosition="start"
						startIcon={<PaymentIcon />}
						variant="brp-yellow"
					>
						{translations.buttons.confirm_and_pay}
					</LoadingButton>
				</Grid>
			</form>
		</Grid>
	);
};
