import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../themes';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

export const Explore = ({ style = {} }) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);

	const navigate = useNavigate();
	const navigateToShop = () => {
		navigate('/shop/categories');
	};
	return (
		<Grid
			container
			padding={0}
			style={{ cursor: 'pointer', marginTop: '16px' }}
			onClick={navigateToShop}
		>
			<Button
				style={{
					...style,
					border: `1px solid ${colors['brp-yellow'].main}`,
					borderRadius: 0,
					color: colors['brp-yellow'].main,
				}}
				variant="brp-black"
				size="small"
			>
				{prompts[currentLocale].buttons.explore_more}
			</Button>
			<span
				style={{
					backgroundColor: colors['brp-yellow'].main,
					color: colors['brp-black'].main,
					width: '32px',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<FontAwesomeIcon
					style={{ margin: 'auto', fontSize: '24px' }}
					icon={faAngleRight}
				/>
			</span>
		</Grid>
	);
};
