import { Grid } from '@mui/material';
import { getBlogPosts, store } from '../../redux';
import { useEffect } from 'react';

export const Blog = () => {
	useEffect(() => {
		store.dispatch(getBlogPosts());
	}, []);
	return (
		<Grid container>
			<iframe
				src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fjetpowerhungary%2F&tabs=timeline&width=500&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
				width="500"
				height="400"
				style={{
					margin: '32px auto',
					border: 'none',
					overflow: 'hidden',
				}}
				allowFullScreen={true}
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
			></iframe>
		</Grid>
	);
};
