import { useSelector } from 'react-redux';
import {
	RootState,
	VehicleDetails,
	store,
	updateVehicleForQuote,
	updateVehicleReuqestFormVisibility,
} from '../../redux';
import {
	Button,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import notFound from '../../assets/not-found.png';

export const VehicleCard = ({
	vehicle,
}: {
	vehicle: VehicleDetails;
}) => {
	// todo: there is a potential that if the gridview is set to false
	// when looking at products, and they search for vehicles, it stays in that
	// so make sure it's reset to gridview whenever vehicles are shown
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);
	const isLargeScreen = useMediaQuery(
		theme.breakpoints.up('lg'),
	);

	const isGridView = useSelector(
		(state: RootState) => state.shop.isGridView,
	);

	const openQuoteDialog = () => {
		store.dispatch(
			updateVehicleReuqestFormVisibility(true),
		);
		store.dispatch(updateVehicleForQuote(vehicle));
		// set what vehicle to get a quote for
	};

	return (
		<Paper
			variant="outlined"
			sx={{
				width: '100%',
				maxHeight: isGridView ? 'auto' : 300,
				border: 'none',
			}}
		>
			<CardContent
				style={{
					display: isGridView ? 'block' : 'flex',
				}}
			>
				<Grid item xs={12} md={isGridView ? 12 : 4}>
					<Link
						to={`/shop/vehicles/${vehicle?.id}`}
						state={{ vehicleId: vehicle.id }}
						style={{
							display: 'block',
							width: '100%',
						}}
					>
						<CardMedia
							component="img"
							alt={vehicle?.name}
							sx={{
								width: '100%',
								maxHeight: '200px',
								aspectRatio: 3 / 2,
								objectFit: 'contain',
							}}
							image={vehicle?.main_image?.src ?? notFound}
						/>
					</Link>
				</Grid>
				<Grid
					item
					xs={12}
					md={isGridView ? 12 : 8}
					padding={0}
					width={isGridView ? '100%' : 'max-content'}
					sx={{ display: isGridView ? 'block' : 'flex' }}
					flexDirection={isGridView ? 'column' : 'row'}
				>
					<Grid
						item
						md={isGridView ? 12 : 9}
						container
						display="flex"
						flexDirection="column"
					>
						<Link
							to={`/shop/vehicles/${vehicle.id}`}
							state={{ vehicleId: vehicle.id }}
						>
							<Typography
								gutterBottom
								variant="h6"
								component="div"
								sx={{ textTransform: 'uppercase' }}
							>
								{vehicle.name}
							</Typography>
						</Link>
					</Grid>
					<Grid item md={isGridView ? 12 : 3}>
						<CardActions
							sx={{
								padding: 0,
								display: 'flex',
								justifyContent: 'space-between',
								flexWrap: 'wrap',
								alignItems: isGridView
									? 'flex-start'
									: 'flex-end',
								height: '100%',
								flexDirection: 'row',
							}}
						>
							<Button
								variant="brp-white"
								size="small"
								onClick={openQuoteDialog}
								style={{
									width:
										isSmallScreen || isLargeScreen
											? '100%'
											: '45%',
									marginLeft: 0,
									marginTop: 8,
									minWidth: 120,
								}}
							>
								{translations.text.get_a_quote}
							</Button>
							<Link
								to={vehicle.external_link}
								target="_blank"
								rel="noopener noreferrer"
								style={{
									width:
										isSmallScreen || isLargeScreen
											? '100%'
											: '45%',
								}}
							>
								<Button
									variant="brp-yellow"
									style={{
										width: '100%',
										marginLeft: 0,
										marginTop: 8,
										minWidth: 120,
									}}
								>
									{translations.text.customise_vehicle}
								</Button>
							</Link>
						</CardActions>
					</Grid>
				</Grid>
			</CardContent>
		</Paper>
	);
};
