import { Button, Grid, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import styles from './account.module.scss';
import { colors } from '../../themes';
import { cx } from '../../helpers';
import {
	RootState,
	logout,
	store,
	toggleLoadingScreen,
} from '../../redux';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from 'react-redux';

export const AccountSummary = ({
	tabSelected,
	handleTabChange,
	accountDetails,
}) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const [isLoggingOut, setIsLoggingOut] = useState(false);
	const navigate = useNavigate();
	const handleLogout = async () => {
		store.dispatch(toggleLoadingScreen(true));
		setIsLoggingOut(true);
		navigate('/');
		await store.dispatch(logout());
		setIsLoggingOut(false);
		store.dispatch(toggleLoadingScreen(false));
	};
	const gridStyle = {
		// width: 'max-content',
		paddingBottom: '16px',
		marginBottom: '16px',
		borderBottom: `1px solid ${colors['brp-black'].main}`,
	};
	return (
		<Grid
			item
			flexDirection="column"
			// maxHeight={md: {"60vh"} }
			xs={12}
			md={3}
			sx={{
				backgroundColor: colors['brp-grey'].faded,
				padding: 4,
				maxHeight: { md: '60vh', xs: 'none' },
			}}
			marginBottom={4}
		>
			<Grid
				container
				flexDirection="column"
				sx={{ ...gridStyle }}
			>
				<Typography variant="body1" component="h3">
					{accountDetails?.first_name}{' '}
					{accountDetails?.last_name}
				</Typography>
				<Typography variant="body2" component="h4">
					{accountDetails?.email}
				</Typography>
			</Grid>
			<Grid
				container
				flexDirection="column"
				sx={{ ...gridStyle }}
			>
				<span
					style={{
						fontWeight:
							tabSelected === 0 ? 'bold' : 'normal',
					}}
					className={styles.linkItem}
					onClick={() => handleTabChange(0)}
				>
					{prompts[currentLocale].account.information.title}
				</span>
				<span
					style={{
						fontWeight:
							tabSelected === 1 ? 'bold' : 'normal',
					}}
					className={cx(
						styles.linkItem,
						tabSelected === 1 && styles.bold,
					)}
					onClick={() => handleTabChange(1)}
				>
					{prompts[currentLocale].account.delivery.title}
				</span>
				<span
					style={{
						fontWeight:
							tabSelected === 2 ? 'bold' : 'normal',
					}}
					className={styles.linkItem}
					onClick={() => handleTabChange(2)}
				>
					{prompts[currentLocale].navigation.order_history}
				</span>
			</Grid>
			<Grid container flexDirection="column">
				<LoadingButton
					onClick={handleLogout}
					loading={isLoggingOut}
					startIcon={<LogoutIcon />}
					variant="brp-white"
				>
					<span>
						{prompts[currentLocale].navigation.logout}
					</span>
				</LoadingButton>
			</Grid>
		</Grid>
	);
};
