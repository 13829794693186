import { useState } from 'react';
import {
	LockOutlined,
	Visibility,
	VisibilityOff,
} from '@mui/icons-material';
import {
	IconButton,
	InputAdornment,
	TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

export const ToggleablePasswordField = (props) => {
	const { field, form, sx, inputProps } = props;
	const { name, value } = field;
	const { setFieldValue } = form;
	const [showPassword, setShowPassword] = useState(false);

	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<TextField
			sx={{ ...sx }}
			inputProps={{ ...inputProps }}
			variant="outlined"
			required
			type={showPassword ? 'text' : 'password'}
			name={name}
			value={value}
			onChange={(e) => {
				setFieldValue(name, e.target.value);
			}}
			fullWidth
			label={prompts[currentLocale].forms.password}
			placeholder={
				prompts[currentLocale].placeholder.password
			}
			InputProps={{
				startAdornment: (
					<InputAdornment
						sx={{ pointerEvents: 'none' }}
						position="start"
					>
						<IconButton
							tabIndex={-1}
							aria-label="Password field"
						>
							<LockOutlined />
						</IconButton>
					</InputAdornment>
				),
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							tabIndex={-1}
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
						>
							{showPassword ? (
								<Visibility />
							) : (
								<VisibilityOff />
							)}
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	);
};
