import {
	createSelector,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import {
	RootState,
	GenericState,
	DialogDetails,
	Category,
} from '../types';
import { getDefaultUrl } from '../../bindings';
import {
	fetchCategories,
	postContact,
	getLangFile,
	getPromoMessages,
	fetchVehicles,
	fetchVehicle,
	subscribe,
	fetchSettings,
	getBlogPosts,
	newsletter_unsubscribe,
	fetchVehicleCategory,
} from '../thunks';
import { langPrompts } from '../../lang';
import {
	generateParentBreadcrumbs,
	getRecurringCategories,
} from '../../views';

const initialState: GenericState = {
	unsubscribe: undefined,
	subscribe: undefined,
	baseUrl: getDefaultUrl(),
	isLoadingScreenShown: false,
	categories: {
		items: [],
		selectedItems: undefined,
		lastSelected: undefined,
		status: undefined,
		breadcrumbs: [
			{
				path: '/shop/products',
				name: 'Products',
				category_id: undefined,
				hasChildren: undefined,
			},
		],
	},
	cartPopover: { isOpen: false },
	accountPopover: { isOpen: false },
	localePopover: { isOpen: false },
	searchPopover: { isOpen: false },
	settings: {
		isBeingFetched: false,
		details: undefined,
	},
	vehicles: {
		isBeingFetched: false,
		details: {},
		selectedVehicle: undefined,
		selectedCategory: undefined,
		isQuoteRequestFormShown: false,
		vehicleForQuote: undefined,
		pagination: undefined,
	},
	dynamicPages: {
		isBeingFetched: false,
		details: [],
	},
	contactForm: undefined,
	promoMessages: {
		items: undefined,
		status: undefined,
	},
	dialogDetails: {
		visible: false,
		content: undefined,
		isError: undefined,
	},
	languagePrompts: langPrompts,
	blog: {
		posts: undefined,
		isBeingFetched: false,
	},
};

export const genericSlice = createSlice({
	name: 'generic',
	initialState,
	reducers: {
		toggleLoadingScreen: (
			state,
			{ payload }: { payload: boolean },
		) => {
			state.isLoadingScreenShown = payload;
		},
		updateDialogDetails: (
			state,
			{ payload }: { payload: DialogDetails },
		) => {
			state.dialogDetails = {
				...state.dialogDetails,
				...payload,
			};
		},
		updateBaseUrl: (
			state,
			action: PayloadAction<string>,
		) => {
			state.baseUrl = action.payload;
		},
		updateCartPopover: (
			state,
			{ payload }: { payload: boolean },
		) => {
			state.cartPopover.isOpen = payload;
		},
		updateSearchPopover: (
			state,
			{ payload }: { payload: boolean },
		) => {
			state.searchPopover.isOpen = payload;
		},
		updateAccountPopover: (
			state,
			{ payload }: { payload: boolean },
		) => {
			state.accountPopover.isOpen = payload;
		},
		updateLocalePopover: (
			state,
			{ payload }: { payload: { isOpen: boolean } },
		) => {
			state.localePopover.isOpen = payload.isOpen;
		},
		updateVehicleReuqestFormVisibility: (
			state,
			{ payload }: { payload: boolean },
		) => {
			state.vehicles = {
				...state.vehicles,
				isQuoteRequestFormShown: payload,
			};
		},
		updateVehicleForQuote: (state, { payload }) => {
			state.vehicles = {
				...state.vehicles,
				vehicleForQuote: payload,
			};
		},
		updateBreadcrumbs: (
			genericState,
			{ payload }: { payload: string },
		) => {
			const { breadcrumbs } = genericState.categories;
			const itemToResetTo = breadcrumbs.find(
				(item) => item.name === payload,
			);
			if (itemToResetTo) {
				const index = breadcrumbs.indexOf(itemToResetTo);
				//genericState.categories.breadcrumbs = itemToResetTo
				if (index !== -1) {
					genericState.categories.breadcrumbs =
						breadcrumbs.slice(0, index + 1); // +1 to include the element itself
				}
				if (itemToResetTo.category_id === undefined) {
					genericState.categories.breadcrumbs =
						initialState.categories.breadcrumbs;
					genericState.categories.selectedItems = undefined;
				} else {
					const categoryToResetTo =
						genericState.categories.items.find(
							(category) =>
								category.id === itemToResetTo.category_id,
						);
					if (categoryToResetTo) {
						const selected = getRecurringCategories(
							// genericState.categories.items,
							categoryToResetTo,
						);
						genericState.categories.selectedItems =
							selected;
					}
				}
			}
		},
		resetForFilter: (genericState) => {
			const { breadcrumbs, selectedItems, lastSelected } =
				initialState.categories;
			genericState.categories.breadcrumbs = breadcrumbs;
			genericState.categories.selectedItems = selectedItems;
			genericState.categories.lastSelected = lastSelected;
		},
		updateSelectedCategoriesViaUrl: (
			genericState,
			{ payload }: { payload: string },
		) => {
			const categoryId = parseInt(payload);
			// fix this
			const categories = genericState.categories.items;
			const category = categories.find(
				(categ) => categ.id === categoryId,
			);
			if (category) {
				const selected = getRecurringCategories(
					// genericState.categories.items,
					category,
				);
				if (selected.parent_id) {
					const parentCategory = categories.find(
						(categ) => categ.id === category.parent_id,
					);
					if (parentCategory) {
						const parentBreadcrumbs =
							generateParentBreadcrumbs(
								categories,
								selected,
								genericState.categories.breadcrumbs,
							);
						genericState.categories.breadcrumbs =
							parentBreadcrumbs;
					}
				}
				if (selected.children.length > 0) {
					const breadcrumbAlreadyExists =
						genericState.categories.breadcrumbs.some(
							(breadcrumb) =>
								breadcrumb.category_id === selected.id,
						);
					if (!breadcrumbAlreadyExists) {
						genericState.categories.breadcrumbs.push({
							name: selected.name,
							path: selected.path,
							category_id: selected.id,
							hasChildren: selected.children.length > 0,
						});
					}
					//
				}
				genericState.categories.selectedItems = selected; //.children;
				genericState.categories.lastSelected = category;
			}
		},
		// return category;
		updateSelectedCategories: (
			genericState,
			{ payload }: { payload: Category },
		) => {
			const selected = getRecurringCategories(
				// genericState.categories.items,
				payload,
			);
			// when to reset?
			if (selected.children.length > 0) {
				genericState.categories.breadcrumbs.push({
					name: selected.name,
					path: selected.path,
					category_id: selected.id,
					hasChildren: selected.children.length > 0,
				});
				//
				genericState.categories.selectedItems = selected; //.children;
			}
			genericState.categories.lastSelected = payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(subscribe.pending, (checkoutState) => {
				checkoutState.subscribe = 'loading';
			})
			.addCase(subscribe.fulfilled, (checkoutState) => {
				checkoutState.subscribe = 'fulfilled';
			})
			.addCase(subscribe.rejected, (checkoutState) => {
				checkoutState.subscribe = 'rejected';
			})
			.addCase(newsletter_unsubscribe.pending, (state) => {
				state.unsubscribe = 'loading';
			})
			.addCase(
				newsletter_unsubscribe.fulfilled,
				(state) => {
					state.unsubscribe = 'fulfilled';
				},
			)
			.addCase(newsletter_unsubscribe.rejected, (state) => {
				state.unsubscribe = 'rejected';
			})

			.addCase(
				fetchCategories.fulfilled,
				(state, action) => {
					state.categories.status = 'fulfilled';
					state.categories.items = action.payload.data;
				},
			)
			.addCase(fetchCategories.rejected, (state) => {
				state.categories.status = 'rejected';
				state.categories.items = [];
				// state.categories.status = 'failed';
				// state.categories.error = action.error.message;
			})
			.addCase(getPromoMessages.rejected, (state) => {
				state.promoMessages.status = 'rejected';
				state.promoMessages.items = [];
				// state.categories.status = 'failed';
				// state.categories.error = action.error.message;
			})
			.addCase(
				getPromoMessages.fulfilled,
				(state, action) => {
					state.promoMessages.status = 'fulfilled';
					state.promoMessages.items =
						action.payload.data.filter(
							(promoItem) => promoItem.enabled,
						);
				},
			)
			// this is now handled from within the thunk itself
			// .addCase(postContact.fulfilled, (state, action) => {
			// 	state.contactForm = {
			// 		message:
			// 			'Thank you for your message, we will be in touch shortly.',
			// 		status: 'fulfilled',
			// 	};
			// })
			// .addCase(postContact.rejected, (state) => {
			// 	state.contactForm = {
			// 		message:
			// 			'Something went wrong, please try again.',
			// 		status: 'rejected',
			// 	};
			// })

			.addCase(fetchVehicles.pending, (state, action) => {
				state.vehicles = {
					...state.vehicles,
					isBeingFetched: true,
					selectedVehicle: undefined,
				};
			})
			.addCase(fetchVehicles.fulfilled, (state, action) => {
				state.vehicles = {
					...state.vehicles,
					isBeingFetched: false,
					details: [...action.payload.data],
					pagination: action.payload.meta,
				};
			})
			.addCase(fetchVehicles.rejected, (state) => {
				state.vehicles = {
					...state.vehicles,
					isBeingFetched: false,
					details: [],
					pagination: undefined,
				};
			})
			.addCase(fetchVehicle.rejected, (state) => {
				state.vehicles = {
					...state.vehicles,
					isQuoteRequestFormShown: false,
					selectedVehicle: undefined,
					isBeingFetched: false,
					details: [],
				};
			})
			.addCase(fetchVehicle.pending, (state) => {
				state.vehicles = {
					...state.vehicles,
					isQuoteRequestFormShown: false,
					selectedVehicle: null,
					isBeingFetched: true,
				};
			})
			.addCase(fetchVehicle.fulfilled, (state, action) => {
				state.vehicles = {
					...state.vehicles,
					selectedVehicle: action.payload.data,
					isBeingFetched: false,
				};
			})
			.addCase(fetchVehicleCategory.rejected, (state) => {
				state.vehicles = {
					...state.vehicles,
					isQuoteRequestFormShown: false,
					isBeingFetched: false,
				};
			})
			.addCase(fetchVehicleCategory.pending, (state) => {
				state.vehicles = {
					...state.vehicles,
					isQuoteRequestFormShown: false,
					isBeingFetched: true,
				};
			})
			.addCase(
				fetchVehicleCategory.fulfilled,
				(state, action) => {
					state.vehicles = {
						...state.vehicles,
						selectedCategory: action.payload.data,
						isBeingFetched: false,
						pagination: action.payload.meta,
					};
				},
			)
			.addCase(fetchSettings.rejected, (state) => {
				state.settings = {
					isBeingFetched: false,
					details: undefined,
				};
			})
			.addCase(fetchSettings.pending, (state) => {
				state.settings = {
					...state.settings,
					isBeingFetched: true,
				};
			})
			.addCase(fetchSettings.fulfilled, (state, action) => {
				state.settings = {
					details: action.payload.data[0],
					isBeingFetched: false,
				};
			})
			.addCase(getBlogPosts.rejected, (state) => {
				state.blog = {
					isBeingFetched: false,
					posts: undefined,
				};
			})
			.addCase(getBlogPosts.pending, (state) => {
				state.blog = {
					...state.blog,
					isBeingFetched: true,
				};
			})
			.addCase(getBlogPosts.fulfilled, (state, action) => {
				state.blog = {
					posts: action.payload.data,
					isBeingFetched: false,
				};
			});
	},
});

export const {
	updateLocalePopover,
	updateCartPopover,
	updateAccountPopover,
	updateSearchPopover,
	updateBaseUrl,
	updateDialogDetails,
	updateVehicleReuqestFormVisibility,
	updateVehicleForQuote,
	updateBreadcrumbs,
	updateSelectedCategories,
	toggleLoadingScreen,
	updateSelectedCategoriesViaUrl,
	resetForFilter,
} = genericSlice.actions;

export const gatherCategoryIds = (state: RootState) => {
	const { lastSelected, breadcrumbs } =
		state.generic.categories;
	let categoryIds = breadcrumbs.map(
		(obj) => obj.category_id,
	);
	return lastSelected?.id
		? [...categoryIds, lastSelected.id]
		: categoryIds;
};

const popoverStateSelector = (
	state: RootState,
	popoverType: 'CART' | 'ACCOUNT' | 'LOCALE' | 'SEARCH',
) => {
	if (popoverType === 'CART') {
		return state.generic.cartPopover.isOpen;
	} else if (popoverType === 'ACCOUNT') {
		return state.generic.accountPopover.isOpen;
	} else if (popoverType === 'LOCALE') {
		return state.generic.localePopover.isOpen;
	} else if (popoverType === 'SEARCH') {
		return state.generic.searchPopover.isOpen;
	}
	return;
};

export const selectPopoverState = createSelector(
	[popoverStateSelector],
	(isOpen) => isOpen,
);

const contactFormSelector = (state: RootState) =>
	state.generic.contactForm;
//todo: move the product related selectors etc to productSlice
export const getContactFormResponse = createSelector(
	[contactFormSelector],
	(contactForm) => contactForm,
);

const categoryForProductSelector = (
	state: RootState,
	category_id?: number,
) => {
	if (category_id === undefined) return undefined;
	const { items: categories } = state.generic.categories;
	return (
		categories.find(
			(category) => category.id === category_id,
		) ?? undefined
	);
};
export const selectCategoryForProduct = createSelector(
	[categoryForProductSelector],
	(category) => category,
);
export const getBaseUrl = (state: RootState) =>
	state.generic.baseUrl;

const loadingStatusSelector = (state: RootState) => {
	//siasztokkkkkk
	return (
		state.generic.categories.status !== 'fulfilled' ||
		(!window.location.pathname.includes('shop') &&
			!state.products.displayable &&
			state.products.status !== 'fulfilled') ||
		state.generic.isLoadingScreenShown
	);
};

export const selectIsLoading = createSelector(
	[loadingStatusSelector],
	(isLoading) => isLoading,
);
// export const getProducts = (state: RootState) => {
// 	const { products } = state.generic;
// 	if (products?.items && products.items.length > 0) {
// 		return products.items;
// 	} else return [];
// };

export const getCategories = (state: RootState) => {
	const { categories } = state.generic;
	if (categories?.items && categories.items.length > 0) {
		return categories.items;
	} else return [];
};

export const getNCategories = (
	state: RootState,
	amount: number,
) => {
	const { items: categories } = state.generic.categories;
	const amountToFetch = Math.min(categories.length, amount);
	return categories?.slice(0, amountToFetch) ?? [];
};

// export const getCategoryForProduct = (
// 	state: RootState,
// 	category_id?: number
// ) => {
// 	if (category_id === undefined) return undefined;
// 	const { items: categories } = state.generic.categories;
// 	return (
// 		categories.find(
// 			(category) => category.id === category_id
// 		) ?? undefined
// 	);
// };

// export default genericSlice.reducer;
export const genericReducer = genericSlice.reducer;
