import React, { useState } from 'react';
import { Pannellum } from 'pannellum-react';

const importAll = (r) => {
	return r.keys().map(r);
};

const images = importAll(
	require.context('./assets/indoors', false, /\.(jpg)$/),
);
const outdoorImages = importAll(
	require.context('./assets/outdoors', false, /\.(jpg)$/),
);

const Scenes = {
	imageOut1: {
		title: 'Image outside 1',
		image: outdoorImages[0],
		pitch: 0,
		yaw: 0,
		hfov: 120,
		hotSpot: {
			type: 'custom',
			pitch: -10,
			yaw: 363,
			scene: 'imageOut2',
		},
		rightHotSpot: {
			type: 'custom',
			pitch: -13,
			yaw: 85,
			scene: 'image1',
		},
	},
	imageOut2: {
		title: 'Image outside 2',
		image: outdoorImages[1],
		pitch: 0,
		yaw: 0,
		hfov: 120,
		hotSpot: {
			type: 'custom',
			pitch: -15,
			yaw: 363,
			scene: 'imageOut3',
		},
		backHotSpot: {
			type: 'custom',
			pitch: -8,
			yaw: 180,
			scene: 'imageOut1',
		},
	},
	imageOut3: {
		title: 'Image outside 3',
		image: outdoorImages[2],
		pitch: 0,
		yaw: 0,
		hfov: 120,
		backHotSpot: {
			type: 'custom',
			pitch: -15,
			yaw: 180,
			scene: 'imageOut2',
		},
	},
	image1: {
		title: 'Image 1',
		image: images[0],
		pitch: 0,
		yaw: 0,
		hfov: 120,
		hotSpot: {
			type: 'custom',
			pitch: -20,
			yaw: 363,
			scene: 'image2',
		},
		leftHotSpot: {
			type: 'custom',
			pitch: -15,
			yaw: 280,
			scene: 'imageOut1',
		},
	},
	image2: {
		title: 'Image 2',
		image: images[1],
		pitch: 0,
		yaw: 0,
		hotSpot: {
			type: 'custom',
			pitch: -25,
			yaw: 360,
			scene: 'image9',
		},
		backHotSpot: {
			type: 'custom',
			pitch: -25,
			yaw: 180,
			scene: 'image1',
		},
		// first right
		rightHotSpot: {
			type: 'custom',
			pitch: -20,
			yaw: 90,
			scene: 'image3',
		},
	},
	image3: {
		title: 'Image 3',
		image: images[2],
		pitch: 0,
		yaw: 0,
		hotSpot: {
			type: 'custom',
			pitch: -35,
			yaw: 350,
			scene: 'image4',
		},
		// view needs to be changed here,
		// it's facing door instead of first sea doo
		backHotSpot: {
			type: 'custom',
			pitch: -23,
			yaw: 180,
			scene: 'image2',
		},
	},
	image4: {
		title: 'Image 4',
		image: images[3],
		pitch: 0,
		yaw: 0,
		hotSpot: {
			type: 'custom',
			pitch: -30,
			yaw: 360,
			scene: 'image5',
		},
		backHotSpot: {
			type: 'custom',
			pitch: -35,
			yaw: 270,
			scene: 'image3',
		},
	},
	image5: {
		title: 'Image 5',
		image: images[4],
		pitch: 0,
		yaw: 0,
		hotSpot: {
			type: 'custom',
			pitch: -19,
			yaw: 360,
			scene: 'image7',
		},
		leftHotSpot: {
			type: 'custom',
			pitch: -25,
			yaw: 270,
			scene: 'image6',
		},
		backHotSpot: {
			type: 'custom',
			pitch: -32,
			yaw: 180,
			scene: 'image4',
		},
	},
	image6: {
		title: 'Image 6',
		image: images[5],
		pitch: 0,
		yaw: 0,
		hotSpot: {
			type: 'custom',
			pitch: -25,
			yaw: 355,
			scene: 'image9',
		},
		// pov is a bit off
		backHotSpot: {
			type: 'custom',
			pitch: -34,
			yaw: 180,
			scene: 'image5',
		},
	},
	image7: {
		title: 'Image 7',
		image: images[6],
		pitch: 0,
		yaw: 0,
		hotSpot: {
			type: 'custom',
			pitch: -20,
			yaw: 3,
			scene: 'image8',
		},
		backHotSpot: {
			type: 'custom',
			pitch: -18,
			yaw: 180,
			scene: 'image5',
		},
	},
	image8: {
		title: 'Image 8',
		image: images[7],
		pitch: 0,
		yaw: 0,
		leftHotSpot: {
			type: 'custom',
			pitch: -5,
			yaw: 307,
			scene: 'image12',
		},
		backHotSpot: {
			type: 'custom',
			pitch: -18,
			yaw: 180,
			scene: 'image7',
		},
	},
	image9: {
		title: 'Image 9',
		image: images[8],
		pitch: 0,
		yaw: 0,
		hotSpot: {
			type: 'custom',
			pitch: -25,
			yaw: 360,
			scene: 'image10',
		},
		backHotSpot: {
			type: 'custom',
			pitch: -27,
			yaw: 180,
			scene: 'image2',
		},
		rightHotSpot: {
			type: 'custom',
			pitch: -25,
			yaw: 95,
			scene: 'image6',
		},
	},
	image10: {
		title: 'Image 10',
		image: images[9],
		pitch: 0,
		yaw: 0,
		hotSpot: {
			type: 'custom',
			pitch: -20,
			yaw: 360,
			scene: 'image11',
		},
		backHotSpot: {
			type: 'custom',
			pitch: -25,
			yaw: 180,
			scene: 'image9',
		},
	},
	image11: {
		title: 'Image 11',
		image: images[10],
		pitch: 0,
		yaw: 0,
		hotSpot: {
			type: 'custom',
			pitch: -18,
			yaw: 360,
			scene: 'image12',
		},
		backHotSpot: {
			type: 'custom',
			pitch: -20,
			yaw: 177,
			scene: 'image10',
		},
	},
	image12: {
		title: 'Image 12',
		image: images[11],
		pitch: 0,
		yaw: 0,
		hotSpot: {
			type: 'custom',
			pitch: -35,
			yaw: 360,
			scene: 'image13',
		},
		rightHotSpot: {
			type: 'custom',
			pitch: 2,
			yaw: 130,
			scene: 'image8',
		},
		backHotSpot: {
			type: 'custom',
			pitch: -20,
			yaw: 180,
			scene: 'image11',
		},
	},
	image13: {
		title: 'Image 13',
		image: images[12],
		pitch: 0,
		yaw: 0,
		hotSpot: {
			type: 'custom',
			pitch: -20,
			yaw: 362,
			scene: 'image14',
		},
		backHotSpot: {
			type: 'custom',
			pitch: -25,
			yaw: 180,
			scene: 'image12',
		},
	},
	image14: {
		title: 'Image 14',
		image: images[13],
		pitch: 0,
		yaw: 180,
		backHotSpot: {
			type: 'custom',
			pitch: -25,
			yaw: 180,
			scene: 'image13',
		},
	},
};

export default function File() {
	const [isReverse, setIsReverse] = useState(false);
	const [currentScene, setCurrentScene] =
		useState('image1');

	const handleHotspotClick = (
		nextScene,
		isBack = false,
	) => {
		if (isBack) {
			setIsReverse(true);
		} else {
			setIsReverse(false);
		}
		setCurrentScene(nextScene);
	};

	return (
		<div
			style={{
				maxWidth: 2000,
				maxHeight: 1200,
				margin: '0 auto 32px',
			}}
		>
			<Pannellum
				width={'100%'}
				height="80vh"
				image={Scenes[currentScene].image}
				pitch={0}
				yaw={isReverse ? 180 : 0}
				hfov={135}
				autoLoad
				showControls={true}
				showFullscreenCtrl={true}
				showZoomCtrl={true}
				orientationOnByDefault={true}
				vOffset={
					Scenes[currentScene]?.hotSpot?.vOffset ?? 0
				}
			>
				{Scenes[currentScene].hotSpot ? (
					<Pannellum.Hotspot
						type="custom"
						pitch={Scenes[currentScene].hotSpot.pitch}
						yaw={Scenes[currentScene].hotSpot.yaw}
						name={currentScene}
						handleClick={() =>
							handleHotspotClick(
								Scenes[currentScene].hotSpot.scene,
							)
						}
					/>
				) : (
					<></>
				)}
				{/* Back HotSpot */}
				{Scenes[currentScene].backHotSpot ? (
					<Pannellum.Hotspot
						type="custom"
						pitch={Scenes[currentScene].backHotSpot.pitch}
						yaw={Scenes[currentScene].backHotSpot.yaw}
						name={`back_${currentScene}`}
						handleClick={() =>
							handleHotspotClick(
								Scenes[currentScene].backHotSpot.scene,
								true,
							)
						}
					/>
				) : (
					<></>
				)}
				{/* Right HotSpot */}
				{Scenes[currentScene].rightHotSpot ? (
					<Pannellum.Hotspot
						type="custom"
						pitch={Scenes[currentScene].rightHotSpot.pitch}
						yaw={Scenes[currentScene].rightHotSpot.yaw}
						name={`right${currentScene}`}
						handleClick={() =>
							handleHotspotClick(
								Scenes[currentScene].rightHotSpot.scene,
								true,
							)
						}
					/>
				) : (
					<></>
				)}
				{/* Lefht HotSpot */}
				{Scenes[currentScene].leftHotSpot ? (
					<Pannellum.Hotspot
						type="custom"
						pitch={Scenes[currentScene].leftHotSpot.pitch}
						yaw={Scenes[currentScene].leftHotSpot.yaw}
						name={`left${currentScene}`}
						handleClick={() =>
							handleHotspotClick(
								Scenes[currentScene].leftHotSpot.scene,
								// true,
							)
						}
					/>
				) : (
					<></>
				)}
			</Pannellum>
		</div>
	);
}
