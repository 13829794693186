import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
	Product,
	fetchSpecificProducts,
	reinstateCart,
	store,
} from '../redux';

export const setLocalStorage = (
	key: string,
	valueToBeStored: any,
) => {
	localStorage.setItem(
		key,
		JSON.stringify(valueToBeStored),
	);
};
const getCartItems = () =>
	localStorage.getItem('cartState');

export const setCartStateInLS = (valueToBeStored: any) => {
	localStorage.setItem(
		'cartState',
		JSON.stringify({
			...valueToBeStored,
			expiresAt: setExpiryDayToOneDay(),
		}),
	);
};

export const extendProductWithFormattedPrices = (
	product: Product,
): Product => {
	const price = formatPriceToForint(product?.price);
	const price_vat_inclusive = formatPriceToForint(
		product?.price_vat_inclusive,
	);
	const current_price_vat_inclusive = formatPriceToForint(
		product?.current_price_vat_inclusive,
	);
	const current_price = formatPriceToForint(
		product?.current_price,
	);
	return {
		...product,
		formatted: {
			current_price_vat_inclusive,
			current_price,
			price_vat_inclusive,
			price,
		},
	};
};
export const formatPriceToForint = (price?: number) => {
	return price
		? new Intl.NumberFormat('hu-HU', {
				style: 'currency',
				currency: 'HUF',
				maximumFractionDigits: 0,
		  }).format(price)
		: undefined;
};
interface CartItem {
	id: number;
	quantity: number;
	expiresAt: string;
}

interface CartData {
	[key: string]: CartItem;
}
export const reinstateCartStateIfRequired = () => {
	const hasExpired = hasCartExpired();
	if (hasExpired === false) {
		const cartItems = getCartItems();
		if (cartItems) {
			const cart: CartData = JSON.parse(cartItems);
			const cartItemArray: {
				id: number;
				quantity: number;
			}[] = Object.values(cart).filter(
				(item) =>
					typeof item === 'object' && !Array.isArray(item),
			);
			store.dispatch(reinstateCart(cartItemArray));
			const products = store.getState().products.items;
			const ids = cartItemArray
				.filter(
					(item) =>
						!products.some(
							(product) => product.id === item.id,
						),
				)
				.map((item) => item.id);
			store.dispatch(fetchSpecificProducts(ids));
		}
	}
};
export const dateFormatter = (date: string) => {
	return new Intl.DateTimeFormat(undefined, {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	}).format(new Date(date));
};
export const setExpiryDayToOneDay = () =>
	new Date(new Date().getTime() + 60000 * 60 * 24);

export const hasCartExpired = () => {
	const currentDate = new Date();

	const sessionString = localStorage.getItem('cartState');
	if (!sessionString) {
		return;
	}

	try {
		const sessionObject: {
			expiresAt: string;
		} = JSON.parse(sessionString);
		const expirationDate = sessionObject.expiresAt;

		if (
			Date.parse(currentDate.toString()) >
			Date.parse(expirationDate)
		) {
			localStorage.removeItem('cartState');
			return true;
		} else {
			return false;
		}
	} catch (error) {
		localStorage.removeItem('cartState');
		console.error('An error occured with your cart', error);
		return true;
	}
};

export const getFormattedDate = (date: Date) => {
	let year = date.getFullYear();
	let month = (1 + date.getMonth())
		.toString()
		.padStart(2, '0');
	let day = date.getDate().toString().padStart(2, '0');

	return month + '/' + day + '/' + year;
};

export const getFormattedDateTimeUK = (date: Date) => {
	return (
		('00' + (date.getMonth() + 1)).slice(-2) +
		'/' +
		('00' + date.getDate()).slice(-2) +
		'/' +
		date.getFullYear() +
		' ' +
		('00' + date.getHours()).slice(-2) +
		':' +
		('00' + date.getMinutes()).slice(-2) +
		':' +
		('00' + date.getSeconds()).slice(-2)
	);
};

export const getFormattedDateTime = (date: Date) => {
	return (
		date.getFullYear() +
		'/' +
		('00' + (date.getMonth() + 1)).slice(-2) +
		'/' +
		('00' + date.getDate()).slice(-2) +
		' ' +
		('00' + date.getHours()).slice(-2) +
		':' +
		('00' + date.getMinutes()).slice(-2) +
		':' +
		('00' + date.getSeconds()).slice(-2)
	);
};

export const cx = (...list) =>
	list.filter(Boolean).join(' ');

export const doScrollToTop = (
	isSidebar: boolean = false,
	behavior: string = 'smooth',
) => {
	if (isSidebar) {
		const elems = document.getElementsByClassName(
			'p-slidemenu-content',
		);
		const elem = elems[0];
		if (elem.scrollTop > 0) {
			elem.scrollTo({ top: 0, behavior: 'auto' });
		}
	} else {
		if (document.documentElement.scrollTop > 0) {
			// @ts-ignore
			window.scrollTo({ top: 0, behavior });
		}
	}
};
// use as component
export const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

export const wordAndLineBreakStyle = {
	overflowWrap: 'break-word',
	wordWrap: 'break-word',
	msWordBreak: 'break-all',
	wordBreak: 'break-word',
	msHyphens: 'auto',
	MozHyphens: 'auto',
	WebkitHyphens: 'auto',
	hyphens: 'auto',
};
