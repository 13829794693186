import { Box, Grid, Popover } from '@mui/material';
import { CartSummary, EmptyCart } from '../../views';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import {
	faSearch,
	faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	RootState,
	getCurrentLanguage,
	getNumberOfItemsInCart,
	logout,
	selectAccountDetails,
	selectPopoverState,
	store,
	toggleLoadingScreen,
	updateAccountPopover,
	updateCartPopover,
	updateLanguage,
	updateLocalePopover,
	updateSearchPopover,
} from '../../redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../themes';
import { Link, useNavigate } from 'react-router-dom';
import styles from './header.module.scss';
import SearchForm from '../search/SearchForm';

export const HeaderButtons = ({
	itemCounterHasBorder = false,
	style = {},
}: {
	itemCounterHasBorder?: boolean;
	style?: object;
}) => {
	const navigate = useNavigate();

	const user = useSelector(selectAccountDetails);
	const numberOfItemsInCart = useSelector(
		getNumberOfItemsInCart,
	);

	const currentLanguage = useSelector(getCurrentLanguage);
	const currentLocale = currentLanguage.toLowerCase();

	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const [accountAnchor, setAccountAnchor] =
		useState<HTMLButtonElement | null>(null);
	const [searchAnchor, setSearchAnchor] =
		useState<HTMLButtonElement | null>(null);
	const [cartAnchor, setCartAnchor] =
		useState<HTMLButtonElement | null>(null);
	const [localeAnchor, setLocaleAnchor] =
		useState<HTMLButtonElement | null>(null);
	// todo: these may not be the best solutions
	const handleMouseEnter = (e) => {
		e.target.style.backgroundColor =
			colors['brp-yellow'].main;
	};
	const handleMouseLeave = (e) => {
		e.target.style.backgroundColor =
			colors['brp-black'].main;
	};

	const handleSearchPopoverClick = (
		event: any, //React.MouseEvent<HTMLButtonElement>,
	) => {
		setSearchAnchor(event.currentTarget);
		store.dispatch(updateSearchPopover(true));
	};
	const handleAccountPopoverClick = (
		event: any, //React.MouseEvent<HTMLButtonElement>,
	) => {
		setAccountAnchor(event.currentTarget);
		store.dispatch(updateAccountPopover(true));
	};
	const handleCartPopoverClick = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		setCartAnchor(event.currentTarget);
		store.dispatch(updateCartPopover(true));
	};
	const handleLocalePopoverClick = (
		event: any & React.MouseEvent<HTMLButtonElement>,
	) => {
		setLocaleAnchor(event.currentTarget);
		store.dispatch(updateLocalePopover({ isOpen: true }));
	};

	const handleAccountPopoverClose = () => {
		setAccountAnchor(null);
		store.dispatch(updateAccountPopover(false));
	};
	const handleSearchPopoverClose = () => {
		setSearchAnchor(null);
		store.dispatch(updateSearchPopover(false));
	};

	const handleCartPopoverClose = () => {
		setCartAnchor(null);
		store.dispatch(updateCartPopover(false));
	};

	const handleLocalePopoverClose = () => {
		setLocaleAnchor(null);
		store.dispatch(updateLocalePopover({ isOpen: false }));
	};

	const handleLocaleSelection = (locale: 'EN' | 'HU') => {
		handleLocalePopoverClose();
		store.dispatch(updateLanguage(locale));
	};
	// future: think of a better way of doing this
	const isSearchPopoverOpen = useSelector(
		(state: RootState) =>
			selectPopoverState(state, 'SEARCH'),
	);
	const isCartPopoverOpen = useSelector(
		(state: RootState) => selectPopoverState(state, 'CART'),
	);
	const isAccountPopoverOpen = useSelector(
		(state: RootState) =>
			selectPopoverState(state, 'ACCOUNT'),
	);
	const isLocalePopoverOpen = useSelector(
		(state: RootState) =>
			selectPopoverState(state, 'LOCALE'),
	);

	const searchPopoverId = isCartPopoverOpen
		? 'search-popover'
		: undefined;
	const cartPopoverId = isCartPopoverOpen
		? 'cart-popover'
		: undefined;
	const accountPopoverId = isAccountPopoverOpen
		? 'account-popover'
		: undefined;
	const localePopoverId = isLocalePopoverOpen
		? 'locale-popover'
		: undefined;
	const handleLogout = async () => {
		store.dispatch(toggleLoadingScreen(true));
		handleAccountPopoverClose();
		navigate('/');
		await store.dispatch(logout());
		store.dispatch(toggleLoadingScreen(false));
	};

	useEffect(() => {
		store.dispatch(updateLocalePopover({ isOpen: false }));
		store.dispatch(updateCartPopover(false));
		store.dispatch(updateAccountPopover(false));
	}, [window.location.pathname]);

	return (
		<Box
			flexDirection="column"
			justifyContent="space-between"
			style={style}
		>
			<Grid
				container
				sx={{
					display: 'flex',
					alignItems: 'flex-start',
				}}
			>
				<svg
					className={styles.icon}
					style={{
						cursor: 'pointer',
						padding: 0,
					}}
					onClick={handleLocalePopoverClick}
					width="32"
					height="32"
					viewBox="0 0 32 32"
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<text
						x="50%"
						y="50%"
						textAnchor="middle"
						alignmentBaseline="middle"
						fontSize="16"
						fill={colors['brp-white'].main}
					>
						{currentLanguage}
					</text>
				</svg>
				<Popover
					slotProps={{ paper: { square: true } }}
					id={localePopoverId}
					open={isLocalePopoverOpen ?? false}
					anchorEl={localeAnchor}
					onClose={handleLocalePopoverClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Grid container flexDirection="column">
						<svg
							style={{
								cursor: 'pointer',
								padding: 0,
								marginRight: 0,
							}}
							className={styles.icon}
							onClick={() => handleLocaleSelection('HU')}
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill={colors['brp-white'].main}
						>
							<text
								x="50%"
								y="50%"
								textAnchor="middle"
								alignmentBaseline="middle"
								fontSize="16"
							>
								HU
							</text>
						</svg>
						<svg
							style={{
								cursor: 'pointer',
								padding: 0,
								marginRight: 0,
							}}
							className={styles.icon}
							onClick={() => handleLocaleSelection('EN')}
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill={colors['brp-white'].main}
						>
							<text
								x="50%"
								y="50%"
								textAnchor="middle"
								alignmentBaseline="middle"
								fontSize="16"
							>
								EN
							</text>
						</svg>
					</Grid>
				</Popover>
				<section
					style={{
						cursor: 'pointer',
						marginRight: '8px',
					}}
					onClick={handleAccountPopoverClick}
				>
					<FontAwesomeIcon
						aria-describedby={accountPopoverId}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						icon={faUser}
						className={styles.icon}
					/>
				</section>
				<Popover
					slotProps={{ paper: { square: true } }}
					id={accountPopoverId}
					open={isAccountPopoverOpen ?? false}
					anchorEl={accountAnchor}
					onClose={handleAccountPopoverClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Grid
						container
						flexDirection="column"
						padding={2}
					>
						{user?.name ? (
							<>
								<Link
									to="account"
									style={{ margin: '4px' }}
								>
									{
										prompts[currentLocale].navigation
											.account
									}
								</Link>
								<Link
									to="account/delivery-details"
									style={{ margin: '4px' }}
								>
									{
										prompts[currentLocale].navigation
											.delivery_details
									}
								</Link>
								<Link
									to="account/order-history"
									style={{ margin: '4px' }}
								>
									{
										prompts[currentLocale].navigation
											.order_history
									}
								</Link>

								<span
									style={{
										margin: '4px',
										cursor: 'pointer',
									}}
									onClick={handleLogout}
								>
									{prompts[currentLocale].navigation.logout}
								</span>
							</>
						) : (
							<>
								<Link to="login" style={{ margin: '4px' }}>
									{prompts[currentLocale].navigation.login}
								</Link>
								<Link
									to="register"
									style={{ margin: '4px' }}
								>
									{
										prompts[currentLocale].navigation
											.register
									}
								</Link>
							</>
						)}
					</Grid>
				</Popover>
				<section
					style={{
						cursor: 'pointer',
						position: 'relative',
					}}
					onClick={handleCartPopoverClick}
				>
					<FontAwesomeIcon
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						icon={faShoppingCart}
						aria-describedby={cartPopoverId}
						className={styles.icon}
					/>
					<div
						className={styles.itemCounterDot}
						style={
							itemCounterHasBorder
								? {
										border: `1px solid ${colors['brp-black'].main}`,
								  }
								: {}
						}
					>
						{numberOfItemsInCart}
					</div>
				</section>
				<Popover
					slotProps={{ paper: { square: true } }}
					id={cartPopoverId}
					open={isCartPopoverOpen ?? false}
					anchorEl={cartAnchor}
					onClose={handleCartPopoverClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					{numberOfItemsInCart > 0 ? (
						<CartSummary isPopover={true} />
					) : (
						<EmptyCart />
					)}
				</Popover>
				<section
					style={{
						cursor: 'pointer',
						marginRight: '8px',
					}}
					onClick={handleSearchPopoverClick}
				>
					<FontAwesomeIcon
						// aria-describedby={accountPopoverId}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						icon={faSearch}
						className={styles.icon}
					/>
				</section>
				<Popover
					id={searchPopoverId}
					open={isSearchPopoverOpen ?? false}
					anchorEl={searchAnchor}
					onClose={handleSearchPopoverClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					slotProps={{ paper: { square: true } }}
				>
					<SearchForm />
				</Popover>
			</Grid>
			{/* <SearchForm /> */}
		</Box>
	);
};
