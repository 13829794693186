import {
	CardActions,
	CardContent,
	CardMedia,
	Chip,
	Grid,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {
	Category,
	RootState,
	selectCategoryForProduct,
	Product,
	calculateDiscountPrice,
} from '../../redux';
import { AddToCart, QuantityButtons } from '..';
import notFound from '../../assets/not-found.png';
import { colors } from '../../themes';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { parseHtml } from '../../views/helpers';

export const ProductCard = ({
	product,
	isShop = false,
	productQuantity,
	buttonsInRow = false,
	isSummary = false,
}: {
	product: Product;
	isShop: boolean;
	buttonsInRow?: boolean;
	productQuantity: number;
	isSummary?: boolean;
}) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);
	const productCategory: Category | undefined = useSelector(
		(state: RootState) =>
			selectCategoryForProduct(state, product?.category_id),
	);
	const isGridView = useSelector(
		(state: RootState) => state.shop.isGridView,
	);

	return (
		<Paper
			variant="outlined"
			sx={{
				// maxWidth: isGridView ? 320 : 'none',
				width: '100%',
				maxHeight: isGridView || isSummary ? 'auto' : 300,
				border: 'none',
			}}
		>
			<CardContent
				style={{
					display:
						isGridView || isSummary ? 'block' : 'flex',
				}}
			>
				<Grid
					item
					xs={12}
					md={isGridView || isSummary ? 12 : 4}
				>
					<Link
						to={`/shop/products/${product?.id}`}
						state={{ productId: product.id }}
						style={{
							display: 'block',
							width:
								isGridView || isSummary
									? '100%'
									: 'max-content',
						}}
					>
						<CardMedia
							component="img"
							alt={product?.name}
							sx={{
								maxHeight: '200px',
								aspectRatio: 3 / 2,
								width:
									isGridView || isSummary ? '100%' : 'auto',
								objectFit: 'contain',
							}}
							image={product?.main_image?.src ?? notFound}
						/>
					</Link>
				</Grid>
				<Grid
					item
					xs={12}
					md={isGridView || isSummary ? 12 : 8}
					padding={0}
					width={
						isGridView || isSummary ? '100%' : 'max-content'
					}
					sx={{
						display:
							isGridView || isSummary ? 'block' : 'flex',
					}}
					flexDirection={
						isGridView || isSummary ? 'column' : 'row'
					}
				>
					<Grid
						item
						md={isGridView || isSummary ? 12 : 9} // 12:6
						container
						display="flex"
						flexDirection="column"
					>
						<Link
							to={`/shop/products/${product?.id}`}
							state={{ productId: product.id }}
						>
							<Typography
								gutterBottom
								variant="h6"
								component="div"
								sx={{ textTransform: 'uppercase' }}
							>
								{product?.name}
							</Typography>
						</Link>
						{isSmallScreen
							? null
							: productCategory?.name && (
									<Chip
										label={productCategory.name}
										sx={{ mb: 2, width: 'max-content' }}
									/>
							  )}
						{!isShop ||
							(!isGridView && !isSummary && (
								<section
									style={{
										color: 'rgba(0, 0, 0, 0.6)',
									}}
								>
									{parseHtml(product?.description)}
								</section>
							))}
						{product?.active_discount ? (
							<Grid
								container
								justifyContent={
									isGridView
										? 'space-between'
										: 'flex-start'
								}
								marginTop={isGridView ? '0' : 'auto'}
							>
								<Typography
									variant="h4"
									style={{
										marginTop: 'auto',
										marginRight: '1rem',
									}}
								>
									{calculateDiscountPrice(product)}
								</Typography>
								<Typography
									variant="h4"
									style={{
										textDecoration: 'line-through',
										marginTop: 'auto',
										marginBottom: '0.2rem',
										fontSize: '1.5rem',
										color: colors['brp-grey'].faded,
									}}
								>
									{product.formatted?.price ??
										product.price}
								</Typography>
							</Grid>
						) : (
							<Typography
								variant="h4"
								style={{ marginTop: 'auto' }}
							>
								{product.formatted?.price ?? product.price}
							</Typography>
						)}
						<Typography
							component="p"
							color={
								product?.available_stock > 0
									? colors['green'].main
									: 'error'
							}
							sx={{ textTransform: 'capitalize' }}
						>
							•{' '}
							{product?.available_stock > 0
								? prompts[currentLocale].shop.in_stock
								: prompts[currentLocale].shop.out_of_stock}
						</Typography>
					</Grid>
					{product?.available_stock > 0 ? (
						<Grid
							item
							md={isGridView || isSummary ? 12 : 3}
							// height="max-content"
						>
							<CardActions
								sx={{
									padding: 0,
									display: 'flex',
									justifyContent: isGridView
										? 'space-between'
										: 'flex-end',
									flexWrap: 'wrap',
									alignItems: isGridView
										? 'flex-start'
										: 'flex-end',
									height: '100%',
									flexDirection:
										// !isSummary &&
										isSmallScreen ? 'row' : 'column',
									// flexDirection: isGridView
									// 	? 'row'
									// 	: 'column',
								}}
							>
								{/* <Button size="small">Share</Button> */}

								<QuantityButtons
									isShop={isShop}
									productId={product.id}
									currentQuantity={productQuantity}
									style={{
										width:
											isGridView || isSummary
												? isSmallScreen
													? '45%'
													: '100%'
												: '90%',
										marginLeft: isSmallScreen ? 0 : 'auto',
										minWidth: 120,
										flex: isGridView ? 1 : 'none',
									}}
								/>

								<AddToCart
									id={product.id}
									// todo: do this width better
									style={{
										width:
											isGridView || isSummary
												? isSmallScreen
													? '45%'
													: '100%'
												: '90%',
										marginLeft: isSmallScreen ? 0 : 'auto',
										marginTop: 8,
										minWidth: 120,
										flex: isGridView ? 1 : 'none',
									}}
									// style={{ flex: 0.9 }}
									//  onClick={handleProduct}
								/>
							</CardActions>
						</Grid>
					) : null}
				</Grid>
			</CardContent>
		</Paper>
	);
};
