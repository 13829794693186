import { Container, Grid, Skeleton } from '@mui/material';

export const ProductsSkeleton = () => {
	return (
		<Container sx={{ paddingLeft: 0, paddingRight: 0 }}>
			<Grid
				container
				justifyContent="space-between"
				marginBottom={4}
				rowGap={2}
			>
				<Skeleton
					variant="rectangular"
					sx={{
						width: { xs: '48%', lg: '29%', xl: '24%' },
					}}
					height={400}
				/>
				<Skeleton
					variant="rectangular"
					sx={{
						width: { xs: '48%', lg: '29%', xl: '24%' },
					}}
					height={400}
				/>
				<Skeleton
					variant="rectangular"
					sx={{
						width: { xs: '48%', lg: '29%', xl: '24%' },
					}}
					height={400}
				/>
				<Skeleton
					variant="rectangular"
					sx={{
						width: { xs: '48%', lg: '29%', xl: '24%' },
					}}
					height={400}
				/>
				<Skeleton
					variant="rectangular"
					sx={{
						width: { xs: '48%', lg: '29%', xl: '24%' },
					}}
					height={400}
				/>
				<Skeleton
					variant="rectangular"
					sx={{
						width: { xs: '48%', lg: '29%', xl: '24%' },
					}}
					height={400}
				/>
				<Skeleton
					variant="rectangular"
					sx={{
						width: { xs: '48%', lg: '29%', xl: '24%' },
					}}
					height={400}
				/>
				<Skeleton
					variant="rectangular"
					sx={{
						width: { xs: '48%', lg: '29%', xl: '24%' },
					}}
					height={400}
				/>
			</Grid>
		</Container>
	);
};
