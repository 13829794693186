import { Grid, Typography } from '@mui/material';
import { colors } from '../../../../themes';

export const RenderErrors = ({
	errors,
	isTandC = false,
	isPrivacyPolicy = false,
}) => {
	return (
		<Grid
			container
			flexDirection="column"
			marginBottom={isTandC || isPrivacyPolicy ? 0 : 2}
			marginTop={0}
			// marginTop={isTandC || isPrivacyPolicy ? -3 : 0}
		>
			{Object.values(errors as any).flatMap(
				(errorMessage: any) =>
					typeof errorMessage === 'object' ? (
						Object.values(errorMessage).map(
							(nestedError: any, index: number) => (
								<Typography
									color={colors['canam-on-red'].main}
									key={`${index}-${nestedError}`}
									marginTop={0}
								>
									{nestedError}
								</Typography>
							),
						)
					) : (
						<Typography
							color={colors['canam-on-red'].main}
							key={errorMessage}
							marginTop={0}
						>
							{errorMessage}
						</Typography>
					),
			)}
		</Grid>
	);
};
