import { useEffect, useState } from 'react';
import {
	GoogleMap,
	InfoWindow,
	useLoadScript,
	Marker,
} from '@react-google-maps/api';
import usePlacesAutocomplete, {
	getDetails,
	getGeocode,
	getLatLng,
} from 'use-places-autocomplete';
import {
	TextField,
	Autocomplete,
	Skeleton,
	Grid,
	Button,
} from '@mui/material';
import {
	RootState,
	fetchParcelShops,
	store,
	updateSelectedLocker,
} from '../../../../redux';
import { useSelector } from 'react-redux';
import { renderLockerDetails } from '../helpers';

const MapSkeleton = () => (
	<Grid container flexDirection="column">
		<Grid
			container
			sx={{ marginBottom: 1 }}
			justifyContent="space-between"
		>
			<Skeleton
				variant="rectangular"
				width="66%"
				height={55}
			/>
			<Skeleton
				variant="rectangular"
				width="33%"
				height={55}
			/>
		</Grid>
		<Skeleton
			variant="rectangular"
			width="100%"
			height={300}
		/>
	</Grid>
);
const libraries: any = ['places'];

export const LockerMap = () => {
	// todo: resolve this
	const { isLoaded } = useLoadScript({
		googleMapsApiKey:
			process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY!,
		libraries,
	});

	if (!isLoaded) return <MapSkeleton />;
	return <Map />;
};

export const CustomMarker = ({
	translations,
	lockerDetail,
	shown,
	setShown,
	selectLocker,
}) => {
	// todo: extend marker with more information
	const {
		address,
		openingDays,
		distance,
		externalContactDetails,
		partnerLogos,
		paymentMethods,
		parcelExchangeTime,
		name,
	} = lockerDetail;

	const coordinates = {
		lat: address.latitude,
		lng: address.longitude,
	};

	const closeInfoWindow = () => setShown(undefined);
	const openInfoWindow = () => setShown(lockerDetail);

	const handleLockerSelection = () =>
		selectLocker(lockerDetail);

	return (
		<Marker
			clickable
			onClick={openInfoWindow}
			title={name}
			position={coordinates}
		>
			{shown?.parcelShopId === lockerDetail.parcelShopId ? (
				<InfoWindow
					onCloseClick={closeInfoWindow}
					position={coordinates}
					options={{ minWidth: 200, maxWidth: 300 }}
				>
					<>
						<Button
							variant="brp-yellow"
							sx={{
								paddingBottom: 0,
								paddingTop: '2px',
								marginBottom: 1,
							}}
							onClick={handleLockerSelection}
						>
							{translations.buttons.select}
						</Button>
						{renderLockerDetails({
							lockerDetail,
							translations,
						})}
					</>
				</InfoWindow>
			) : null}
		</Marker>
	);
};

const Map = () => {
	const [shown, setShown] = useState(undefined);
	const [lockerSelected, setLockerSelected]: [
		object | undefined,
		any,
	] = useState(undefined);

	const [currentZoom, setCurrentZoom] =
		useState<number>(15);
	const [currentCentre, setCurrentCentre] = useState<{
		lat: number;
		lng: number;
	}>({
		lat: 47.4979,
		lng: 19.0402,
	});
	const [mapRef, setMapRef]: [any, any] = useState(null);
	const handleOnLoad = (map) => {
		setMapRef(map);
	};
	// todo: generalise these funcs
	const getCurrentCentre = () => {
		getCurrentZoom();
		const centre = handleCenterChanged();
		setCurrentCentre({
			lat: centre.lat(),
			lng: centre.lng(),
		});
	};

	const getCurrentZoom = () => {
		const zoom = handleZoomChanged();
		setCurrentZoom(zoom);
	};
	const handleCenterChanged = () => {
		if (mapRef) {
			const newCentre = mapRef.getCenter();
			return newCentre;
		}
	};
	const handleZoomChanged = () => {
		if (mapRef) {
			const newZoom = mapRef.getZoom();
			return newZoom;
		}
	};

	const parcelShops = useSelector(
		(state: RootState) => state.checkout.parcelShops,
	);
	const lockerDetails = parcelShops.currentPage;

	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);

	useEffect(() => {
		if (lockerSelected) {
			store.dispatch(updateSelectedLocker(lockerSelected));
		}
	}, [lockerSelected]);

	useEffect(() => {
		if (currentCentre) {
			store.dispatch(fetchParcelShops(currentCentre));
		}
	}, [currentCentre]);

	const renderLockerLocations = () => {
		const locations = lockerDetails.map((locker: any) => {
			const { parcelShopId } = locker;
			return (
				<CustomMarker
					selectLocker={setLockerSelected}
					translations={prompts[currentLocale]}
					key={parcelShopId}
					lockerDetail={locker}
					shown={shown}
					setShown={setShown}
				/>
			);
		});
		return <>{locations}</>;
	};

	return parcelShops.isBeingFetched ? (
		<MapSkeleton />
	) : (
		<>
			<Grid
				container
				className="places-container"
				sx={{ marginTop: 2 }}
			>
				<PlacesAutocomplete
					translations={prompts[currentLocale]}
					setSelected={setCurrentCentre}
				/>
				<Button
					sx={{ flex: 1 }}
					variant="brp-black"
					onClick={getCurrentCentre}
				>
					{prompts[currentLocale].text.more_lockers}
				</Button>
			</Grid>
			<GoogleMap
				onLoad={handleOnLoad}
				onCenterChanged={handleCenterChanged}
				onZoomChanged={handleZoomChanged}
				zoom={currentZoom}
				center={currentCentre}
				mapContainerClassName="map-container"
			>
				{lockerDetails.length > 0 &&
					renderLockerLocations()}
			</GoogleMap>
		</>
	);
};

const processAddressDetails = (addressDetails) => {
	const address = {
		street: '',
		street_extra: '',
		city: '',
		post_code: '',
		// country: '',
	};
	for (const component of addressDetails as google.maps.GeocoderAddressComponent[]) {
		const componentType = component.types[0];

		switch (componentType) {
			case 'street_number': {
				address.street = component.long_name;
				break;
			}

			case 'route': {
				address.street = `${component.long_name} ${address.street}`;
				break;
			}

			case 'postal_code': {
				address.post_code = component.long_name;
				break;
			}

			case 'locality':
				address.city = component.long_name;
				break;

			//   case "country":
			//     address.country = component.long_name;
			//     break;
		}
	}
	return address;
};

export const PlacesAutocomplete = ({
	translations,
	setSelected,
	isAutofill = false,
}: {
	translations: any;
	isAutofill?: boolean;
	setSelected?: any;
}) => {
	const {
		ready,
		value,
		suggestions: { status, data },
		setValue,
	} = usePlacesAutocomplete({
		requestOptions: {
			componentRestrictions: { country: ['hu'] },
			language: 'hu',
		},
	});

	const handleSelect = async (address) => {
		try {
			if (address) {
				setValue(address, false);
				const results: any = await getGeocode({ address });
				if (isAutofill) {
					const details: any = await getDetails({
						placeId: results[0].place_id,
						language: 'hu',
					});
					const processedAddress = processAddressDetails(
						details.address_components,
					);
					setSelected(processedAddress);
				} else {
					const { lat, lng }: { lat: number; lng: number } =
						getLatLng(results[0]);
					setSelected({ lat, lng });
				}
			}
		} catch (e) {
			console.log('e is: ', e);
		}
	};

	return (
		<Autocomplete
			sx={{ flex: 2 }}
			options={
				status === 'OK'
					? data.map((suggestion) => suggestion.description)
					: []
			}
			renderInput={(params) => (
				<TextField
					{...params}
					label={translations.placeholder.map_search}
					sx={{ '& fieldset': { border: 'none' } }}
					disabled={!ready}
					value={value}
					onChange={(e) => setValue(e.target.value)}
				/>
			)}
			onChange={(e, address) => handleSelect(address)}
		/>
	);
};
