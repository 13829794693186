import {
	createSelector,
	createSlice,
} from '@reduxjs/toolkit';
import { LocaleState, RootState } from '../types';
import { getLangFile } from '../thunks';

const initialState: LocaleState = {
	languages: undefined,
	// currently defaulting to EN
	// as translations are needed
	currentLanguage: 'HU',
};

export const localeSlice = createSlice({
	name: 'locale',
	initialState,
	reducers: {
		updateLanguage: (
			state,
			{ payload }: { payload: 'EN' | 'HU' },
		) => {
			state.currentLanguage = payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getLangFile.rejected, (state) => {
				// todo: show reject
				// console.log('here');
			})
			.addCase(getLangFile.fulfilled, (state, action) => {
				state.languages = action.payload;
			});
	},
});
const selectCurrentLanguage = (state: RootState) =>
	state.locale.currentLanguage;
export const getCurrentLanguage = createSelector(
	[selectCurrentLanguage],
	(language) => language,
);
export const { updateLanguage } = localeSlice.actions;

// export default genericSlice.reducer;
export const localeReducer = localeSlice.reducer;
