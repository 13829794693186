import Markdown from 'react-markdown';
import parse from 'html-react-parser';

export const parseHtml = (message: string) =>
	parse(message) as string;

export const parseMarkdown = (message: string) => {
	return (
		<Markdown
			allowedElements={['p', 'a']}
			components={{
				p: (props) => <p>{props.children}</p>,
				a: (props) => (
					<a
						href={props.href}
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</a>
				),
			}}
		>
			{message}
		</Markdown>
	);
};
