import {
	Button,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import styles from './headings.module.scss';
import { colors } from '../../themes';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

type HeadingProps = {
	lineType?: 'HORIZONTAL' | 'VERTICAL';
};

export const Heading = ({
	title,
	subTitle,
	verticalLineColor,
	lineType = 'VERTICAL',
	hasCta = false,
	path = '',
}) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const navigate = useNavigate();
	const navigateToShop = () => {
		navigate(path);
	};
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);
	return (
		<Container
			className={styles.headingContainer}
			// style={
			// 	lineType === 'HORIZONTAL' ? { paddingLeft: 0 } : {}
			// }
		>
			{lineType === 'VERTICAL' && !isSmallScreen ? (
				<div
					className={styles.verticalLine}
					style={{ backgroundColor: verticalLineColor }}
				></div>
			) : null}
			<Container
				sx={{
					padding: 0,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					flexWrap: 'wrap',
				}}
			>
				<Grid item>
					<Typography
						variant="h4"
						className={styles.subHeading}
						noWrap
						style={{ width: 'max-content' }}
						component="p"
						sx={
							lineType === 'HORIZONTAL'
								? { paddingLeft: 0 }
								: {}
						}
					>
						{title}
					</Typography>
					{lineType === 'HORIZONTAL' ? (
						<section
							style={{
								width: '80px',
								height: '4px',
								backgroundColor: colors['brp-yellow'].main,
								// marginLeft: '250px',
							}}
						></section>
					) : null}
				</Grid>
				{hasCta && (
					<Button
						variant="brp-yellow"
						onClick={navigateToShop}
						sx={{ margin: isSmallScreen ? '16px auto' : 0 }}
					>
						{prompts[currentLocale].buttons.shop_now}
					</Button>
				)}
			</Container>
			<Typography
				variant="caption"
				className={styles.subTitle}
				sx={{ textAlign: 'left' }}
				// noWrap
				component="p"
			>
				{subTitle}
			</Typography>
		</Container>
	);
};
