import { Box, Slider, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { colors } from '../../../themes';
import { useSelector } from 'react-redux';
import {
	RootState,
	applyPriceRange,
	leastExpensiveItemSelector,
	mostExpensiveItemSelector,
	store,
} from '../../../redux';
import { useDebounce } from '../../../helpers';
import { addListener } from '@reduxjs/toolkit';

export const RangeSlider = ({ setFilterPrice }) => {
	// this needs to only account for the displayed items
	const minValue = useSelector(leastExpensiveItemSelector);
	const maxValue = useSelector(mostExpensiveItemSelector);
	const priceFilters = useSelector(
		(state: RootState) =>
			state.products.filtersApplied.price,
	);
	const [value, setValue] = useState<number[]>([
		minValue ?? 0,
		maxValue,
	]);
	const debouncedSliderValues = useDebounce<number[]>(
		value,
		1000,
	);

	const [inputMinValue, setInputMinValue] = useState(
		minValue.toString(),
	);
	const [inputMaxValue, setInputMaxValue] = useState(
		maxValue.toString(),
	);

	useEffect(() => {
		// note to self for future:
		// Could also just `return dispatch(addListener())` directly, but showing this
		// as a separate variable to be clear on what's happening
		// not ideal, but it does work and isn't invalid
		const unsubscribe = store.dispatch(
			addListener({
				actionCreator: applyPriceRange,
				effect: (action, listenerApi) => {
					setFilterPrice();
				},
			}),
		);
		return unsubscribe;
	}, []);

	useEffect(() => {
		if (priceFilters.length === 0) {
			setValue([minValue ?? 0, maxValue]);
			setInputMinValue(minValue.toString());
			setInputMaxValue(maxValue.toString());
		}
	}, [priceFilters]);

	const handleChange = (
		_event: any, //React.SyntheticEvent,
		newValue: number | number[],
	) => {
		setValue(newValue as number[]);
		setInputMinValue(newValue[0].toString());
		setInputMaxValue(newValue[1].toString());
	};

	const handleMinInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const inputValue = parseInt(event.target.value);
		const newValue =
			maxValue < inputValue ? maxValue : inputValue;
		setInputMinValue(newValue.toString());
		setValue([newValue, value[1]]);
	};

	const handleMaxInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const inputValue = parseInt(event.target.value);
		const newValue =
			maxValue < inputValue ? maxValue : inputValue;
		setInputMaxValue(newValue.toString());
		setValue([value[0], newValue]);
	};
	const handlePriceRangeChange = () => {
		store.dispatch(applyPriceRange([value[0], value[1]]));
	};

	const handleInputSubmit = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			store.dispatch(applyPriceRange([value[0], value[1]]));
		}
	};

	return (
		<Box id="price-range-container">
			<Slider
				value={value}
				min={minValue}
				max={maxValue}
				step={100}
				onChange={handleChange}
				onChangeCommitted={handlePriceRangeChange}
				valueLabelDisplay="auto"
				sx={{
					// paddingX: 2,
					color: colors['brp-yellow'].main,
				}}
			/>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<TextField
					label="Min"
					type="number"
					inputProps={{
						step: 100,
						min: minValue,
						max: maxValue,
					}}
					value={inputMinValue}
					onChange={handleMinInputChange}
					onKeyDown={handleInputSubmit}
					size="small"
					sx={{ width: '45%' }}
				/>
				<TextField
					sx={{ width: '45%' }}
					size="small"
					label="Max"
					type="number"
					inputProps={{
						step: 100,
						min: minValue,
						max: maxValue,
					}}
					value={inputMaxValue}
					onChange={handleMaxInputChange}
					onKeyDown={handleInputSubmit}
				/>
			</Box>
		</Box>
	);
};
