import React from 'react';
import { colors } from '../../themes';
import CloseIcon from '@mui/icons-material/Close';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
	RootState,
	store,
	updateDialogDetails,
} from '../../redux';

export const GenericDialog = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];

	const theme = useTheme();
	const fullScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);

	const dialogDetails = useSelector(
		(state: RootState) => state.generic.dialogDetails,
	);
	const { isError, content, visible } = dialogDetails;

	const handleClose = () => {
		store.dispatch(
			updateDialogDetails({
				visible: false,
				// resetting content and isError here
				// causes jittering upon closing the dialog
				// so commented them out
				// content: undefined,
				// isError: undefined,
			}),
		);
	};

	const displayableContent =
		Array.isArray(content) && content.length > 1
			? content.map((contentRow) => <li>{contentRow}</li>)
			: content;

	return (
		<Dialog
			open={visible ?? false}
			draggable={false}
			onClose={handleClose}
			fullScreen={fullScreen}
			scroll="paper"
			sx={{ margin: '0 auto' }}
			PaperProps={{
				sx: {
					border: fullScreen
						? 'none'
						: `1px solid ${
								isError
									? colors['canam-on-red'].main
									: colors['brp-black'].main
						  }`,
					borderRadius: 0,
				},
			}}
		>
			<DialogTitle
				sx={{
					paddingBottom: 0,
					marginRight: 4,
					marginLeft: 4,
					color: isError
						? colors['canam-on-red'].main
						: colors['green'].main,
					textAlign: 'center',
				}}
			>
				{isError
					? translations.errors.generic
					: translations.dialog.success}
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={handleClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent sx={{ py: 1 }}>
				<DialogContentText
					tabIndex={-1}
					display="flex"
					flexDirection={
						Array.isArray(content) ? 'column' : 'row'
					}
				>
					{displayableContent}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="brp-black" onClick={handleClose}>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
};
