import React, { useEffect, useState } from 'react';
import {
	Box,
	Toolbar,
	IconButton,
	Typography,
	Container,
	useMediaQuery,
	useTheme,
	Grid,
	Dialog,
	MobileStepper,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import jetpower from '../../assets/jetpower.png';
import styles from './header.module.scss';
import { cx } from '../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { HeaderButtons } from './HeaderButtons';
import { GridCloseIcon } from '@mui/x-data-grid';
import { colors } from '../../themes';
import { getPages } from './headerHelpers';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	RootState,
	logout,
	selectAccountDetails,
	store,
} from '../../redux';
import {
	faFacebookF,
	faInstagram,
	faTiktok,
} from '@fortawesome/free-brands-svg-icons';

const PromoMessages = () => {
	const promoDetails = useSelector(
		(state: RootState) => state.generic.promoMessages.items,
	);
	const [activeStep, setActiveStep] = React.useState(0);
	const maxSteps = promoDetails?.length;

	const handleNext = () => {
		if (maxSteps && activeStep + 1 === maxSteps)
			setActiveStep(0);
		else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	useEffect(() => {
		const interval = setInterval(handleNext, 3500); // Change slide every 5 seconds (5000 milliseconds)

		return () => clearInterval(interval); // Clean up interval on component unmount
	}, [activeStep]);

	const promoItems = promoDetails?.map((promoItem) => {
		return (
			<Link
				rel="noreferrer"
				target="_blank"
				to={promoItem.link}
				style={{ marginTop: '3px' }}
			>
				<span>{promoItem.text}</span>
			</Link>
		);
	});
	return maxSteps && promoItems ? (
		<Grid
			container
			sx={{
				'& .MuiMobileStepper-positionStatic': {
					display: 'none',
				},
			}}
			justifyContent="center"
		>
			{promoItems[activeStep]}
			<MobileStepper
				variant="text"
				steps={maxSteps}
				position="static"
				activeStep={activeStep}
				nextButton={<></>}
				backButton={<></>}
			/>
		</Grid>
	) : null;
};

export const InfoLine = () => {
	const navigate = useNavigate();
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const accountDetails = useSelector(selectAccountDetails);
	const userIsLoggedIn = !!accountDetails?.id;

	const toggleMenuDialog = () => {
		setIsMenuOpen((prevValue) => !prevValue);
	};

	const closeMenuDialog = () => {
		setIsMenuOpen(false);
	};

	const handleLogout = () => {
		store.dispatch(logout());
		navigate('/');
	};

	const pages = getPages(prompts[currentLocale]);

	return (
		<nav
			id={cx(styles.jetpowerInfoline)}
			style={
				isSmallScreen && !isMenuOpen
					? { position: 'sticky', top: 0, zIndex: 100 }
					: {}
			}
		>
			<Container
				maxWidth="lg"
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
			>
				{isSmallScreen ? null : (
					<Box sx={{ display: 'flex', flex: 1 }}>
						<Box className={styles.left}>
							<FontAwesomeIcon
								icon={faPhoneAlt}
								style={{ marginRight: 8 }}
							/>
							<a
								href="tel:+36 123 3412"
								rel="noreferrer"
								target="_blank"
								style={{
									marginRight: '16px',
									// display: { xs: 'none', md: 'flex' },
									fontFamily: 'inherit',
									fontWeight: 300,
									fontSize: '0.9rem',
									// letterSpacing: '.3rem',
									color: 'inherit',
									marginTop: '2px',
								}}
							>
								+36 24 492 280
							</a>
							<FontAwesomeIcon
								icon={faEnvelope}
								style={{ marginRight: 8 }}
							/>
							<Typography
								noWrap
								component="a"
								href="mailto:info@jetpower.com?subject=Enquiry"
								sx={{
									marginRight: '16px',
									display: { xs: 'none', md: 'flex' },
									fontFamily: 'inherit',
									fontWeight: 300,
									fontSize: '0.9rem',
									color: 'inherit',
									marginTop: '2px',
								}}
							>
								info@jetpower.com
							</Typography>
						</Box>
						<Box
							sx={{ flex: 1 }}
							className={styles.rightSide}
						>
							<PromoMessages />
						</Box>
						<Box>
							<a
								style={{
									width: 'max-content',
									color: colors['brp-grey'].main,
								}}
								href="https://www.facebook.com/jetpowerhungary/"
								rel="noreferrer"
								target="_blank"
							>
								<FontAwesomeIcon
									icon={faFacebookF}
									color={colors['brp-black'].main}
									style={{ marginTop: 2 }}
								/>
							</a>
							<a
								style={{
									width: 'max-content',
								}}
								href="https://www.instagram.com/jetpower_hungary"
								rel="noreferrer"
								target="_blank"
							>
								<FontAwesomeIcon
									icon={faInstagram}
									color={colors['brp-black'].main}
									style={{ marginTop: 2, marginLeft: 8 }}
								/>
							</a>
							<a
								style={{ width: 'max-content' }}
								href="https://www.tiktok.com/@jetpower_hungary"
								rel="noreferrer"
								target="_blank"
							>
								<FontAwesomeIcon
									icon={faTiktok}
									color={colors['brp-black'].main}
									style={{ marginTop: 2, marginLeft: 8 }}
								/>
							</a>
						</Box>
					</Box>
				)}
				<Box
					sx={{
						flexGrow: 1,
						display: { xs: 'flex', md: 'none' },
					}}
				>
					<IconButton
						size="large"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={toggleMenuDialog}
						sx={{ paddingX: 0 }}
					>
						<MenuIcon
							// color="red"
							sx={{ fill: colors['brp-white'].main }}
						/>
					</IconButton>
				</Box>
				{isSmallScreen ? (
					<Dialog
						open={isMenuOpen}
						onClose={toggleMenuDialog}
						fullScreen
						// maxHeight="100vh"
						// sx={{

						// }}
						sx={{
							maxHeight: '100vh',
							maxWidth: '100vw',
							// overflow: 'hidden',
						}}
						PaperProps={{
							sx: {
								backgroundColor: colors['brp-black'].main,
							},
						}}
					>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={toggleMenuDialog}
								aria-label="close"
							>
								<GridCloseIcon
									// size="32px"
									sx={{
										fontSize: '32px',
										fill: colors['brp-white'].main,
									}}
								/>
							</IconButton>
						</Toolbar>
						<Grid
							container
							paddingX={6}
							paddingTop={2}
							flexDirection="column"
							height="100%"
							// overflow="hidden"
						>
							{pages.map((page) => (
								<Link
									key={page.text}
									style={{
										color: colors['brp-white'].main,
										fontSize: '1.75rem',
										marginBottom: '16px',
									}}
									onClick={closeMenuDialog}
									to={page.route}
									id={`${page.text}-nav-item`}
									// id={page.id}
									className={styles.navigationItem}
								>
									{page.text}
								</Link>
							))}
							<Grid
								container
								flexDirection="column"
								sx={{ marginTop: 4 }}
							>
								{userIsLoggedIn ? (
									<>
										<Link
											to="/account"
											style={{
												color: colors['brp-white'].main,
												fontSize: '1.75rem',
												marginBottom: '16px',
											}}
											onClick={closeMenuDialog}
										>
											Account
										</Link>
										<Link
											to="/account/order-history"
											style={{
												color: colors['brp-white'].main,
												fontSize: '1.75rem',
												marginBottom: '16px',
											}}
											onClick={closeMenuDialog}
										>
											Order History
										</Link>
										<Link
											to="/account/delivery-details"
											style={{
												color: colors['brp-white'].main,
												fontSize: '1.75rem',
												marginBottom: '16px',
											}}
											onClick={closeMenuDialog}
										>
											Delivery Details
										</Link>
										<Link
											to="/"
											style={{
												color: colors['brp-white'].main,
												fontSize: '1.75rem',
												marginBottom: '16px',
											}}
											onClick={() => {
												closeMenuDialog();
												handleLogout();
											}}
										>
											Logout
										</Link>
									</>
								) : (
									<>
										<Link
											to="/login"
											style={{
												color: colors['brp-white'].main,
												fontSize: '1.75rem',
												marginBottom: '16px',
											}}
											onClick={closeMenuDialog}
										>
											Login
										</Link>
										<Link
											to="/register"
											style={{
												color: colors['brp-white'].main,
												fontSize: '1.75rem',
												marginBottom: '16px',
											}}
											onClick={closeMenuDialog}
										>
											Register
										</Link>
									</>
								)}
							</Grid>
							<Link
								to="/"
								style={{
									marginTop: 'auto',
									marginBottom: '16px',
									width: '100%',
								}}
							>
								<img
									style={{
										width: '100%',
										filter: 'invert(0.7)',
									}}
									src={jetpower}
									alt="Jetpower"
								/>
							</Link>
						</Grid>
					</Dialog>
				) : null}
				{isSmallScreen ? (
					<HeaderButtons
						itemCounterHasBorder
						style={{ marginBottom: '-5px' }}
					/>
				) : null}
			</Container>
		</nav>
	);
};
