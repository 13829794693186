import {
	Button,
	Grid,
	Paper,
	Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux';
import { useNavigate } from 'react-router-dom';

export const CheckoutAs = ({ setContinueAsGuest }) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const navigate = useNavigate();
	return (
		<Grid
			container
			sx={{
				marginBottom: { xs: 2, md: 4 },
				flexDirection: { xs: 'column', md: 'row' },
			}}
			columnGap={2}
		>
			<Paper
				variant="outlined"
				square
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1,
					marginX: { xs: 2, md: 0 },
					marginBottom: { xs: 1, md: 0 },
					padding: { xs: 2, md: 4 },
				}}
			>
				<Typography variant="h6" component="h3">
					{translations.text.guest_checkout}
				</Typography>
				<Typography
					variant="body1"
					component="p"
					sx={{ marginBottom: 2 }}
				>
					{translations.text.guest_checkout_description}
				</Typography>
				<Button
					variant="brp-yellow-checkout"
					onClick={() => setContinueAsGuest(true)}
					sx={{ width: 'max-content', marginTop: 'auto' }}
				>
					{translations.buttons.continue_as_guest}
				</Button>
			</Paper>
			<Paper
				variant="outlined"
				square
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1,
					marginX: { xs: 2, md: 0 },
					padding: { xs: 2, md: 4 },
				}}
			>
				<Typography variant="h6" component="h3">
					{translations.buttons.my_account}
				</Typography>
				<Typography
					variant="body1"
					component="p"
					sx={{ marginBottom: 2 }}
				>
					{translations.text.login_description}
				</Typography>
				<Grid container>
					<Button
						variant="brp-yellow"
						sx={{ width: 'max-content' }}
						onClick={() =>
							navigate('/login', {
								state: {
									from: '/checkout',
									isRedirect: true,
								},
							})
						}
					>
						{translations.navigation.login}
					</Button>
					<Button
						variant="brp-black"
						sx={{
							textTransform: 'capitalize',
							width: 'max-content',
							marginLeft: 1,
						}}
						onClick={() =>
							navigate('/register', {
								state: {
									from: '/checkout',
									isRedirect: true,
								},
							})
						}
					>
						{translations.navigation.register}
					</Button>
				</Grid>
			</Paper>
		</Grid>
	);
};
