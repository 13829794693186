import {
	faList,
	faTh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid } from '@mui/material';
import {
	RootState,
	handleViewChange,
	store,
} from '../../../redux';
import { useSelector } from 'react-redux';
import { colors } from '../../../themes';

export const ProductView = () => {
	const isGridView = useSelector(
		(state: RootState) => state.shop.isGridView,
	);

	const changeView = (viewType: 'grid' | 'list') => {
		store.dispatch(handleViewChange(viewType));
	};
	return (
		<Grid container width="max-content" paddingX={2}>
			<Box
				padding="4px"
				border={`1px solid ${
					isGridView
						? colors['brp-black'].main
						: colors['brp-grey'].main
				}`}
				color={
					isGridView
						? colors['brp-black'].main
						: colors['brp-grey'].main
				}
				alignItems="center"
				justifyContent="center"
				display="flex"
				sx={{ marginRight: 1, cursor: 'pointer' }}
				data-view="grid"
				onClick={() => changeView('grid')}
				// height="max-height"
			>
				<FontAwesomeIcon icon={faTh} />
			</Box>
			<Box
				border={`1px solid ${
					isGridView
						? colors['brp-grey'].main
						: colors['brp-black'].main
				}`}
				color={
					isGridView
						? colors['brp-grey'].main
						: colors['brp-black'].main
				}
				alignItems="center"
				justifyContent="center"
				display="flex"
				padding="4px"
				sx={{ cursor: 'pointer' }}
				onClick={() => changeView('list')}
			>
				<FontAwesomeIcon icon={faList} />
			</Box>
		</Grid>
	);
};
