import { Button, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { DiscountField } from '../..';
import {
	RootState,
	getCurrentOrder,
	getDiscount,
	getDiscountValue,
	getSelectedDelivery,
	getSubTotalOfCart,
	getTotalOfCart,
	getVatOfCart,
	// getVat,
	store,
	updateCartPopover,
} from '../../../../redux';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../../themes';
import { formatPriceToForint } from '../../../../helpers';

interface ICostValues {
	totalPrice: number;
	subTotalPrice: number;
	discountValue: number;
	deliveryPrice: any;
	vat: number;
	isCart?: boolean;
	vatPercentage?: number;
}
export const CostValues = ({
	vat,
	totalPrice,
	subTotalPrice,
	discountValue,
	vatPercentage,
	deliveryPrice,
	isCart,
}: ICostValues) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const gridItem = { justifyContent: 'space-between' };

	return (
		<>
			<Grid container sx={{ ...gridItem }}>
				<Typography
					variant={isCart ? 'h6' : 'body1'}
					component={isCart ? 'span' : 'h5'}
				>
					{prompts[currentLocale].shop.subtotal}
				</Typography>
				<Typography
					variant={isCart ? 'h6' : 'body1'}
					component={isCart ? 'p' : 'h5'}
				>
					{formatPriceToForint(subTotalPrice)}
				</Typography>
			</Grid>
			{discountValue > 0 && (
				<Grid container sx={{ ...gridItem }}>
					<Typography
						variant={isCart ? 'h6' : 'body1'}
						component={isCart ? 'span' : 'h5'}
					>
						{prompts[currentLocale].shop.discount}
					</Typography>
					<Typography
						variant={isCart ? 'h6' : 'body1'}
						component={isCart ? 'p' : 'h5'}
					>
						{formatPriceToForint(discountValue)}
					</Typography>
				</Grid>
			)}
			<Grid container sx={{ ...gridItem }}>
				<Typography
					variant={isCart ? 'h6' : 'body1'}
					component={isCart ? 'span' : 'h5'}
				>
					{/* {prompts[currentLocale].shop.vat_inclusive}{' '} */}
					{prompts[currentLocale].shop.vat}{' '}
					{vatPercentage ? `(${vatPercentage}%)` : null}
				</Typography>
				<Typography
					variant={isCart ? 'h6' : 'body1'}
					component={isCart ? 'p' : 'h5'}
				>
					{formatPriceToForint(vat)}
				</Typography>
			</Grid>
			{deliveryPrice?.fee !== undefined ? (
				<Grid container sx={{ ...gridItem }}>
					<Typography
						variant={isCart ? 'h6' : 'body1'}
						component={isCart ? 'span' : 'h5'}
					>
						{prompts[currentLocale].shop.delivery}
					</Typography>
					<Typography
						variant={isCart ? 'h6' : 'body1'}
						component={isCart ? 'p' : 'h5'}
					>
						{deliveryPrice.fee === 0
							? prompts[currentLocale].shop.free
							: formatPriceToForint(deliveryPrice.fee)}
					</Typography>
				</Grid>
			) : null}
			<Grid container sx={{ ...gridItem, marginTop: 2 }}>
				<Typography
					variant={isCart ? 'h6' : 'body1'}
					component={isCart ? 'span' : 'h5'}
					sx={{
						borderBottom: `2px solid ${colors['brp-yellow'].main}`,
					}}
				>
					{prompts[currentLocale].shop.total}
				</Typography>
				<Typography
					variant={isCart ? 'h6' : 'body1'}
					component={isCart ? 'p' : 'h5'}
					sx={{
						borderBottom: `2px solid ${colors['brp-yellow'].main}`,
					}}
				>
					{formatPriceToForint(totalPrice)}
				</Typography>
			</Grid>
		</>
	);
};

export const CostSummary = ({
	isCheckout = false,
	isPopover = false,
	isCart = false,
	isOverview = false,
	isOrderConfirm = false,
}: {
	isCheckout?: boolean;
	isPopover?: boolean;
	isCart?: boolean;
	isOverview?: boolean;
	isOrderConfirm?: boolean;
}) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const navigate = useNavigate();
	const navigateToCheckout = () => {
		store.dispatch(updateCartPopover(false));
		navigate('/checkout');
	};
	const navigateToCart = () => {
		store.dispatch(updateCartPopover(false));
		navigate('/cart');
	};
	const subTotalPrice = useSelector(getSubTotalOfCart);
	const totalPrice = useSelector(getTotalOfCart);
	const discountValue = useSelector(getDiscountValue);
	const deliveryPrice = useSelector(getSelectedDelivery);
	const vat = useSelector(getVatOfCart);
	const vatPercentage = useSelector(
		(state: RootState) =>
			state.generic.settings.details?.tax_rate,
	);
	const currentOrder = useSelector(getCurrentOrder);
	const isPaymentReady =
		currentOrder?.payment?.service_payment_id;

	return (
		<Grid
			container
			sx={{
				marginTop: 2,
				width: {
					xs: '100%',
					md: isPopover || isOverview ? '100%' : '80%',
				},
				maxWidth: {
					md:
						isCart || isPopover || isOrderConfirm
							? '300px'
							: 'none',
				},
				margin: {
					md: isCart
						? '16px 16px 0 0'
						: isOverview || isOrderConfirm
						  ? '0'
						  : '16px auto',
				},
			}}
		>
			{isCheckout && !isPaymentReady ? (
				<DiscountField />
			) : null}
			<CostValues
				discountValue={discountValue}
				vatPercentage={vatPercentage}
				isCart={isCart}
				totalPrice={totalPrice}
				vat={vat}
				deliveryPrice={deliveryPrice}
				subTotalPrice={subTotalPrice}
			/>
			<Grid
				container
				justifyContent="space-between"
				marginTop={2}
			>
				{isPopover ? (
					<Button
						variant="brp-white"
						size="small"
						onClick={navigateToCart}
					>
						{prompts[currentLocale].shop.cart}
					</Button>
				) : null}
				{isPopover ? (
					<Button
						variant="brp-yellow"
						onClick={navigateToCheckout}
					>
						{prompts[currentLocale].shop.checkout}
					</Button>
				) : null}
			</Grid>
		</Grid>
	);
};
