import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Grid, Typography } from '@mui/material';
import notFound from '../../assets/not-found.png';
import {
	dateFormatter,
	formatPriceToForint,
} from '../../helpers';
import {
	CostSummary,
	CostValues,
	RenderDetails,
} from '../../views';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../themes';
import { RootState } from '../../redux';
import { useSelector } from 'react-redux';
interface ItemDialogProps {
	open: boolean;
	onClose: () => void;
	rowDetails: any;
	translations: any;
}

const getAttributes = (
	attributes: any,
	currentLocale: string,
) => {
	return attributes.map((attr: any) => {
		return (
			<Grid
				container
				key={attr.attribute_id}
				style={{ width: 'max-content' }}
			>
				<Typography variant="body2" component="p">
					{attr.attribute.label[currentLocale]}:
				</Typography>
				<Typography
					variant="body2"
					component="p"
					style={{ marginLeft: '4px' }}
				>
					{typeof attr.value === 'string'
						? attr.value
						: attr.value[currentLocale]}
				</Typography>
			</Grid>
		);
	});
};

export const OrderHistoryItem = (
	props: ItemDialogProps,
) => {
	const { rowDetails, onClose, open, translations } = props;
	const items = rowDetails?.items ?? [];
	const {
		products_vat,
		payable_total,
		discount_applied,
		deliveryMethod,
	} = rowDetails;
	const handleClose = () => {
		onClose();
	};
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	return (
		<Grid container>
			<Grid container alignItems="center">
				<span
					onClick={handleClose}
					style={{
						width: 'max-content',
						display: 'flex',
						marginRight: '16px',
						alignItems: 'flex-start',
						cursor: 'pointer',
					}}
				>
					<FontAwesomeIcon
						icon={faAngleLeft}
						// size="1x"
						style={{ fontSize: '24px', marginTop: '-5px' }}
						color={colors['brp-black'].main}
					/>
				</span>
				<Grid
					container
					justifyContent="space-between"
					flex={1}
				>
					<Typography
						variant="h6"
						component="h3"
					>{`${translations.account.order_history_table.reference}: ${rowDetails.reference}`}</Typography>
					<Typography variant="h6" component="h4">
						{dateFormatter(rowDetails?.placed_at)}
					</Typography>
				</Grid>
			</Grid>
			<Grid container justifyContent="flex-end">
				<Typography variant="h6" component="h4">
					{rowDetails.delivery_method.name[currentLocale]}
				</Typography>
			</Grid>
			<Grid container sx={{ marginTop: 1 }}>
				<List
					sx={{
						pt: 0,
						width: '100%',
					}}
				>
					{items.map((item) => (
						<Grid
							key={item.product.name}
							container
							flexDirection="column"
							sx={{ px: 2 }}
						>
							<ListItem
								sx={{ py: 0, alignItems: 'center' }}
								disableGutters
							>
								<img
									src={
										item.product?.main_image?.src ??
										notFound
									}
									style={{ width: 100 }}
									alt={item.product.name}
								/>
								<Grid
									container
									flexDirection="row"
									justifyContent="space-between"
									alignItems="flex-start"
									style={{ marginLeft: '8px' }}
								>
									<Grid
										container
										flexDirection="column"
										style={{ width: 'max-content' }}
									>
										<Typography
											variant="h6"
											component="p"
											// style={{ width: 'max-content' }}
										>
											{item.product.name}
										</Typography>
										{getAttributes(
											item.product.attributes,
											currentLocale,
										)}
									</Grid>
									<Typography variant="body1" component="p">
										{translations.shop.quantity}:{' '}
										{item.quantity}
									</Typography>
								</Grid>
							</ListItem>
						</Grid>
					))}
				</List>

				<Grid
					container
					justifyContent="space-between"
					sx={{
						borderTop: `1px solid ${colors['brp-black'].main}`,
						borderBottom: `1px solid ${colors['brp-black'].main}`,
						paddingY: 1,
					}}
				>
					<Grid container sx={{ width: 'max-content' }}>
						<Typography variant="h6" component="h4">
							{translations.account.delivery.title}
						</Typography>
						<RenderDetails
							title={null}
							payload={{
								...rowDetails.shipping_address,
							}}
						/>
					</Grid>
					<Grid container sx={{ width: 'max-content' }}>
						<Typography variant="h6" component="h4">
							{translations.navigation.billing_details}
						</Typography>
						<RenderDetails
							title={null}
							payload={{
								...rowDetails.billing_address,
							}}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					marginLeft="auto"
					width="max-content"
					paddingTop={2}
				>
					<CostValues
						subTotalPrice={payable_total - products_vat}
						deliveryPrice={deliveryMethod?.fee}
						vat={products_vat}
						discountValue={discount_applied}
						isCart={false}
						totalPrice={payable_total}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
