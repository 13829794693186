import {
	AccordionProps,
	// Accordion,
	// AccordionDetails,
	// AccordionSummary,
	Grid,
	Typography,
	styled,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { AddNew, Delete } from '../../components';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useState } from 'react';
import { DeliveryDetails } from '../shop';
import {
	DeliveryDetails as DeliveryDetailsType,
	RootState,
	selectAccountDetails,
} from '../../redux';
import { useSelector } from 'react-redux';

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion
		disableGutters
		elevation={0}
		square
		{...props}
	/>
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled(
	(props: AccordionSummaryProps) => (
		<MuiAccordionSummary
			expandIcon={
				<ArrowForwardIosSharpIcon
					sx={{ fontSize: '0.9rem', pointerEvents: 'auto' }}
				/>
			}
			{...props}
		/>
	),
)(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		justifyContent: 'space-between',
		alignItems: 'center',
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(
	({ theme }) => ({
		padding: theme.spacing(2),
		borderTop: '1px solid rgba(0, 0, 0, .125)',
	}),
);

export const DeliveryAddresses = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	// todo: think about using this
	const accountDetails = useSelector(selectAccountDetails);
	const deliveryAddress = accountDetails.shipping_address;
	// we need to have an array of addresses, that we
	// can append to whenever the 'add new' button is clicked
	// then we map through that array and display all of
	// the accordions we have
	// to be populated with user's delivery details
	// const deliveryDetails: DeliveryDetailsType = {};
	const [expanded, setExpanded] = useState<string | false>(
		'panel1',
	);

	// const handleDdSubmit = (formData: object | any) => {
	// 	return null;
	// };

	// const ddFormik = usePersistentDeliveryDetails({
	// 	onSubmit: handleDdSubmit,
	// 	// valueOverride: deliveryAddress,
	// });
	const handleChange =
		(panel: string) =>
		(event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};
	// todo: re-add addnew and delete buttons
	return (
		<Grid container flexDirection="column">
			<Typography component="h2" variant="h5">
				{prompts[currentLocale].account.delivery.title}
			</Typography>
			<Typography component="p" variant="body2">
				{
					prompts[currentLocale].account.delivery
						.description
				}
			</Typography>
			{/*<AddNew*/}
			{/*	style={{*/}
			{/*		width: 'max-content',*/}
			{/*		marginTop: 16,*/}
			{/*		marginBottom: 16,*/}
			{/*	}}*/}
			{/*/>*/}
			<Accordion
				style={{ marginTop: '16px' }}
				expanded={expanded === 'panel1'}
				onChange={handleChange('panel1')}
			>
				<AccordionSummary
					sx={{ pointerEvents: 'none' }}
					aria-controls="panel1d-content"
					id="panel1d-header"
				>
					<Typography>
						{prompts[currentLocale].text.address}
					</Typography>
					{/*<Delete*/}
					{/*	style={{*/}
					{/*		padding: '0 4px',*/}
					{/*		pointerEvents: 'auto',*/}
					{/*	}}*/}
					{/*	deletionType="ADDRESS"*/}
					{/*/>*/}
				</AccordionSummary>
				<AccordionDetails>
					{
						deliveryAddress === null ? (
							<Typography>
								{
									prompts[currentLocale].account.delivery
										.no_address
								}
							</Typography>
						) : null
						// todo: resolve this below
						// <DeliveryDetails
						// 	// ddFormik={ddFormik}
						// 	isCheckout={false}
						// 	prompts={prompts}
						// 	currentLocale={currentLocale}
						// />
					}
				</AccordionDetails>
			</Accordion>
		</Grid>
	);
};
