import React, { useEffect } from 'react';
import {
	Grid,
	IconButton,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
	RootState,
	cancelPayment,
	fetchWebshopDetails,
	store,
} from '../../../../redux';
import { RenderDetails } from './RenderDetails';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../../../themes';
import { renderLockerDetails } from '../helpers';
import EditIcon from '@mui/icons-material/Edit';
import { doScrollToTop } from '../../../../helpers';

export const DeliverySummary = () => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const checkoutDetails = useSelector(
		(state: RootState) => state.checkout,
	);

	const { selectedDeliveryMethod, parcelShops } =
		checkoutDetails;
	const selectedParcelShop = parcelShops?.selected ?? null;
	const isPickup =
		selectedDeliveryMethod?.is_pickup ?? false;
	const isLocker =
		selectedDeliveryMethod?.is_locker ?? false;

	const deliveryDetails =
		checkoutDetails.orderUserResponse?.shipping_address;

	const { name } = checkoutDetails.orderUserResponse;

	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);

	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);

	const translations = prompts[currentLocale];
	useEffect(() => {
		if (
			isPickup &&
			!isLocker &&
			!checkoutDetails.webshop.details
		) {
			store.dispatch(fetchWebshopDetails());
		}
	}, []);

	const navigateBack = () => {
		store.dispatch(cancelPayment());
		doScrollToTop();
	};
	return (
		<Paper
			variant="outlined"
			square
			sx={
				isSmallScreen
					? { padding: 2, border: 'none' }
					: {
							marginBottom: 4,
							padding: 4,
							display: 'flex',
							flexDirection: 'column',
							flex: 1,
					  }
			}
		>
			<Grid
				alignItems="center"
				container
				sx={{ marginBottom: 2 }}
			>
				<FontAwesomeIcon
					icon={faTruck}
					style={{
						padding: 8,
						marginRight: 8,
						background: colors['brp-yellow'].main,
					}}
				/>
				<Typography
					variant="body1"
					component="span"
					sx={{
						textTransform: 'capitalize',
						color: colors['brp-black'].main,
					}}
					marginBottom="-5px"
				>
					{isPickup
						? translations.navigation.pickup_details
						: translations.navigation.delivery_details}
				</Typography>
				<IconButton
					onClick={navigateBack}
					sx={{ marginLeft: 'auto' }}
				>
					<EditIcon />
				</IconButton>
			</Grid>
			{isPickup && isLocker ? (
				renderLockerDetails({
					lockerDetail: selectedParcelShop,
					translations,
				})
			) : isPickup &&
			  !isLocker &&
			  checkoutDetails.webshop?.details?.shipping ? (
				<RenderDetails
					title={null}
					payload={{
						...checkoutDetails.webshop.details.shipping,
					}}
				/>
			) : (
				<RenderDetails
					title={null}
					payload={{
						name,
						...deliveryDetails,
						// @ts-ignore
						country: deliveryDetails?.country.name,
						// phone_number,
					}}
				/>
			)}
		</Paper>
	);
};
