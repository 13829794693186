import {
	Button,
	Container,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, store, updateUser } from '../../redux';

export const AccountDetails = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	// todo: resolve this any
	const accountDetails: any = useOutletContext();

	const initialValues = {
		first_name: accountDetails?.first_name ?? '',
		last_name: accountDetails?.last_name ?? '',
	};

	const handleSubmit = async (values) => {
		await store.dispatch(
			updateUser({
				name: `${values.last_name} ${values.first_name}`,
				id: accountDetails?.id,
			}),
		);
	};
	return (
		<Grid container>
			<Grid container flexDirection="column">
				<Typography component="h2" variant="h5">
					{prompts[currentLocale].account.information.title}
				</Typography>
				<Typography
					component="p"
					variant="body2"
					style={{
						marginBottom: 16,
					}}
				>
					{
						prompts[currentLocale].account.information
							.description
					}
				</Typography>
			</Grid>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting }) => (
					// consider increasing padding here but it causes issues with height
					<Form
						style={{
							width: '100%',
							padding: 0,
							boxSizing: 'border-box',
						}}
					>
						<Grid
							container
							columnGap={2}
							sx={{
								flexDirection: { xs: 'column', md: 'row' },
							}}
						>
							<Field
								name="first_name"
								as={TextField}
								fullWidth
								required
								sx={{ marginTop: 2, flex: 1 }}
								// className={styles.noBorderRadius}
								label={
									prompts[currentLocale].forms.first_name
								}
								placeholder={
									prompts[currentLocale].placeholder
										.first_name
								}
							/>
							<Field
								name="last_name"
								as={TextField}
								fullWidth
								required
								sx={{ marginTop: 2, flex: 1 }}
								label={
									prompts[currentLocale].forms.last_name
								}
								placeholder={
									prompts[currentLocale].placeholder
										.last_name
								}
							/>
						</Grid>
						<Grid container>
							<Field
								disabled
								sx={{ marginTop: 2, flex: 1 }}
								as={TextField}
								variant="outlined"
								fullWidth
								margin="normal"
								type="email"
								required
								value={accountDetails?.email ?? ''}
								label={prompts[currentLocale].forms.email}
								placeholder={
									prompts[currentLocale].placeholder.email
								}
							/>
						</Grid>
						<Container
							style={{
								padding: 0,
								justifyContent: 'space-between',
								display: 'flex',
							}}
						>
							<Button
								type="submit"
								variant="brp-yellow"
								color="primary"
								// fullWidth
								sx={{
									marginTop: '8px',
									textTransform: 'capitalize',
								}}
								disabled={isSubmitting}
							>
								{prompts[currentLocale].buttons.save}
							</Button>
						</Container>
					</Form>
				)}
			</Formik>
		</Grid>
	);
};
