import { Container, Grid, Skeleton } from '@mui/material';

export const ProductSkeleton = () => {
	return (
		<Container>
			<Skeleton
				variant="rectangular"
				width={240}
				height={30}
				sx={{ marginTop: { xs: 2, md: 0 } }}
			/>
			<Grid
				container
				marginTop={4}
				justifyContent="space-between"
			>
				<Skeleton
					variant="rectangular"
					width="45%"
					height={300}
				/>
				<Skeleton
					variant="rectangular"
					width="45%"
					height={300}
				/>
			</Grid>
			<Grid container marginTop={4}>
				<Skeleton
					variant="rectangular"
					width="10%"
					height={40}
					sx={{ marginRight: 2 }}
				/>
				<Skeleton
					variant="rectangular"
					width="10%"
					height={40}
				/>
			</Grid>
			{/* <Grid container marginTop="4px"> */}
			<Skeleton
				variant="rectangular"
				width="100%"
				height={2}
				sx={{ marginY: '4px' }}
			/>
			{/* </Grid> */}
			<Skeleton
				variant="rectangular"
				width="50%"
				height={100}
				sx={{ marginBottom: 4 }}
			/>
		</Container>
	);
};
