import {
	Category,
	fetchFilteredProducts,
	fetchProducts,
	getAllCategoryIdForParent,
	search,
	store,
	toggleCategory,
} from '../../redux';
import { useLocation } from 'react-router-dom';

export const addOrUpdateParam = (
	key: string,
	selectedItems: string,
	url: string = window.location.href,
): object => {
	const urlObject = new URL(url);

	// Check if the key and value already exist in the search params
	const paramExists = urlObject.searchParams
		.getAll(key)
		.includes(selectedItems);

	if (paramExists) {
		// Remove the key with the specific value
		const values = urlObject.searchParams.getAll(key);
		const updatedValues = values.filter(
			(value) => value !== selectedItems,
		);

		// Update the search params
		urlObject.searchParams.delete(key);
		updatedValues.forEach((value) =>
			urlObject.searchParams.append(key, value),
		);
	} else {
		// If the key doesn't exist, or the value is different, add/update the parameter
		if (urlObject.searchParams.has(key)) {
			urlObject.searchParams.append(key, selectedItems);
		} else {
			urlObject.searchParams.set(key, selectedItems);
		}
	}

	return {
		pathname: urlObject.pathname,
		search: urlObject.search,
	};
};

export const removeParam = (
	key: string,
	valueToRemove: string,
	url: string = window.location.href,
): string => {
	const urlObject = new URL(url);
	const existingValues = urlObject.searchParams.get(key);

	if (existingValues) {
		const updatedValues = existingValues
			.split(',')
			.filter((value) => value !== valueToRemove)
			.join(',');

		urlObject.searchParams.set(key, updatedValues);
	}

	return urlObject.toString();
};

export const sanitizeSlugForDisplay = (slug: string) => {
	return slug
		.split('-')
		.map(
			(word) =>
				word.charAt(0).toUpperCase() + word.slice(1),
		)
		.join(' ');
};

export const pickRandomItemsFromArray = (
	array: any[],
	numOfItemsToPick: number,
) => {
	const shuffled = array.slice();

	for (let i = shuffled.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));

		[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
	}

	return shuffled.slice(0, numOfItemsToPick);
};

export const handleCategoryToggle = async (
	category: Category,
) => {
	const categoryIds = getAllCategoryIdForParent([category]);

	store.dispatch(
		toggleCategory({
			categoryIds,
			onlyReceivedCategories: true,
		}),
	);

	const filters = store.getState().products.filtersApplied;
	const sort = store.getState().products.sortApplied;

	await store.dispatch(
		fetchFilteredProducts({
			filters,
			sort,
		}),
	);
};

export const isAttributeInUrl = (
	attributeValue: string,
) => {
	const location = useLocation();
	const urlValues = new URLSearchParams(
		location.search,
	).getAll('attribute');

	// Check if any URL parameter matches the provided attribute value
	return (
		urlValues &&
		urlValues.some(
			(urlValue) =>
				urlValue.toLowerCase() ===
				attributeValue.toLowerCase(),
		)
	);
};

export const parseFilterParametersFromUrl = async (
	previousPath: string,
) => {
	// issue here is that if we navigate from the Overview component
	// the API fetches webshop products twice, so trying to check
	// the previous path and if its from that component
	// we simply don't execute this function, ie return early

	if (previousPath && previousPath === '/shop/categories') {
		return;
	}

	const urlSearchParams = new URLSearchParams(
		window.location.search,
	);
	const filters: any = {};

	const searchParam = urlSearchParams.get('s');

	if (searchParam) {
		const decodedSearchValue =
			decodeURIComponent(searchParam);
		await store.dispatch(search(decodedSearchValue));
		// todo: returning here will ignore other params - should it?
		return;
	}
	const categoryParam = urlSearchParams.get('category');
	if (categoryParam) {
		filters.category = categoryParam.split(',');
	}

	const attributeParams =
		urlSearchParams.getAll('attribute');

	if (attributeParams.length > 0) {
		filters.attribute = attributeParams.reduce<string[]>(
			(acc, param) => {
				acc.push(...param.split(','));
				return acc;
			},
			[],
		);
	}

	if (Object.keys(filters).length > 0) {
		const sort = store.getState().products.sortApplied;

		await store.dispatch(
			fetchFilteredProducts({
				filters: {
					attribute: filters.attribute,
					categories: filters.category,
				},

				sort,
			}),
		);
	} else {
		store.dispatch(fetchProducts());
	}
	return filters;
};
