import { colors } from '../../../themes';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import {
	RootState,
	SortOrder,
	SortType,
	applySort,
	// selectedSort,
	// sortProducts,
	fetchFilteredProducts,
	selectedSort,
	store,
} from '../../../redux';
import { wrap } from 'module';

type SortOption = {
	type: SortType;
	order: SortOrder;
	label: string;
};

export const Sort = ({
	setIsLoading,
	isColumnDisplay = false,
}) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const sortSelected = useSelector(selectedSort);

	const performSort = async (
		order: SortOrder,
		type: SortType,
	) => {
		setIsLoading(true);
		const sort = {
			order,
			type,
		};

		store.dispatch(applySort(sort));

		await store.dispatch(
			fetchFilteredProducts({
				sort,
				filters: store.getState().products.filtersApplied,
			}),
		);

		setIsLoading(false);
	};

	const generateSortElements = () => {
		const sortOptions: SortOption[] = [
			{
				type: 'name',
				order: 'ASC',
				label: prompts[currentLocale].sort.a_to_z,
			},
			{
				type: 'name',
				order: 'DESC',
				label: prompts[currentLocale].sort.z_to_a,
			},
			{
				type: 'price',
				order: 'DESC',
				label:
					prompts[currentLocale].sort.price_high_to_low,
			},
			{
				type: 'price',
				order: 'ASC',
				label:
					prompts[currentLocale].sort.price_low_to_high,
			},
			{
				type: 'created_at',
				order: 'DESC',
				label: prompts[currentLocale].sort.newest,
			},
			{
				type: 'views',
				order: 'DESC',
				label: prompts[currentLocale].sort.most_popular,
			},
		];
		return sortOptions.map((option, index) => (
			<span
				key={index}
				style={{
					fontWeight:
						sortSelected.type === option.type &&
						sortSelected.order === option.order
							? 'bold'
							: 'normal',
					borderBottom:
						sortSelected.type === option.type &&
						sortSelected.order === option.order
							? `2px solid ${colors['brp-yellow'].main}`
							: 'none',
					cursor: 'pointer',
					marginRight: 16,
					marginBottom: isColumnDisplay ? 16 : 0,
					opacity:
						sortSelected.type === option.type &&
						sortSelected.order === option.order
							? 1
							: '0.6',
				}}
				onClick={() =>
					performSort(option.order, option.type)
				}
			>
				{option.label}
			</span>
		));
	};
	return (
		<Grid
			container
			sx={{
				width: 'max-content',
				maxWidth: '100vw',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				display: 'flex',
				textAlign: 'center',
				marginBottom: isColumnDisplay ? 0 : 1,
				paddingBottom: isColumnDisplay ? 0 : 2,
				flexWrap: 'wrap',
				flexDirection: isColumnDisplay ? 'column' : 'row',
			}}
		>
			{generateSortElements()}
		</Grid>
	);
};
