import { Button } from '@mui/material';
import { removeFromCart, store } from '../../redux';

export const Delete = ({
	productId,
	style = {},
	deletionType = 'PRODUCT',
	text,
}: {
	text: string;
	productId?: number;
	style?: object;
	deletionType?: 'PRODUCT' | 'ADDRESS';
}) => {
	const handleDelete = (e) => {
		e.preventDefault();
		switch (deletionType) {
			case 'PRODUCT':
				store.dispatch(
					removeFromCart({
						id: productId,
					}),
				);
				break;
			case 'ADDRESS':
				// console.log('delete this address');
				break;
			default:
				break;
		}
	};
	return (
		<Button
			variant="brp-white"
			size="small"
			onClick={handleDelete}
			style={style}
			sx={{
				minWidth: 'auto',
				border: 'none',
				background: 'transparent',
				padding: 0,
			}}
		>
			<p
				style={{
					textDecoration: 'underline',
				}}
			>
				{text}
			</p>
		</Button>
	);
};
