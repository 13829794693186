import {
	Box,
	Button,
	Container,
	Grid,
	List,
	ListItem,
	Tab,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useEffect, useState } from 'react';
import {
	RootState,
	store,
	selectCurrentVehicle,
	getImagesForProduct as getImagesForVehicle,
	VehicleDetails,
	fetchVehicle,
	updateVehicleReuqestFormVisibility,
	updateVehicleForQuote,
} from '../../../redux';
import { colors } from '../../../themes';
import {
	AddToCart,
	Gallery,
	Heading,
	ShopNow,
	QuantityButtons,
	ProductSkeleton,
	ProductCard,
	QuoteRequestDialog,
} from '../../../components';
import {
	Link,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import {
	faAngleLeft,
	faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { YouMayLike } from '../components';
import { parseHtml } from '../../helpers';

const ItemList = ({ vehicleData }) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const {
		name,
		length,
		width,
		height,
		weight,
		seats,
		fuel_tank_litres,
		cargo,
		engine_name,
		intake,
		cylinder,
		horse_power,
		fuel_type,
		body_type,
		colour,
		warranty,
		registration,
	} = vehicleData;

	const attributes = [
		{
			label: translations.vehicles.fields.name,
			value: name,
		},
		{
			label: translations.vehicles.fields.length,
			value: length,
		},
		{
			label: translations.vehicles.fields.width,
			value: width,
		},
		{
			label: translations.vehicles.fields.height,
			value: height,
		},
		{
			label: translations.vehicles.fields.weight,
			value: weight,
		},
		{
			label: translations.vehicles.fields.seats,
			value: seats,
		},
		{
			label: translations.vehicles.fields.fuel_tank_litres,
			value: fuel_tank_litres,
		},
		{
			label: translations.vehicles.fields.cargo,
			value: cargo,
		},
		{
			label: translations.vehicles.fields.engine_name,
			value: engine_name,
		},
		{
			label: translations.vehicles.fields.intake,
			value: intake,
		},
		{
			label: translations.vehicles.fields.cylinder,
			value: cylinder,
		},
		{
			label: translations.vehicles.fields.horse_power,
			value: horse_power,
		},
		{
			label: translations.vehicles.fields.fuel_type,
			value: fuel_type,
		},
		{
			label: translations.vehicles.fields.body_type,
			value: body_type,
		},
		{
			label: translations.vehicles.fields.colour,
			value: colour,
		},
		{
			label: translations.vehicles.fields.warranty,
			value: parseHtml(warranty),
		},
		{
			label: translations.vehicles.fields.registration,
			value: parseHtml(registration),
		},
	];

	return (
		<List>
			{attributes.map((currentAttribute, index) => (
				<ListItem
					key={index}
					sx={{
						mb: 1,
						justifyContent: 'flex-start',
						padding: 0,
					}}
				>
					<Grid
						item
						xs={4}
						md={2}
						sx={{ minWidth: '20vw' }}
					>
						<Typography
							maxWidth="sm"
							variant="body2"
							sx={{
								padding: 0,
								fontWeight: 'bold',
							}}
							color="brp-black"
						>
							{currentAttribute.label}
						</Typography>
					</Grid>
					<Grid item xs={6} md={8}>
						<Grid
							maxWidth="sm"
							sx={{
								padding: 0,
								textAlign: 'justify',
							}}
							color="brp-black"
						>
							{currentAttribute.value}
						</Grid>
					</Grid>
				</ListItem>
			))}
		</List>
	);
};

export const Vehicle = () => {
	const isFetching = useSelector(
		(state: RootState) =>
			state.generic.vehicles.selectedVehicle === null,
	);
	const navigate = useNavigate();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const { state } = useLocation();
	const currentVehicle: VehicleDetails | undefined | null =
		useSelector(selectCurrentVehicle).selectedVehicle;

	const url = window.location.href;
	const idParam = url.match(/\/(\d+)(?:\/|$)/);
	let id = state?.productId;
	if (!id && idParam && idParam.length > 0) {
		id = idParam[1];
	}

	useEffect(() => {
		store.dispatch(
			fetchVehicle(parseInt(id) ?? currentVehicle?.id),
		);
	}, [id]);

	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];

	const galleryImages = !!currentVehicle
		? getImagesForVehicle(currentVehicle)
		: [];

	const isQuoteRequestFormShown = useSelector(
		(state: RootState) =>
			state.generic.vehicles.isQuoteRequestFormShown,
	);

	const openQuoteDialog = () => {
		store.dispatch(
			updateVehicleReuqestFormVisibility(true),
		);
		store.dispatch(updateVehicleForQuote(currentVehicle));
		// set what vehicle to get a quote for
	};

	return isFetching ? (
		<ProductSkeleton />
	) : currentVehicle &&
	  Object.keys(currentVehicle)?.length > 0 ? (
		<Container
		//  style={{ width: '100%', padding: 0 }}
		>
			<QuoteRequestDialog open={isQuoteRequestFormShown} />
			<Grid
				container
				style={{
					width: '100%',
					//  padding: 0, margin: 0
				}}
				sx={{ marginTop: { xs: 2, md: 0 } }}
			>
				<Link
					to="/shop/vehicles"
					style={{
						width: 'max-content',
						paddingLeft: 6,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<FontAwesomeIcon
						icon={faAngleLeft}
						size="1x"
						color={colors['brp-black'].main}
						// style={{ fontSize: 16 }}
						// className={styles.icon}
					/>
					<Typography
						gutterBottom
						variant="h6"
						component="p"
						sx={{
							margin: 0,
							marginLeft: '4px',
							marginBottom: '-5px',
						}}
					>
						{prompts[currentLocale].buttons.back}
					</Typography>
				</Link>
				{isSmallScreen ? (
					currentVehicle?.spec?.url ? (
						<Link
							to={currentVehicle.spec.url}
							target="_blank"
							rel="noopener noreferrer"
							style={{
								display: 'flex',
								alignItems: 'center',
								marginLeft: 'auto',
							}}
						>
							<span>
								{prompts[currentLocale].text.specification}
							</span>
							<FontAwesomeIcon
								style={{ marginLeft: 4 }}
								icon={faExternalLinkAlt}
							/>
						</Link>
					) : null
				) : null}
			</Grid>
			<Grid
				container
				maxWidth="lg"
				spacing={1}
				sx={{
					display: 'flex',
					padding: { md: 6 },
					justifyContent: 'space-between',
				}}
			>
				<Grid
					item
					xs={12}
					md={7}
					sx={{
						minHeight: '30vh',
						height: 'max-content',
						justifyContent: 'center',
						alignItems: 'flex-start',
					}}
				>
					<Gallery
						items={galleryImages}
						showPlayButton={false}
						showFullscreenButton={false}
						thumbnailPosition={
							isSmallScreen ? 'bottom' : 'left'
						}
					/>
				</Grid>
				<Grid
					xs={12}
					md={5}
					item
					sx={{
						height: 'max-content',
						padding: 2,
						justifyContent: 'center',
						alignItems: 'flex-start',
					}}
				>
					<Grid container sx={{ flexDirection: 'column' }}>
						{isSmallScreen ? null : currentVehicle?.spec
								?.url ? (
							<Link
								to={currentVehicle.spec.url}
								target="_blank"
								rel="noopener noreferrer"
								style={{
									display: 'flex',
									alignItems: 'center',
									marginLeft: 'auto',
								}}
							>
								<span>
									{
										prompts[currentLocale].text
											.specification
									}
								</span>
								<FontAwesomeIcon
									style={{ marginLeft: 4 }}
									icon={faExternalLinkAlt}
								/>
							</Link>
						) : null}
						<Typography component="h2" variant="h4">
							{currentVehicle.name}
						</Typography>
						<Grid container>
							{parseHtml(currentVehicle.description)}
						</Grid>
						<Container
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								padding: 0,
								marginTop: 16,
								columnGap: '8px',
							}}
						>
							<Button
								onClick={openQuoteDialog}
								variant="brp-white"
								size="small"
								style={{
									width: isSmallScreen ? '100%' : '45%',
									marginLeft: 0,
									marginTop: 8,
									minWidth: 120,
								}}
							>
								{translations.text.get_a_quote}
							</Button>

							{currentVehicle.external_link ? (
								<Link
									to={currentVehicle.external_link}
									target="_blank"
									rel="noopener noreferrer"
									style={{
										width: isSmallScreen ? '100%' : '45%',
									}}
								>
									<Button
										variant="brp-yellow"
										style={{
											width: '100%',
											marginLeft: 0,
											marginTop: 8,
											minWidth: 120,
										}}
									>
										{translations.text.customise_vehicle}
									</Button>
								</Link>
							) : null}
						</Container>
					</Grid>
				</Grid>
			</Grid>
			<YouMayLike />
		</Container>
	) : (
		<Grid
			container
			flexDirection="column"
			alignItems="center"
			paddingTop={2}
			paddingBottom={4}
		>
			<Typography
				variant="h5"
				component="p"
				sx={{
					textTransform: 'uppercase',
					color: colors['brp-black'].main,
					marginBottom: '16px',
					position: 'relative',
					width: 'max-content',
				}}
			>
				{prompts[currentLocale].shop.product_not_found}
			</Typography>
			<Button
				variant="brp-yellow"
				onClick={() => navigate('/shop')}
			>
				{prompts[currentLocale].shop.back_to_shop}
			</Button>
		</Grid>
	);
};
