import React from 'react';
import {
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { colors } from '../../themes';
import { Explore } from '../buttons';

export const InfoHero: React.FC<any> = React.memo(
	({ sections, title, subTitle }) => {
		return (
			<section
				style={{
					background: colors['brp-black'].main,
					color: colors['brp-white'].main,
					margin: 0,
					padding: '32px 64px',
				}}
			>
				<Container
					sx={{
						textAlign: 'center',
					}}
				>
					<Container maxWidth="lg">
						<Typography
							variant="h4"
							// className={styles.subHeading}
							noWrap
							component="p"
						>
							{title}
						</Typography>
						<Typography
							variant="caption"
							// className={styles.subTitle}
							// noWrap
							sx={{ margin: 1 }}
							component="p"
						>
							{subTitle}
						</Typography>
					</Container>
					{sections}
				</Container>
			</section>
		);
	},
);

/*
const useStyles = makeStyles((theme) => ({
	heroContainer: {
		position: 'relative',
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8),
		backgroundColor: '#f0f0f0',
		overflow: 'hidden',
	},
	imageContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		width: '45%',
		height: '100%',
		clipPath: 'polygon(55% 0, 100% 0, 100% 100%, 0% 100%)',
		backgroundColor: '#fff',
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
}));
*/

// interface HeroProps extends React.Props
interface HeroProps {
	imgSrc: string | any;
	imagePosition: 'right' | 'left';
	text: string;
}

export const Hero: React.FC<HeroProps> = React.memo(
	({ imagePosition, imgSrc, text }) => {
		const theme = useTheme();
		const isSmallScreen = useMediaQuery(
			theme.breakpoints.down('md'),
		);
		const imageStyle: any = {
			position: isSmallScreen ? 'relative' : 'absolute',
			top: 0,
			width: isSmallScreen ? '100%' : '75%',
			height: '100%',
		};
		const rowDirection = { flexDirection: 'row' };
		if (imagePosition === 'left') {
			imageStyle.left = 0;
			imageStyle.clipPath = isSmallScreen
				? 'none'
				: 'polygon(0 0, 75% 0, 100% 100%, 0% 100%);';
			rowDirection.flexDirection = 'row-reverse';
		} else if (imagePosition === 'right') {
			imageStyle.right = 0;
			imageStyle.clipPath = isSmallScreen
				? 'none'
				: 'polygon(35% 0, 100% 0, 100% 100%, 0% 100%)';
		}
		return (
			<div
				style={{
					position: 'relative',
					// paddingTop: isSmallScreen ? 40 : 8,
					paddingTop: isSmallScreen ? 0 : 8,
					backgroundColor: colors['brp-black'].main,
					height: isSmallScreen
						? 'auto'
						: 'min(60vh,600px)',
					maxHeight: '720px',
				}}
			>
				<Container maxWidth="lg" sx={{ padding: 0 }}>
					<Grid
						container
						sx={{
							flexDirection: rowDirection.flexDirection,
						}}
					>
						<Grid
							item
							xs={12}
							sm={3}
							sx={{
								order: 2,
								marginTop: '10%',
								marginBottom: isSmallScreen ? '10%' : 0,
								marginLeft: '5%',
								color: colors['brp-white'].main,
							}}
						>
							<Typography
								variant="h4"
								component="h1"
								fontSize={isSmallScreen ? 48 : 56}
								sx={{ textTransform: 'uppercase' }}
							>
								{text}
							</Typography>
							{/* <Typography variant="h5">
								Subheading for Heading 1
							</Typography>
							<Typography variant="body1">
								Content related to Heading 1.
							</Typography> */}
							<Explore />
						</Grid>
						<Grid item xs={12} sm={7} sx={imageStyle}>
							<img
								src={imgSrc}
								alt="Hero"
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									objectPosition: 'right',
								}}
							/>
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	},
);
