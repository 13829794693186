import React from 'react';
import PropTypes from 'prop-types';
import {
	ThemeProvider as MuiThemeProvider,
	alpha,
	getContrastRatio,
} from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		'brp-grey': true;
		'brp-yellow': true;
		'brp-yellow-checkout': true;
		'brp-black': true;
		'brp-white': true;
		'seadoo-aqua': true;
		'seadoo-green': true;
		'canam-on-red': true;
		'canam-off-yellow': true;
		'skidoo-yellow': true;
		// 'skidoo-yellow': {
		// 	main: true;
		// 	first: true;
		// 	second: true;
		// 	third: true;
		// };
	}
	interface ButtonPropsVariantOverrides {
		'brp-grey': true;
		'brp-yellow': true;
		'brp-yellow-checkout': true;
		'brp-black': true;
		'brp-white': true;
		'seadoo-aqua': true;
		'seadoo-green': true;
		'canam-on-red': true;
		'canam-off-yellow': true;
		'skidoo-yellow': true;
		// 'skidoo-yellow': {
		// 	main: true;
		// 	first: true;
		// 	second: true;
		// 	third: true;
		// };
	}
}
declare module '@mui/material/styles/createPalette' {
	// interface Palette {
	// 	'brp-grey': true;
	// }
	interface PaletteOptions {
		'brp-grey': {
			main: string;
			light: string;
			dark: string;
			contrastText: string;
		};
		'brp-yellow': { main: string; checkout: string };
		'brp-yellow-checkout': {
			main: string;
			checkout: string;
		};
		'brp-black': { main: string; darker: string };
		'brp-white': { main: string };
		'seadoo-aqua': { main: string };
		'seadoo-green': { main: string };
		'canam-on-red': { main: string };
		'canam-off-yellow': { main: string };
		'skidoo-yellow': {
			main: string;
			first: string;
			second: string;
			third: string;
		};
		green: { main: string };
	}
}

export const colors = {
	'brp-grey': {
		main: '#8A9199',
		faded: 'rgba(138, 145, 153, 0.3)',
		seeThru: 'rgba(138, 145, 153, 0.8)',
		contrastText: '#000',
	},
	'brp-yellow': {
		main: '#FFD200',
		checkout: 'rgba(255, 210, 0, 0.1)',
	},
	'brp-yellow-checkout': {
		main: '#FFD200',
		checkout: 'rgba(255, 210, 0, 0.1)',
	},
	'brp-black': {
		main: '#333132',
		darker: '#2D2B2C',
		seeThru: 'rgba(51, 49, 50, 0.8)',
		light: 'rgba(51, 49, 50, 0.1)',
	},
	'brp-white': {
		main: '#ffffff',
	},
	'seadoo-aqua': {
		main: '#2cd5c4',
	},
	'seadoo-green': {
		main: '#c5e86c',
	},
	'canam-on-red': {
		main: '#e03c31',
	},
	'canam-off-yellow': {
		main: '#ffc72c',
	},
	'skidoo-yellow': {
		main: '#ffc72c',
		first: 'rgba(255, 199, 44, 0.75)',
		second: 'rgba(255, 199, 44, 0.50)',
		third: 'rgba(255, 199, 44, 0.25)',
	},
	green: {
		main: '#00B709',
	},
};
const theme = createTheme({
	palette: {
		primary: {
			main: '#FFD200',
		},
		secondary: {
			main: '#8A9199',
		},
		'brp-grey': {
			main: '#8A9199',
			light: alpha('#8A9199', 0.5),
			dark: alpha('#8A9199', 0.9),
			contrastText:
				getContrastRatio('#8A9199', '#fff') > 4.5
					? '#fff'
					: '#111', //'#fff',
		},
		'brp-yellow': {
			main: '#FFD200',
			checkout: 'rgba(255, 210, 0, 0.1)',
		},
		'brp-yellow-checkout': {
			main: '#FFD200',
			checkout: 'rgba(255, 210, 0, 0.1)',
		},
		'brp-black': {
			main: '#333132',
			darker: '#2D2B2C',
		},
		'brp-white': {
			main: '#ffffff',
		},
		'seadoo-aqua': {
			main: '#2cd5c4',
		},
		'seadoo-green': {
			main: '#c5e86c',
		},
		'canam-on-red': {
			main: '#e03c31',
		},
		'canam-off-yellow': {
			main: '#ffc72c',
		},
		'skidoo-yellow': {
			main: '#ffc72c',
			first: 'rgba(255, 199, 44,0.75)',
			second: 'rgba(255, 199, 44,0.50)',
			third: 'rgba(255, 199, 44,0.25)',
		},
		green: {
			main: '#00B709',
		},
	},
	components: {
		MuiTextField: {
			variants: [
				{
					props: { variant: 'filled' },
					style: {
						// background: colors['brp-white'].main,
						color: colors['brp-black'].main,
						// border: `1px solid ${colors['brp-black'].main}`,
						borderRadius: '0',
					},
				},
			],
			styleOverrides: {
				root: {
					// background: 'rgba(123,32,32)',
					// todo: disabled below two underlines - not sure what they were doing lmao
					// '& .MuiInput-underline:before': {
					// 	borderBottomColor: 'red', //'#2cd5c4',
					// },
					// '& .MuiInput-underline:after': {
					// 	borderBottomColor: 'red', //'#2cd5c4',
					// },
					// '& .MuiFilledInput-root': {
					// 	'& .MuiFilledInput-underline': {
					// 		'&:before': {
					// 			borderBottomColor: '#2cd5c4',
					// 		},
					// 		'&:after': {
					// 			borderBottomColor: '#2cd5c4',
					// 		},
					// 	},
					// },
					// '& .MuiFilledInput-root': {
					// 	//
					// 	'& MuiFilledInput-underline': {
					// 		color: '#2cd5c4',
					// 	},
					// },
				},
				// focused: {
				//     '& .MuiInputBase-root': {
				//         borderBottom: '2px solid #2cd5c4', // Your custom focused borderBottom style here
				//       },
				// },
			},
		},
		MuiButton: {
			// variants: [
			// 	{
			// 		props: { variant: 'brp-grey' },
			// 		style: {
			// 			color: '#8A9199',
			// 		},
			// 	},
			// 	{
			// 		props: {
			// 			variant: 'brp-yellow',
			// 		},
			// 		style: {
			// 			color: '#FFD200',
			// 		},
			// 	},
			// ],
			variants: [
				// {
				//     props: { variant: 'green'},
				//     style: {
				//         color: colors['green'].main
				//     }
				// },
				{
					props: { variant: 'brp-white' },
					style: {
						background: colors['brp-white'].main,
						color: colors['brp-black'].main,
						border: `1px solid ${colors['brp-grey'].faded}`,
						borderRadius: '0',
						textTransform: 'capitalize',
					},
				},
				{
					props: { variant: 'brp-white', size: 'small' },
					style: {
						background: colors['brp-white'].main,
						color: colors['brp-black'].main,
						border: `1px solid ${colors['brp-black'].main}`,
						borderRadius: '0',
					},
				},
				{
					props: { variant: 'brp-grey' },
					style: {
						color: colors['brp-black'].main,
						background: colors['brp-grey'].faded,
						borderRadius: '0',
						boxShadow: 'none',
						fontSize: '24px',
						lineHeight: '12px',
						padding: 0,
						// fontSize: '1.25rem',
						// padding: 4,
					},
				},
				{
					props: { variant: 'brp-yellow' },
					style: {
						color: colors['brp-black'].main,
						background: colors['brp-yellow'].main,

						// background: alpha(
						// 	colors['brp-yellow'].main,
						// 	700
						// ),
						borderRadius: 0,
						textTransform: 'capitalize',
						// boxShadow: 'none',
						border: `1px solid ${colors['brp-black'].main}`,
						// Add any other desired styles for this variant
					},
				},
				{
					props: { variant: 'brp-yellow-checkout' },
					style: {
						color: colors['brp-black'].main,
						background: colors['brp-yellow'].checkout,

						// background: alpha(
						// 	colors['brp-yellow'].main,
						// 	700
						// ),
						borderRadius: 0,
						textTransform: 'capitalize',
						// boxShadow: 'none',
						border: `1px solid ${colors['brp-black'].main}`,
						// Add any other desired styles for this variant
					},
				},
				// {
				// 	props: { color: 'brp-yellow' },
				// 	style: {
				// 		color: colors['brp-yellow'].main,
				// 		// Add any other desired styles for this variant
				// 	},
				// },
				{
					props: { color: 'brp-black' },
					style: {
						color: colors['brp-black'].main,
						// Add any other desired styles for this variant
					},
				},
				// {
				// 	props: { color: 'brp-white' },
				// 	style: {
				// 		color: colors['brp-white'].main,
				// 		// Add any other desired styles for this variant
				// 	},
				// },
				{
					props: { color: 'seadoo-aqua' },
					style: {
						backgroundColor: colors['seadoo-aqua'].main,
						color: colors['brp-black'].main,
						boxShadow: 'none',
						borderRadius: 0,
						border: `1px solid ${colors['brp-black'].main}`,

						// Add any other desired styles for this variant
					},
				},
				{
					props: { color: 'seadoo-green' },
					style: {
						color: colors['seadoo-green'].main,
						// Add any other desired styles for this variant
					},
				},
				{
					props: { color: 'canam-on-red' },
					style: {
						color: colors['canam-on-red'].main,
						// Add any other desired styles for this variant
					},
				},
				{
					props: { color: 'canam-off-yellow' },
					style: {
						color: colors['canam-off-yellow'].main,
						// Add any other desired styles for this variant
					},
				},
				{
					props: { color: 'skidoo-yellow' },
					style: {
						color: colors['skidoo-yellow'].main,
						// Add any other desired styles for this variant
					},
				},
			],
		},
	},
});

export const ThemeProvider = ({ children }) => (
	<MuiThemeProvider theme={theme}>
		{children}
	</MuiThemeProvider>
);
ThemeProvider.propTypes = {
	children: PropTypes.any,
};
