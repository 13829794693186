import {
	Button,
	Container,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { colors } from '../../themes';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { EmailOutlined } from '@mui/icons-material';
import {
	Link,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { ToggleablePasswordField } from '../../components';
import {
	RootState,
	loginUser,
	selectAccountDetails,
	store,
} from '../../redux';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import React from 'react';

export const LoginForm = ({
	translations,
	isSmallScreen,
}) => {
	const location = useLocation();
	const from = location.state?.from || '/';
	const accountDetails = useSelector(selectAccountDetails);
	const navigate = useNavigate();

	const initialValues = {
		email: '',
		password: '',
	};

	const validationSchema = Yup.object({
		email: Yup.string().required(
			translations.validation.generic.email,
		),
		password: Yup.string().required(
			translations.validation.password.required,
		),
	});

	const handleSubmit = async (
		values: typeof initialValues,
		{ resetForm },
	) => {
		await store.dispatch(loginUser(values));
		if (store.getState()?.auth?.details?.id !== undefined) {
			if (from === '/checkout') {
				navigate(from, {
					replace: true,
				});
			} else {
				navigate('/account');
			}
		}
		resetForm();
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({ isSubmitting }) => (
				// todo: consider increasing padding here but it causes issues with height
				<Form
					style={{
						padding: isSmallScreen ? '64px 16px' : 16,
						boxSizing: 'border-box',
					}}
				>
					<Typography
						variant="h5"
						component="h5"
						sx={{
							textTransform: 'uppercase',
							color: colors['brp-black'].main,
							marginBottom: '16px',
						}}
					>
						{translations.navigation.login}
					</Typography>
					<Field
						name="email"
						as={TextField}
						variant="outlined"
						fullWidth
						margin="normal"
						type="email"
						inputProps={{ tabIndex: 1 }}
						required
						label={translations.forms.email}
						placeholder={translations.placeholder.email}
						InputProps={{
							startAdornment: (
								<InputAdornment
									sx={{ pointerEvents: 'none' }}
									position="start"
								>
									<IconButton aria-label="Email field">
										<EmailOutlined />
									</IconButton>
								</InputAdornment>
							),
						}}
						sx={{ mb: 4, marginTop: 2 }}
					/>
					<Field
						inputProps={{ tabIndex: 2 }}
						as={TextField}
						component={ToggleablePasswordField}
						type="password"
						name="password"
						fullWidth
						variant="outlined"
					/>
					<Link
						tabIndex={4}
						to="/forgotten-password"
						style={{
							fontWeight: 'bold',
							marginTop: 8,
							display: 'block',
						}}
					>
						{translations.forms.forgotten_password}
					</Link>
					<Container
						style={{
							padding: 0,
							justifyContent: 'space-between',
							display: 'flex',
							marginTop: 16,
						}}
					>
						<LoadingButton
							tabIndex={3}
							type="submit"
							loading={isSubmitting}
							variant="brp-yellow"
						>
							<span>{translations.navigation.login}</span>
						</LoadingButton>
						<Grid
							container
							sx={{
								alignItems: 'center',
								// justifyContent: 'space-between',
								width: 'max-content',
							}}
						>
							<Typography
								variant="subtitle1"
								component="h6"
								sx={{
									// textTransform: 'uppercase',
									color: colors['brp-grey'].main,
								}}
							>
								{translations.forms.no_account}
							</Typography>
							<Link
								tabIndex={4}
								to="/register"
								style={{
									fontWeight: 'bold',
									marginLeft: 8,
								}}
							>
								{translations.forms.create_one_1}
								<span
									style={
										{
											// color: colors['brp-yellow'].main,
										}
									}
								>
									{translations.forms.create_one_2}.
								</span>
							</Link>
						</Grid>
					</Container>
				</Form>
			)}
		</Formik>
	);
};

export const Login = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);

	return (
		<div
			style={{
				position: 'relative',
				backgroundColor: colors['brp-white'].main,
				height: '100%',
			}}
		>
			<Container
				style={{
					paddingLeft: 0,
					paddingRight: 0,
					marginLeft: 0,
					marginRight: 0,
					height: '100%',
					maxWidth: 'none',
				}}
				// todo: look into how to achieve this with
				// clip path background for left element
				// maxWidth="lg"
			>
				<Grid container style={{ height: '100%' }}>
					<Grid
						item
						xs={12}
						sm={7}
						sx={{
							// ...imageStyle,
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
							paddingRight: '20%',
							minHeight: isSmallScreen ? '30vh' : '50vh',
							background: colors['brp-black'].main,
							clipPath: isSmallScreen
								? 'none'
								: 'polygon(0 0, 75% 0, 100% 100%, 0% 100%);',
						}}
					>
						<Container
							style={{
								width: 'max-content',
								// alignItems: 'center',
							}}
						>
							<Typography
								variant="h3"
								component="h1"
								sx={{
									textTransform: 'uppercase',
									color: colors['brp-white'].main,
									marginBottom: '16px',
								}}
							>
								{
									prompts[currentLocale].text
										.login_welcome_1
								}
							</Typography>
							<Typography
								variant="h3"
								component="h1"
								sx={{
									textTransform: 'uppercase',
									color: colors['brp-white'].main,
									marginBottom: '16px',
								}}
							>
								{
									prompts[currentLocale].text
										.login_welcome_2
								}{' '}
								<span
									style={{
										color: colors['brp-yellow'].main,
									}}
								>
									{
										prompts[currentLocale].text
											.login_welcome_3
									}
								</span>
							</Typography>
						</Container>
					</Grid>
					<Grid item sm={5} xs={12}>
						<LoginForm
							translations={prompts[currentLocale]}
							isSmallScreen={isSmallScreen}
						/>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};
