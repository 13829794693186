import {
	RootState,
	personalDetailsForOrderUser,
	selectAccountDetails,
	store,
	updateIsBusiness,
} from '../../../redux';
import {
	FormControlLabel,
	Grid,
	Paper,
	Switch,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { colors } from '../../../themes';
import React, {
	forwardRef,
	useImperativeHandle,
	useRef,
} from 'react';
import { RenderErrors } from './components';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';

export const PersonalDetails = forwardRef(
	(
		{
			pdFormik,
			prompts,
			currentLocale,
		}: {
			pdFormik: any;
			prompts: object;
			currentLocale: string;
		},
		parentRef,
	) => {
		const theme = useTheme();
		const isSmallScreen = useMediaQuery(
			theme.breakpoints.down('md'),
		);
		const businessDetails = useSelector(
			(state: RootState) => state.checkout.business,
		);
		const childFormikRef = useRef<any>(null);

		const handleBusinessChange = ({ target }) => {
			store.dispatch(updateIsBusiness(target.checked));
		};
		const handleSubmit = () => {
			store.dispatch(
				personalDetailsForOrderUser(pdFormik.values),
			);
			pdFormik.resetForm();
		};

		useImperativeHandle(parentRef, () => ({
			submitForm: () => {
				if (childFormikRef.current) {
					childFormikRef.current.submitForm();
				}
			},
			validateForm: async () => {
				if (childFormikRef.current) {
					return await childFormikRef.current.validateForm();
				}
			},
		}));
		return (
			<Paper
				variant="outlined"
				id="personal-details"
				square
				sx={
					isSmallScreen
						? { padding: 2, border: 'none' }
						: {
								padding: 4,
								marginBottom: 4,
						  }
				}
			>
				<Formik
					innerRef={childFormikRef}
					initialValues={pdFormik.initialValues}
					onSubmit={handleSubmit}
					// todo: resolve this ts issue below
				>
					{() => {
						return (
							<Form>
								<Grid
									container
									sx={{
										alignItems: 'flex-start',
										justifyContent: 'space-between',
									}}
								>
									<Grid
										alignItems="center"
										minWidth="200px"
										container
										sx={{
											width: 'max-content',
											marginBottom: 2,
										}}
									>
										<FontAwesomeIcon
											icon={faUser}
											style={{
												padding: 8,
												marginRight: 8,
												background:
													colors['brp-yellow'].main,
											}}
										/>
										<Typography
											variant="body1"
											component="span"
											sx={{
												textTransform: 'capitalize',
												color: colors['brp-black'].main,
											}}
										>
											{businessDetails.isBusiness
												? prompts[currentLocale].text
														.company_details
												: prompts[currentLocale].account
														.information.title}
										</Typography>
									</Grid>
									<FormControlLabel
										control={
											<Switch
												checked={businessDetails.isBusiness}
												onChange={handleBusinessChange}
											/>
										}
										label={
											<span style={{ marginTop: '5px' }}>
												{
													prompts[currentLocale].text
														.business_purchase
												}
											</span>
										}
										sx={{ marginBottom: 1 }}
									/>
								</Grid>
								<Grid container>
									<RenderErrors errors={pdFormik.errors} />
									<Grid
										container
										columnGap={2}
										flexDirection={
											isSmallScreen ? 'column' : 'row'
										}
									>
										<Grid
											container
											flexDirection="column"
											style={{ marginBottom: 16, flex: 1 }}
										>
											{businessDetails.isBusiness ? (
												<>
													<Field
														name="company_name"
														as={TextField}
														required
														fullWidth
														value={
															pdFormik.values.company_name
														}
														onChange={pdFormik.handleChange}
														onBlur={pdFormik.handleBlur}
														label={
															prompts[currentLocale].forms
																.company_name
														}
														placeholder={
															prompts[currentLocale]
																.placeholder.company_name
														}
													/>
													<ErrorMessage name="company_name" />
												</>
											) : (
												<>
													<Field
														name="last_name"
														as={TextField}
														fullWidth
														value={
															pdFormik.values.last_name
														}
														onChange={pdFormik.handleChange}
														onBlur={pdFormik.handleBlur}
														required
														label={
															prompts[currentLocale].forms
																.last_name
														}
														placeholder={
															prompts[currentLocale]
																.placeholder.last_name
														}
													/>
													<ErrorMessage name="last_name" />
												</>
											)}
										</Grid>
										<Grid
											container
											flexDirection="column"
											style={{ marginBottom: 16, flex: 1 }}
										>
											{businessDetails.isBusiness ? (
												<>
													<InputMask
														mask="99999999-9-99"
														value={
															pdFormik.values.tax_number
														}
														onChange={pdFormik.handleChange}
														onBlur={pdFormik.handleBlur}
													>
														{() => (
															<Field
																required
																name="tax_number"
																as={TextField}
																fullWidth
																value={
																	pdFormik.values.tax_number
																}
																onChange={
																	pdFormik.handleChange
																}
																label={
																	prompts[currentLocale]
																		.forms.tax_number
																}
																placeholder={
																	prompts[currentLocale]
																		.placeholder.tax_number
																}
															/>
														)}
													</InputMask>

													<ErrorMessage name="tax_number" />
												</>
											) : (
												<>
													<Field
														name="first_name"
														as={TextField}
														fullWidth
														value={
															pdFormik.values.first_name
														}
														onChange={pdFormik.handleChange}
														onBlur={pdFormik.handleBlur}
														required
														label={
															prompts[currentLocale].forms
																.first_name
														}
														placeholder={
															prompts[currentLocale]
																.placeholder.first_name
														}
													/>
													<ErrorMessage name="first_name" />
												</>
											)}
										</Grid>
									</Grid>
									<Grid
										container
										flexDirection="column"
										style={{ marginBottom: 16, flex: 1 }}
									>
										<Field
											name="email"
											as={TextField}
											fullWidth
											value={pdFormik.values.email}
											onChange={pdFormik.handleChange}
											onBlur={pdFormik.handleBlur}
											required
											label={
												prompts[currentLocale].forms.email
											}
											placeholder={
												prompts[currentLocale].placeholder
													.email
											}
										/>{' '}
										<ErrorMessage name="email" />
									</Grid>
								</Grid>
							</Form>
						);
					}}
				</Formik>
			</Paper>
		);
	},
);
