import {
	Grid,
	Skeleton,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { BillingSummary, DeliverySummary } from '.';
import { RootState } from '../../../../redux';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';

const DetailsSummarySkeleton = () => (
	<Grid container columnGap={4}>
		<Skeleton
			variant="rectangular"
			height={250}
			sx={{ flex: 1, marginBottom: 4 }}
		/>
		<Skeleton
			variant="rectangular"
			height={250}
			sx={{ flex: 1, marginBottom: 4 }}
		/>
	</Grid>
);

export const DetailsSummary = () => {
	const isLoading = useSelector(
		(state: RootState) =>
			state.checkout.webshop.isBeingFetched,
	);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	return isLoading ? (
		<DetailsSummarySkeleton />
	) : (
		<Grid
			container
			flexDirection={isSmallScreen ? 'column' : 'row'}
			columnGap={isSmallScreen ? 0 : 4}
		>
			<BillingSummary />
			<DeliverySummary />
		</Grid>
	);
};
