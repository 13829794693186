export const langPrompts = {
	en: {
		hungary: 'Hungary',
		buttons: {
			review_order: 'Review order',
			select: 'Select',
			cancel: 'Cancel',
			send: 'Send',
			submit: 'Submit',
			navigate_to_shop: 'Go to webshop',
			view_products: 'View products',
			shop_now: 'Shop now',
			subscribe: 'Subscribe',
			subscribe_now: 'Subscribe now!',
			activate: 'Activate my account',
			my_account: 'My account',
			back: 'Back',
			save: 'Save',
			create_account: 'Create account',
			explore_more: 'Explore more',
			confirm_and_pay: 'Confirm and pay',
			continue_as_guest: 'Continue as guest',
		},
		errors: {
			generic: 'Something went wrong!',
			nothing_to_show:
				"It looks like we don't have anything to show here.",
		},
		dialog: {
			success: 'Great news',
		},
		forms: {
			company_name: 'Company name',
			tax_number: 'Tax number',
			first_name: 'First name',
			last_name: 'Last name',
			email: 'Email address',
			message: 'Message',
			password: 'Password',
			password_reset: 'Reset password',
			password_new: 'New password',
			password_reset_successful:
				'The password reset was successful',
			password_reset_title: 'Password reset',
			password_reset_again: 'Click here to reset again',
			password_reset_invalid_link:
				'Invalid password reset link',
			password_confirmation: 'Password confirmation',
			unsubscribe_invalid_link:
				'Invalid unsubscription link',
			forgotten_password: 'Forgotten your password?',
			forgot_password: 'Forgotten password',
			no_account: "Don't have an account?",
			create_one_1: 'Create ',
			create_one_2: 'one',
			phone_number: 'Phone number',
			street: 'Street',
			street_extra: 'Street extra',
			city: 'City',
			post_code: 'Postcode',
			state: 'County',
			country: 'Country',
		},
		validation: {
			password: {
				required: 'Password is required.',
				min_length:
					'Password must be at least 8 characters',
				confirmation_required:
					'Password confirmation is required',
				confirmation_match: 'Passwords must match',
			},
			generic: {
				first_name: 'First name is required',
				last_name: 'Last name is required',
				email: 'Email is required',
				email_invalid: 'Email is invalid',
				message: 'Message is required',
				phone: 'Phone number is required',
				phone_invalid:
					'The phone number must start with +36 or 06',
				company_name: 'Company name is required',
				tax_number: 'Tax number is required',
			},
			shipping_billing: {
				street: 'Street is required',
				city: 'City is required',
				post_code: 'Postcode is required',
				post_code_min:
					'Postcode must be at least 4 characters',
				state: 'County is required',
				delivery_method_id:
					'A delivery method must be selected',
			},
		},
		placeholder: {
			first_name: 'Enter your first name',
			last_name: 'Enter your last name',
			email: 'Enter your email address',
			message: 'Enter your message',
			password: 'Enter your password',
			password_confirmation: 'Enter your password again',
			phone_number: 'Enter your phone number',
			map_search: 'Search by address',
		},
		sort: {
			label: 'Sort by',
			a_to_z: 'Alphabetic: A-Z',
			z_to_a: 'Alphabetic: Z-A',
			price_low_to_high: 'Price: Low-to-High',
			price_high_to_low: 'Price: High-to-Low',
			newest: 'Newest',
			most_popular: 'Most popular',
		},
		navigation: {
			pages: 'Pages',
			parts_finder: 'Parts finder',
			account: 'Account',
			login: 'Login',
			logout: 'Logout',
			register: 'Register',
			home: 'Home',
			shop: 'Webshop',
			blog: 'Blog',
			order_history: 'Order history',
			pickup_details: 'Pickup details',
			delivery_details: 'Delivery details',
			billing_details: 'Billing details',
			virtual_tour: 'Virtual Tour',
			contact_us: 'Contact Us',
			terms_and_conditions: 'Terms and conditions',
			terms_and_conditions_short: "T&C's",
			privacy_policy: 'Privacy policy',
			impressum: 'Impressum',
			other: 'Other',
		},
		heading: {},
		shop: {
			price_summary: 'Price summary',
			cart_summary: 'Cart Summary',
			detail_summary: 'Detail Summary',
			no_item: 'No items found',
			on_sale: 'Sale',
			vehicles: 'Vehicles',
			cart: 'Cart',
			shopping_cart: 'Shopping Cart',
			nothing_in_cart: 'You have nothing in your cart',
			add_to_cart: 'Add to cart',
			added_to_cart: 'Added to cart',
			checkout: 'Checkout',
			checkout_as_guest: 'Checkout as guest',
			vat: 'VAT',
			vat_inclusive: 'VAT (inclusive)',
			discount: 'Discount',
			delete: 'Delete',
			product_not_found:
				'We could not find what you were looking for.',
			back_to_shop: 'Back to webshop',
			back_to_home: 'Back to home',
			quantity: 'Quantity',
			name: 'Name',
			all_products: 'All products',
			products: 'Products',
			product: 'Product',
			services: 'Services',
			price: 'Price',
			in_stock: 'In stock',
			out_of_stock: 'Out of stock',
			total: 'Total',
			subtotal: 'Subtotal',
			delivery: 'Delivery',
			billing: 'Billing',
			continue_shopping: 'Continue shopping',
			continue_to_payment: 'Continue to payment',
			payment: 'Payment',
			to_checkout: 'Go to checkout',
			to_shop: 'Go to webshop',
			more: 'View more',
			less: 'View less',
			free: 'Free',
			items_in_cart: 'Items in the cart',
		},
		contact: {
			details: 'Contact us',
			location_question: 'Where are we located?',
			have_a_question: 'Have a question?',
			hear_from: 'We would love to hear from you!',
			success:
				'Thank you for your message, we will be in touch shortly.',
		},
		account: {
			title: 'My account',
			activation: {
				title: 'Account activation',
				error:
					"It looks like there's something wrong with your activation link. Please get in touch with us.",
			},
			information: {
				title: 'Personal information',
				description:
					'Manage your personal information, including phone numbers and email addresses where you can be contacted',
			},
			delivery: {
				title: 'Delivery address',
				no_address: 'We have no address on record',
				description:
					'Manage your delivery addresses, where the purchased items can be delivered',
			},
			order_history_table: {
				reference: 'Reference',
				purchase_date: 'Purchase date',
				status: 'Status',
				dialog_title: 'Purchased items',
				name: 'Name',
				quantity: 'Quantity',
				disclaimer:
					'Kindly note that orders may take a couple of minutes to reflect in the table after being placed.',
				no_orders_yet: 'You have not placed an order yet',
			},
		},
		opening_days: {
			MON: 'Monday',
			TUE: 'Tuesday',
			WED: 'Wednesday',
			THU: 'Thursday',
			FRI: 'Friday',
			SAT: 'Saturday',
			SUN: 'Sunday',
		},
		text: {
			reset_password_1:
				'An email has been sent to your email address with instructions for resetting your password.',
			reset_password_2:
				'This email may take a few minutes to arrive in your inbox.',
			size_guide: 'Size guide',
			search: 'Search',
			company_details: 'Company details',
			order_confirmation: 'Order confirmation',
			phone_number_delivery:
				'All delivery methods require a phone number to be provided.',
			guest_checkout: 'Guest checkout',
			guest_checkout_description:
				'For swift, hassle-free checkout, choose guest mode. No account needed. Enjoy simplicity and convenience in moments.',
			login_description:
				'For enhanced security and personalized shopping, please log in to your account or create one now. It only takes a few moments.',
			more_lockers: 'Find more lockers',
			closed: 'Closed',
			choose_another_locker: 'Choose another locker',
			locker_selected: 'Selected locker',
			opening_hours: 'Opening times',
			subscription_title_1: 'Subscribe to ',
			subscription_title_2: 'our newsletter',
			subscription_detail:
				'Join for exclusive offers, updates, and news. Stay ahead with our latest releases.',
			newsletter_failure:
				'We were not able to subscribe you to our newsletter',
			newsletter_success:
				'Successfully subscribed to our newsletter',
			newsletter: 'Newsletter',
			subscribe_to_newsletter:
				'Subscribe to our newsletter.',
			business_purchase: 'Are you a business?',
			additional: 'Additional',
			request_new_pass: 'Request new password',
			page_size: 'Shown on a page:',
			reset: 'Reset filters',
			get_a_quote: 'Get a quote',
			customise_vehicle: 'Customise',
			privacy_acknowledged_1:
				'I confirm that I have read and agree to the ',
			privacy_acknowledged_2: ' privacy policy',
			privacy_acknowledged_3: '',
			already_account: 'Already have an account?',
			see_all_products: 'All of our products',
			login_welcome_1: 'Welcome.',
			login_welcome_2: 'Back.',
			login_welcome_3: 'User.',
			register_welcome_1: 'Welcome.',
			register_welcome_2: 'Dear ',
			register_welcome_3: 'User.',
			home_hero: 'Drive. Grow. Innovate.',
			our_categories: 'Our categories',
			categories: 'Categories',
			address: 'Address',
			most_popular_product: 'Our most popular product',
			completion:
				'We have successfully received your order. You should get an email confirmation of your order shortly, and we’ll soon be in contact again once your order ships from our warehouse.',
			completion_success: 'Thank you for your purchase',
			completion_error:
				'Something went wrong whilst receiving your payment',
			completion_reference: 'Transaction reference',
			description: 'Description',
			specification: 'Specification',
			no_specification: 'No specifications found',
			coming_soon: 'Coming soon',
			may_like: 'You may also like',
			stay_informed: 'Stay informed',
			delivery_is_same:
				'Shipping is the same as the billing address',
			footer_notice:
				'The prices found on our website are for informational purposes only and do not constitute an offer. We reserve the right to change prices.',
		},
		checkbox: {
			checkout_t_and_c_part1:
				'By placing this order, you are confirming that you agree to our ',
			checkout_t_and_c_part2: 'Terms and conditions',
			checkout_t_and_c_not_checked:
				'You must agree to our terms and conditions',
			checkout_privacy_part1:
				'We will process your personal data in accordance with the ',
			checkout_privacy_part2: 'Privacy policy',
			checkout_privacy_not_checked:
				'Please agree to the privacy policy to proceed.',
		},
		vehicles: {
			name: 'Vehicle',
			names: 'Vehicles',
			sections: {
				details: 'Details',
				engine: 'Engine / body',
				legal: 'Legal',
			},
			fields: {
				'main_image.url': 'Main image',
				length: 'Length (cm)',
				width: 'Width (cm)',
				height: 'Height (cm)',
				weight: 'Weight (kg)',
				name: 'Name',
				external_link: 'Link',
				price: 'Price',
				description: 'Description',
				seats: 'Seats',
				fuel_tank_litres: 'Fuel tank (litres)',
				engine_name: 'Engine type',
				intake: 'Intake', //
				horse_power: 'Performance',
				cylinder: 'Cylinder', //
				fuel_type: 'Fuel type',
				body_type: 'Body type',
				colour: 'Colour',
				cargo: 'Cargo', //
				warranty: 'Warranty',
				registration: 'Registration',
			},
		},
		parts_finder: {
			title: 'Parts finder',
			button_1: 'BRP Online Parts Catalog',
			paragraph_1:
				'By clicking on the link below, you will be redirected to the BRP official parts search page. Here you can check the exploded view drawings and article number list by year and exact type. The site is available in English.',
			button_2: 'BRP PAC Online',
			paragraph_2:
				'If you are looking for accessories, optional parts or clothing, you can view the entire range on the following page:',
		},
		grants: {
			title: 'Grants',
			site: 'Site',
			site_desc:
				'A new hall is being built at our site, which will be worthy of the quality of our products and services. The investment is realized through a grant, which you can read about here.',
			service: 'Service',
			service_desc:
				'We are also expanding our service capacity to meet new demands, for example, with a new material handling machine, which is being realized under the following grant.',
		},
	},
	hu: {
		hungary: 'Magyarország',
		buttons: {
			review_order: 'Rendelés áttekintése',
			select: 'Kiválasztom',
			cancel: 'Mégse',
			send: 'Küldés',
			submit: 'Küldés',
			navigate_to_shop: 'A webáruházba',
			view_products: 'Termékek megtekintése',
			shop_now: 'Vásárolj most',
			subscribe: 'Feliratkozás',
			subscribe_now: 'Feliratkozom!',
			activate: 'Aktiválás',
			my_account: 'Belépés',
			back: 'Vissza',
			save: 'Mentés',
			confirm_and_pay: 'Megerősítés és fizetés',
			create_account: 'Regisztrálok',
			explore_more: 'Továbbiak felfedezése',
			continue_as_guest: 'Folytatás belépés nélkül',
		},
		errors: {
			generic: 'Hiba történt!',
			nothing_to_show: 'Sajnos itt nem találtunk semmit.',
		},
		dialog: {
			success: 'Nagyszerű!',
		},
		forms: {
			company_name: 'Cég név',
			tax_number: 'Ádószám',
			first_name: 'Keresztnév',
			last_name: 'Vezetéknév',
			email: 'Email cím',
			message: 'Üzenet',
			password: 'Jelszó',
			password_new: 'Új jelszó',
			password_reset_successful:
				'A jelszó módosítása sikeres volt.',
			password_reset: 'Módosítás megerősítése',
			password_reset_title: 'Új jelszó módosítása',
			password_reset_invalid_link:
				'Érvénytelen jelszó-visszaállítási link',
			password_reset_again:
				'Kattintson ide a visszaállításhoz',

			password_confirmation: 'Jelszó megerősítés',
			unsubscribe_invalid_link:
				'Érvénytelen leiratkozási link',
			forgotten_password: 'Elfelejtette jelszavát?',
			forgot_password: 'Elfelejtett jelszó',
			no_account: 'Nincs fiókja?',
			create_one_1: 'Hozzon létre ',
			create_one_2: 'egyet',
			phone_number: 'Telefonszám',
			street: 'Utca',
			street_extra: 'Kiegészítő cím',
			city: 'Város',
			post_code: 'Irányítószám',
			state: 'Megye',
			country: 'Ország',
		},

		validation: {
			password: {
				required: 'A jelszó megadása kötelező.',
				min_length:
					'A jelszónak legalább 8 karakter hosszúnak kell lennie.',
				confirmation_required:
					'A jelszó megerősítése kötelező.',
				confirmation_match:
					'A jelszavaknak egyezniük kell.',
			},
			generic: {
				first_name: 'Keresztnév megadása kötelező',
				last_name: 'Vezetéknév megadása kötelező',
				email: 'Az e-mail cím megadása kötelező',
				email_invalid: 'Az e-mail cím érvénytelen',
				message: 'Az üzenet megadása kötelező',
				phone: 'A telefonszám megadása kötelező',
				phone_invalid:
					'A telefonszámnak +36 vagy 06-al kell kezdődnie',
				company_name: 'Cég név megadása kötelező',
				tax_number: 'Adószám megadása kötelező',
			},
			shipping_billing: {
				street: 'Az utca megadása kötelező',
				city: 'A város megadása kötelező',
				post_code: 'Az irányítószám megadása kötelező',
				post_code_min:
					'Az irányítószámnak legalább 4 karakter hosszúnak kell lennie',
				state: 'A megye megadása kötelező',
				delivery_method_id:
					'Kérjük, válasszon egy szállítási módot',
			},
		},
		placeholder: {
			first_name: 'Adja meg keresztnevét',
			last_name: 'Adja meg vezetéknevét',
			email: 'Adja meg email címét',
			message: 'Írja meg üzenetét',
			password: 'Adja meg jelszavát',
			password_confirmation: 'Adja meg újra a jelszavát',
			phone_number: 'Adja meg telefonszámát',
			map_search: 'Keresés cím alapján',
		},
		sort: {
			label: 'Rendezés',
			a_to_z: 'ABC szerint növekvő',
			z_to_a: 'ABC szerint csökkenő',
			price_low_to_high: 'Ár szerint növekvő',
			price_high_to_low: 'Ár szerint csökkenő',
			newest: 'Legújabb',
			most_popular: 'Legnépszerűbb',
		},
		navigation: {
			parts_finder: 'Alkatrész keresés',
			pages: 'Oldalak',
			account: 'Felhasználó',
			login: 'Bejelentkezés',
			logout: 'Kijelentkezés',
			register: 'Regisztráció',
			home: 'Főoldal',
			shop: 'Webáruház',
			blog: 'Blog',
			order_history: 'Rendelési előzmények',
			pickup_details: 'Átvétel részletei',
			delivery_details: 'Szállítási cím',
			billing_details: 'Számlázási cím',
			virtual_tour: 'Virtuális Séta',
			contact_us: 'Kapcsolat',
			terms_and_conditions:
				'Általános Szerződési Feltételek',
			terms_and_conditions_short: 'ÁSZF',
			privacy_policy: 'Adatvédelmi tájékoztató',
			impressum: 'Impresszum',
			other: 'Egyéb',
		},
		heading: {},
		shop: {
			price_summary: 'Ár összefoglaló',
			cart_summary: 'Termék összefoglaló',
			detail_summary: 'Részletes összefoglaló',
			no_item: 'Nincs találat',
			on_sale: 'Leárazott',
			vehicles: 'Járművek',
			cart: 'Kosár',
			shopping_cart: 'Kosár',
			nothing_in_cart: 'Üres a kosarad',
			add_to_cart: 'Kosárba',
			added_to_cart: 'Kosárba helyezve',
			checkout: 'Fizetés',
			checkout_as_guest: 'Fizetés belépés nélkül',
			delete: 'Törlés',
			vat: 'Áfa',
			vat_inclusive: 'Áfa',
			discount: 'Vásárlási utalvány',
			product_not_found:
				'Sajnos nem találtuk meg amit kerestél.',
			back_to_shop: 'Vissza a termékekhez',
			back_to_home: 'Vissza a főoldalra',
			quantity: 'Mennyiség',
			name: 'Név',
			all_products: 'Termékek',
			products: 'Termékek',
			product: 'Termék',
			services: 'Szolgáltatások',
			price: 'Ár',
			in_stock: 'Raktáron',
			out_of_stock: 'Nincs raktáron',
			total: 'Végösszeg',
			subtotal: 'Nettó',
			delivery: 'Kiszállítás',
			billing: 'Számlázás',
			continue_shopping: 'Vásárlás folytatása',
			continue_to_payment: 'Tovább a Fizetéshez',
			payment: 'Fizetés',
			to_checkout: 'Tovább a Fizetéshez',
			to_shop: 'A termékekhez',
			more: 'Bővebben',
			less: 'Kevesebb mutatása',
			free: 'Ingyenes',
			items_in_cart: 'Tételek a kosárban',
		},
		contact: {
			details: 'Elérhetőségeink',
			location_question: 'Hol találsz meg minket?',
			have_a_question: 'Kérdése van?',
			hear_from: 'Szívesen hallanánk Öntől!',
			success:
				'Köszönjük üzenetét, hamarosan felvesszük Önnel a kapcsolatot.',
		},
		account: {
			title: 'Profilom',
			activation: {
				title: 'Felhasználó aktiválás',
				error:
					'Úgy tűnik, valami probléma van az aktiváló hivatkozásával. Kérjük, lépjen kapcsolatba velünk.',
			},
			information: {
				title: 'Személyes adatok',
				description:
					'Kezelje személyes adatait, ideértve az olyan elérhetőségeket, mint telefon és email cím',
			},
			delivery: {
				title: 'Szállítási cím',
				no_address: 'Nem találtunk szállítási címet',
				description:
					'Kezelje szállítási címeit, ahová a vásárolt tételek szállíthatók',
			},
			order_history_table: {
				reference: 'Azonosító',
				purchase_date: 'Vásárlás dátuma',
				status: 'Állapot',
				dialog_title: 'Vásárolt tételek',
				disclaimer:
					'Kérjük, vegye figyelembe, hogy a rendelések leadása után néhány percbe telhet, mire megjelennek a táblázatban.',
				no_orders_yet: 'Még nincs vásárlási előzmény',
			},
		},
		opening_days: {
			MON: 'Hétfő',
			TUE: 'Kedd',
			WED: 'Szerda',
			THU: 'Csütörtök',
			FRI: 'Péntek',
			SAT: 'Szombat',
			SUN: 'Vasárnap',
		},
		text: {
			reset_password_1:
				'Elküldtünk egy e-mailt az Ön e-mail címére a jelszó visszaállításához szükséges lépésekkel.',
			reset_password_2:
				'Ez az e-mail néhány percbe telhet, amíg megérkezik.',
			size_guide: 'Mérettáblázat',
			search: 'Keresés',
			company_details: 'Céges adatok',
			order_confirmation: 'Rendelés leadása',
			phone_number_delivery:
				'Minden szállítási módhoz szükséges egy telefonszám megadása.',
			guest_checkout: 'Regisztráció nélkül',
			guest_checkout_description:
				'Gyors és kényelmes fizetés pillanatok alatt. Fiók létrehozása nem szükséges.',
			login_description:
				'A fokozott biztonság érdekében kérjük, jelentkezzen be a fiókjába, vagy hozzon létre egyet most. Csak néhány pillanatig tart.',
			more_lockers: 'Keresés ezen a területen',
			closed: 'Zárva',
			choose_another_locker: 'Másik választása',
			locker_selected: 'Kiválasztott csomagautómata',
			opening_hours: 'Nyitvatartás',
			subscription_title_1: 'Iratkozzon fel ',
			subscription_title_2: 'a hírlevelünkre',
			subscription_detail:
				'Csatlakozzon az exkluzív ajánlatokhoz, fejleményekhez és hírekhez. Legyen az elsők között.',
			newsletter_failure:
				'Nem sikerült feliratkoztatni Önt hírlevelünkre.',
			newsletter_success:
				'Sikeresen feliratkozott hírlevelünkre.',
			newsletter: 'Hírlevél',
			subscribe_to_newsletter: 'Feliratkozom a hírlevélre',
			business_purchase: 'Céges vásárlás?',
			additional: 'Elerhetőség',
			request_new_pass: 'Új jelszó igénylése',
			page_size: 'Megjelenített termék:',
			reset: 'Szűrő visszaállítása',
			get_a_quote: 'Árajánlat kérése',
			customise_vehicle: 'testreszabás',
			privacy_acknowledged_1: 'Elolvastam és elfogadom az ',
			privacy_acknowledged_2: 'adatvédelmi tájékoztatót',
			privacy_acknowledged_3: ' a folytatáshoz',
			already_account: 'Van már belépési fiókja?',
			see_all_products: 'Összes termékünk',
			login_welcome_1: 'Üdvözöljük',
			login_welcome_2: 'újra',
			login_welcome_3: 'Magát.',
			register_welcome_1: 'Üdvözöljük',
			register_welcome_2: 'Önt ',
			register_welcome_3: 'Honlapunkon.',
			home_hero: 'Vezess. Fejlődj. Innoválj.',
			our_categories: 'Kategóriáink',
			categories: 'Kategóriák',
			address: 'Cím',
			most_popular_product: 'Legnépszerűbb termékünk',
			completion:
				'Sikeresen megkaptuk rendelését. Hamarosan megkapja rendelésének visszaigazolását e-mailben, és amint a rendelése szállításra kerül, újra felvesszük Önnel a kapcsolatot.',
			completion_success: 'Köszönjük a rendelését',
			completion_error:
				'Hiba történt a rendelés fizetése közben',
			completion_reference: 'Rendelési azonosító',
			description: 'Leírás',
			specification: 'Tulajdonságok',
			no_specification: 'Nincs megadott tulajdonság',
			coming_soon: 'Hamarosan elérhető',
			may_like: 'Ez is érdekelhet',
			stay_informed: 'Maradj tájékozott',
			delivery_is_same:
				'A szállítási cím megegyezik a számlázási címmel',
			footer_notice:
				'A honlapunkon található árak tájékoztató jellegűek, nem minősül ajánlattételnek. Fenntartjuk az árak változtatásának lehetőségét.',
		},
		checkbox: {
			checkout_t_and_c_part1:
				'A rendelés leadásával megerősíti, hogy egyetért az ',
			checkout_t_and_c_part2:
				'Általános Szerződési Feltételekkel',
			checkout_t_and_c_not_checked:
				'Kérjük, fogadja el az általános szerződési feltételeket a folytatáshoz.',
			checkout_privacy_part1:
				'Személyes adatait az alábbiak szerint fogjuk kezelni: ',
			checkout_privacy_part2: 'Adatvédelmi tájékoztató',
			checkout_privacy_not_checked:
				'Kérjük, fogadja el az adatvédelmi tájékoztatót a folytatáshoz.',
		},
		vehicles: {
			name: 'Jármű',
			names: 'Járművek',
			sections: {
				details: 'Részletek',
				engine: 'Motor',
				legal: 'Legal',
			},
			fields: {
				'main_image.url': 'Főkép',
				length: 'Hossz (cm)',
				width: 'Szélesség (cm)',
				height: 'Magasság (cm)',
				weight: 'Súly (kg)',
				name: 'Név',
				external_link: 'Link',
				price: 'Ár',
				description: 'Leirás',
				seats: 'Ülések',
				fuel_tank_litres: 'Üzemanyag tartály (L)',
				engine_name: 'Motor típus',
				intake: 'Szívórendszer',
				horse_power: 'Teljesítmény',
				cylinder: 'Hengerűrtartalom',
				fuel_type: 'Üzemanyag',
				body_type: 'Test típus',
				colour: 'Szín',
				cargo: 'Csomagtér',
				warranty: 'Garancia',
				registration: 'Regisztráció',
			},
		},
		parts_finder: {
			title: 'Alkatrész keresés',
			button_1: 'BRP online alkatrészkatalógus',
			button_2: 'BRP PAC Online',
			paragraph_1:
				'Az alábbi linkre kattintva érheti el a BRP hivatalos alkatrész kereső oldalát. Itt évjárat, és pontos típus szerint nézheti meg a robbantott ábrákat, és cikkszám listát. Az oldal angol nyelven érhető el',
			paragraph_2:
				'Amennyiben kiegészítőket, opcionális alkatrészeket vagy ruházatot keres, az alábbi oldalon tekintheti meg a teljes kínálatot:',
		},
		grants: {
			title: 'Pályázati támogatások',
			site: 'Telephely',
			site_desc:
				'Telephelyünkön új csarnok épül, amely méltó lesz termékeink és szolgáltatásaink színvonalához. A beruházás pályázatból valósul meg, amelyről itt olvashatsz.',
			service: 'Szervíz',
			service_desc:
				'Szerviz kapacitásunkat is bővítjük az új igényeknek megfelelően, pl. egy új anyagmozgató géppel, ez az alábbi pályázat keretében valósul meg.',
		},
	},
};
