import {
	Button,
	Grid,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
	RootState,
	cancelPayment,
	getCurrentOrder,
	getIsCheckoutLoading,
	getItemsInCart,
	getSelectedDelivery,
	setCheckoutLoading,
	store,
} from '../../../../redux';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { CostSummary } from '../../cart-page';
import { colors } from '../../../../themes';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { placeOrder } from '../helpers';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { CheckoutCheckboxes } from './CheckoutCheckboxes';

export const ConfirmDetails = ({
	showButton = true,
	isOnlinePayment = false,
}) => {
	const [isTandCAccepted, setIsTandCAccepted] = useState({
		isAccepted: false as boolean,
		checked: false as boolean,
		message: undefined as string | undefined,
	});
	const [
		isPrivacyPolicyAccepted,
		setIsPrivacyPolicyAccepted,
	] = useState({
		isAccepted: false as boolean,
		checked: false as boolean,
		message: undefined as string | undefined,
	});
	const navigate = useNavigate();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const isCheckoutLoading = useSelector(
		getIsCheckoutLoading,
	);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const discount = useSelector(
		(state: RootState) => state.checkout.discount,
	);
	const cartItems = useSelector(getItemsInCart);
	const selectedDelivery = useSelector(getSelectedDelivery);
	const orderUserDetails = useSelector(
		(state: RootState) => state.checkout.orderUserResponse,
	);
	const deliveryDetails = useSelector(
		(state: RootState) => state.checkout.deliveryDetails,
	);
	const shouldSubscribe = useSelector(
		(state: RootState) =>
			state.checkout.shouldSubscribe ?? false,
	);
	const selectedLocker = useSelector(
		(state: RootState) =>
			state.checkout.parcelShops.selected,
	);
	const navigateToConfirmation = async () => {
		store.dispatch(setCheckoutLoading(true));
		if (!isOnlinePayment) {
			if (!isTandCAccepted.isAccepted) {
				setIsTandCAccepted({
					isAccepted: false,
					checked: true,
					message:
						prompts[currentLocale].checkbox
							.checkout_t_and_c_not_checked,
				});
				store.dispatch(setCheckoutLoading(false));
				return;
			}
			if (!isPrivacyPolicyAccepted.isAccepted) {
				setIsPrivacyPolicyAccepted({
					isAccepted: false,
					checked: true,
					message:
						prompts[currentLocale].checkbox
							.checkout_privacy_not_checked,
				});
				store.dispatch(setCheckoutLoading(false));
				return;
			}
		}
		try {
			const orderPlaced: any = await placeOrder({
				orderUserDetails,
				cartItems,
				selectedDelivery,
				shouldSubscribe,
				selectedLocker,
				deliveryDetails,
				promo_code: discount?.code,
			});

			if (orderPlaced) {
				navigate(
					`/completion?ref=${orderPlaced?.reference}&redirect_status=succeeded`,
					{
						state: { isOnline: false },
					},
				);
			}
		} catch (e) {
			console.log(e);
		}
		store.dispatch(setCheckoutLoading(false));
	};
	const navigateBack = () => {
		store.dispatch(cancelPayment());
	};
	return (
		<Paper
			square
			variant="outlined"
			sx={
				isSmallScreen
					? { padding: 2, border: 'none' }
					: {
							padding: 4,
							marginBottom: 4,
							flex: 2,
							// maxWidth: '40%',
					  }
			}
		>
			<Grid
				container
				sx={{
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					flexDirection: 'row',
					marginBottom: 2,
				}}
			>
				<Grid
					alignItems="center"
					minWidth="200px"
					container
				>
					<FontAwesomeIcon
						icon={faMoneyBill}
						style={{
							padding: 8,
							marginRight: 8,
							background: colors['brp-yellow'].main,
						}}
					/>
					<Typography
						variant="body1"
						component="span"
						sx={{
							textTransform: 'capitalize',
							color: colors['brp-black'].main,
						}}
						marginBottom="-5px"
					>
						{translations.shop.price_summary}
					</Typography>
				</Grid>
			</Grid>
			<CostSummary isOrderConfirm />
			{!isOnlinePayment ? (
				<CheckoutCheckboxes
					shouldSubscribe={shouldSubscribe}
					translations={prompts[currentLocale]}
					isTandCAccepted={isTandCAccepted}
					isPrivacyPolicyAccepted={isPrivacyPolicyAccepted}
					setIsPrivacyPolicyAccepted={
						setIsPrivacyPolicyAccepted
					}
					setIsTandCAccepted={setIsTandCAccepted}
				/>
			) : null}
			{showButton ? (
				<Grid
					container
					justifyContent="space-between"
					sx={{ mt: 2 }}
				>
					<Button
						onClick={navigateBack}
						variant="brp-black"
						sx={{ textTransform: 'capitalize' }}
						disabled={isCheckoutLoading}
					>
						{prompts[currentLocale].buttons.cancel}
					</Button>
					<LoadingButton
						color="secondary"
						onClick={navigateToConfirmation}
						loading={isCheckoutLoading}
						loadingPosition="start"
						startIcon={<ShoppingBasketIcon />}
						variant="brp-yellow"
						sx={{
							marginLeft: isSmallScreen ? 2 : 0,
						}}
						className="capitalise"
					>
						<span style={{ marginTop: 2 }}>
							{translations.text.order_confirmation}
						</span>
					</LoadingButton>
				</Grid>
			) : null}
		</Paper>
	);
};
