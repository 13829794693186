import { Container } from '@mui/material';
import {
	CategoryCards,
	Heading,
} from '../../../components';
import { Category, Product } from '../../../redux';
import Grid from '@mui/material/Unstable_Grid2';
import styles from '../home.module.scss';

export const Section = ({
	payload,
	headingTitle,
	verticalLineColor,
	ctaText,
	hasCta = false,
	subTitle,
	path = '#',
}) => {
	const cards = payload.map((obj: Category | Product) => (
		<Grid key={obj.id} xs={12} md={5}>
			<CategoryCards
				key={obj.id}
				payload={obj}
				ctaText={ctaText}
				hasCta={hasCta}
			/>
		</Grid>
	));

	return (
		<Container
			sx={{
				marginTop: { xs: '32px', md: '64px' },
				marginBottom: { xs: '32px', md: '64px' },
			}}
		>
			<Heading
				subTitle={subTitle}
				title={headingTitle}
				verticalLineColor={verticalLineColor}
				hasCta={true}
				path={path}
			/>

			<Grid
				justifyContent="center"
				container
				spacing={4}
				className={styles.categoryCardsContainer}
			>
				{cards}
			</Grid>
		</Container>
	);
};
