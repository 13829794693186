import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { colors } from '../../themes';
import * as Yup from 'yup';

import {
	Button,
	Container,
	ListItem,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import jetpower from '../../assets/jetpower.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {
	faFax,
	faPhone,
} from '@fortawesome/free-solid-svg-icons';
import {
	faFacebookF,
	faInstagram,
	faTiktok,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { useSelector } from 'react-redux';
import {
	DynamicPage,
	RootState,
	store,
	subscribe,
} from '../../redux';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';

const FooterContentListItem = styled(ListItem)(() => ({
	paddingLeft: 0,
}));
const FooterHeaderItem = styled(Box)(() => ({
	fontSize: '16px',
	textTransform: 'capitalize',
	marginBottom: '16px',
}));
const FooterContentItem = styled(Box)(() => ({
	// pl: 2,
	listStyle: 'none',
	color: colors['brp-grey'].main,
}));

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: 'transparent',
	color: colors['brp-white'].main,
	// 	theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'left',
	boxShadow: 'none',
	// color: theme.palette.text.secondary,
}));

export const Footer = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);

	const dynamicPages: DynamicPage[] = useSelector(
		(state: RootState) =>
			state.locale.languages?.[currentLocale].pages,
	);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);
	const initialValues = {
		email: '',
	};
	const validationSchema = Yup.object({
		email: Yup.string()
			.email(
				prompts[currentLocale].validation.generic
					.email_invalid,
			)
			.required(
				prompts[currentLocale].validation.generic.email,
			),
	});

	const isRequestPending = useSelector(
		(state: RootState) =>
			state.generic.subscribe === 'loading',
	);

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values, { resetForm }) => {
			await store.dispatch(
				subscribe({
					email: values.email,
					showResponse: true,
				}),
			);
			resetForm();
		},
	});
	return (
		<footer style={{ marginTop: '-1px' }}>
			<Box sx={{ flexGrow: 1 }}>
				<Grid
					container
					sx={{
						background: colors['brp-black'].main,
						justifyContent: 'center',
					}}
				>
					<Grid
						container
						maxWidth="lg"
						sx={{
							paddingTop: '16px',
							paddingBottom: '16px',
						}}
					>
						<Grid
							item
							sx={{
								paddingLeft: '8px',
								paddingRight: '8px',
							}}
							xs={12}
							md={5}
							lg={4}
						>
							{/* <Item>Email subscribe section</Item> */}
							<img
								src={jetpower}
								alt="Jetpower"
								style={{ width: 200 }}
							/>
							<FooterContentItem
								sx={{
									marginTop: '16px',
									width: '80%',
									textAlign: 'justify',
								}}
							>
								{prompts[currentLocale].text.footer_notice}
							</FooterContentItem>

							<Item
								sx={{
									paddingLeft: 0,
									paddingBottom: 0,
									paddingTop: 2,
									fontSize: 16,
								}}
							>
								{
									prompts[currentLocale].contact
										.location_question
								}
							</Item>
							<FooterContentItem sx={{ marginTop: '16px' }}>
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2703.584546375897!2d19.11513317702282!3d47.34198447116677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741eeca4e7fc0af%3A0xa7a7814526dad8e5!2sJet%20Power%20Hungary!5e0!3m2!1sen!2suk!4v1702125471264!5m2!1sen!2suk"
									width="80%"
									height="250"
									style={{ border: 0 }}
									// allowFullScreen=""
									loading="lazy"
									referrerPolicy="no-referrer-when-downgrade"
								></iframe>
							</FooterContentItem>
						</Grid>
						<Grid
							container
							xs={12}
							md={7}
							lg={8}
							item
							justifyContent="flex-end"
							// spacing={4}
						>
							{/* <Grid item xs={6} lg={3}>
								<Item>
									<FooterHeaderItem id="home">
										{
											prompts[currentLocale].navigation
												.pages
										}
									</FooterHeaderItem>
									<FooterContentItem
										component="ul"
										aria-labelledby="Home"
									>
										<FooterContentListItem>
											<Link to="/" className="grey-color">
												{
													prompts[currentLocale].navigation
														.home
												}
											</Link>
										</FooterContentListItem>
										<FooterContentListItem>
											<Link
												to="/parts-finder"
												className="grey-color"
											>
												{
													prompts[currentLocale].navigation
														.parts_finder
												}
											</Link>
										</FooterContentListItem>
									</FooterContentItem>
								</Item>
							</Grid> */}
							<Grid item xs={6} lg={3}>
								<Item>
									<FooterHeaderItem id="shop">
										{prompts[currentLocale].text.categories}
									</FooterHeaderItem>
									<FooterContentItem
										component="ul"
										aria-labelledby="category-b"
									>
										<FooterContentListItem>
											Sea doo
										</FooterContentListItem>
										<FooterContentListItem>
											Ski doo
										</FooterContentListItem>
										<FooterContentListItem>
											Can-am off-road
										</FooterContentListItem>
										<FooterContentListItem>
											Can-am on-road
										</FooterContentListItem>
									</FooterContentItem>
								</Item>
								<Item>
									<FooterHeaderItem
										id="shop"
										sx={{ mb: 0 }}
									>
										{prompts[currentLocale].text.newsletter}
									</FooterHeaderItem>
									<FooterContentItem component="ul">
										<FooterContentListItem
											sx={{ paddingTop: 0 }}
										>
											<form onSubmit={formik.handleSubmit}>
												<TextField
													fullWidth
													id="email"
													name="email"
													label={
														prompts[currentLocale].forms
															.email
													}
													placeholder={
														prompts[currentLocale]
															.placeholder.email
													}
													sx={{
														// '& .MuiInputBase-input.MuiOutlinedInput-input':
														'& .MuiInputBase-root.MuiInput-root':
															{
																color:
																	colors['brp-grey'].main,
															},
														'& .MuiFormLabel-root.MuiInputLabel-root':
															{
																color:
																	colors['brp-grey'].main,
															},
														'& .MuiFormLabel-root.MuiInputLabel-root .MuiInput-underline:after':
															{
																borderBottomColor: 'green',
															},
													}}
													variant="standard"
													value={formik.values.email}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													error={
														formik.touched.email &&
														Boolean(formik.errors.email)
													}
													helperText={
														formik.touched.email &&
														formik.errors.email
													}
													size="small"
												/>
												<LoadingButton
													type="submit"
													loading={isRequestPending}
													variant="brp-black"
													sx={{
														textAlign: 'left',
														width: 'max-content',
														paddingLeft: 0,
														marginTop: 1,
													}}
												>
													<span>
														{
															prompts[currentLocale].buttons
																.subscribe
														}
													</span>
												</LoadingButton>
											</form>
										</FooterContentListItem>
									</FooterContentItem>
								</Item>
							</Grid>
							{/* todo: maybe move this below categories */}
							<Grid item xs={6} lg={3}>
								<Item>
									<FooterHeaderItem id="other">
										{
											prompts[currentLocale].navigation
												.other
										}
									</FooterHeaderItem>
									<FooterContentItem
										component="ul"
										aria-labelledby="other"
									>
										{/* <FooterContentListItem>
											<Link
												to="/blog"
												className="grey-color"
											>
												{
													prompts[currentLocale].navigation
														.blog
												}
											</Link>
										</FooterContentListItem> */}
										{dynamicPages.map((pageItem) => {
											return (
												<FooterContentListItem
													key={pageItem.slug}
												>
													<Link
														to={`/${pageItem.slug}`}
														className="grey-color"
													>
														{pageItem.title}
													</Link>
												</FooterContentListItem>
											);
										})}
										<FooterContentListItem>
											<Link
												to="/parts-finder"
												className="grey-color"
											>
												{
													prompts[currentLocale].navigation
														.parts_finder
												}
											</Link>
										</FooterContentListItem>
										{/* <FooterContentListItem>
											<Link
												to="/contact"
												className="grey-color"
											>
												{
													prompts[currentLocale].navigation
														.contact_us
												}
											</Link>
										</FooterContentListItem> */}
										{/* <FooterContentListItem>
											<Link
												to="/impressum"
												className="grey-color"
											>
												{
													prompts[currentLocale].navigation
														.impressum
												}
											</Link>
										</FooterContentListItem> */}
									</FooterContentItem>
								</Item>
							</Grid>
							<Grid item xs={6} lg={3}>
								<Item>
									<FooterHeaderItem id="info">
										{
											prompts[currentLocale].text
												.stay_informed
										}
									</FooterHeaderItem>
									<FooterContentItem
										component="ul"
										aria-labelledby="info"
									>
										{/* todo: make all of these aligned properly */}
										<Container
											style={{
												marginBottom: '16px',
												padding: 0,
											}}
										>
											<FontAwesomeIcon
												color={colors['brp-yellow'].main}
												icon={faEnvelope}
												style={{ paddingRight: '8px' }}
												title="Email"
											/>
											<a
												style={{
													width: 'max-content',
													color: colors['brp-grey'].main,
												}}
												href="mailto:info@jetpower.com"
												rel="noreferrer"
												target="_blank"
											>
												info@jetpower.com
											</a>
										</Container>
										<Container
											style={{
												marginBottom: '16px',
												padding: 0,
											}}
										>
											<FontAwesomeIcon
												color={colors['brp-yellow'].main}
												icon={faPhone}
												style={{ paddingRight: '8px' }}
												title="Telephone"
											/>
											<a
												href="tel:+36 24 492 280"
												rel="noreferrer"
												target="_blank"
												style={{
													width: 'max-content',
													color: colors['brp-grey'].main,
												}}
											>
												+36 24 492 280
											</a>
										</Container>
										<Container
											style={{
												marginBottom: '16px',
												padding: 0,
											}}
										>
											<FontAwesomeIcon
												color={colors['brp-yellow'].main}
												icon={faFax}
												style={{ paddingRight: '8px' }}
												title="Fax"
											/>
											<Link
												style={{
													width: 'max-content',
													color: colors['brp-grey'].main,
												}}
												to="/"
											>
												+36 24 461 383
											</Link>
										</Container>
										{/* <Container
											style={{
												marginBottom: '16px',
												padding: 0,
											}}
										>
											<FontAwesomeIcon
												color={colors['brp-yellow'].main}
												icon={faMapPin}
												style={{ paddingRight: '8px' }}
												title="Address"
											/>
											<a
												style={{
													width: 'max-content',
													color: colors['brp-grey'].main,
												}}
												href="https://maps.app.goo.gl/ksxwYRFjx8cbpJL89"
												rel="noreferrer"
												target="_blank"
											>
												Dunaharaszti
											</a>
										</Container> */}
										<Container
											style={{
												marginBottom: '16px',
												padding: 0,
											}}
										>
											<FontAwesomeIcon
												icon={faYoutube}
												color={colors['brp-yellow'].main}
												style={{ paddingRight: '8px' }}
											/>
											<a
												style={{
													width: 'max-content',
													color: colors['brp-grey'].main,
												}}
												href="https://www.youtube.com/@jetpowerhungary6054/videos"
												rel="noreferrer"
												target="_blank"
											>
												Jet Power Hungary
											</a>
										</Container>
										<Container
											style={{
												marginBottom: '16px',
												padding: 0,
											}}
										>
											<FontAwesomeIcon
												icon={faFacebookF}
												color={colors['brp-yellow'].main}
												style={{ paddingRight: '8px' }}
											/>
											<a
												style={{
													width: 'max-content',
													color: colors['brp-grey'].main,
												}}
												href="https://www.facebook.com/jetpowerhungary/"
												rel="noreferrer"
												target="_blank"
											>
												Jet Power Hungary Kft
											</a>
										</Container>
										<Container
											style={{
												marginBottom: '16px',
												padding: 0,
											}}
										>
											<FontAwesomeIcon
												icon={faTiktok}
												color={colors['brp-yellow'].main}
												style={{ paddingRight: '8px' }}
											/>
											<a
												style={{
													width: 'max-content',
													color: colors['brp-grey'].main,
												}}
												href="https://www.tiktok.com/@jetpower_hungary"
												rel="noreferrer"
												target="_blank"
											>
												Jet Power Hungary
											</a>
										</Container>
										<Container
											style={{
												marginBottom: '16px',
												padding: 0,
											}}
										>
											<FontAwesomeIcon
												icon={faInstagram}
												color={colors['brp-yellow'].main}
												style={{ paddingRight: '8px' }}
											/>
											<a
												style={{
													width: 'max-content',
													color: colors['brp-grey'].main,
												}}
												href="https://www.instagram.com/jetpower_hungary"
												rel="noreferrer"
												target="_blank"
											>
												jetpower_hungary
											</a>
										</Container>
									</FooterContentItem>
								</Item>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						// fontSize: '12px',
						background: colors['brp-black'].darker,
						justifyContent: 'center',
						flexDirection: { xs: 'column', sm: 'row' },
					}}
				>
					<Grid
						// xs={12}
						maxWidth="lg"
						container
						justifyContent="space-between"
						// alignItems="center"
					>
						<Grid
							container
							sx={{
								width: isSmallScreen
									? '100%'
									: 'max-content',
								justifyContent: isSmallScreen
									? 'space-evenly'
									: 'flex-start',
							}}
						>
							<Item>
								<Link
									style={{
										color: colors['brp-grey'].main,
									}}
									to="/terms-of-service"
								>
									{
										prompts[currentLocale].navigation
											.terms_and_conditions_short
									}
								</Link>
							</Item>
							<Item>
								<Link
									style={{
										color: colors['brp-grey'].main,
									}}
									to="/privacy-policy"
								>
									{
										prompts[currentLocale].navigation
											.privacy_policy
									}
								</Link>
							</Item>
							<Item>
								<Link
									style={{
										color: colors['brp-grey'].main,
									}}
									to="/impressum"
								>
									{
										prompts[currentLocale].navigation
											.impressum
									}
								</Link>
							</Item>
						</Grid>
						<Grid
							sx={{
								order: { xs: 2, sm: 1 },
								width: 'max-content',
								margin: isSmallScreen ? '0 auto' : 0,
								paddingRight: isSmallScreen ? 0 : 1,
							}}
						>
							<Item sx={{ color: colors['brp-grey'].main }}>
								© 2023. All rights reserved. JetPower
								Hungary
							</Item>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</footer>
	);
};

export const DefaultFooter: React.FC = () => {
	return (
		<footer>
			<nav>
				<Link to="/terms-of-service">T&Cs</Link>
				<Link to="/privacy-policy">Privacy</Link>
			</nav>
		</footer>
	);
};
