import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteHandler } from './components/index';
import {
	fetchCategories,
	fetchProducts,
	fetchSettings,
	selectIsLoading,
	getPromoMessages,
	store,
	loginViaToken,
	getLangFile,
} from './redux';
import { Backdrop, CircularProgress } from '@mui/material';
import './App.css';
import jetpower from './assets/jetpower.png';
import { colors } from './themes';
import { reinstateCartStateIfRequired } from './helpers';

const App = () => {
	const loading = useSelector(selectIsLoading);
	useEffect(() => {
		store.dispatch(getLangFile());
		store.dispatch(fetchProducts());
		store.dispatch(fetchSettings());
		store.dispatch(fetchCategories());
		store.dispatch(getPromoMessages());
		reinstateCartStateIfRequired();
		const token =
			store.getState()?.auth?.access_token ??
			localStorage.getItem('userToken');
		if (token) {
			store.dispatch(loginViaToken());
		}
	}, []);
	return loading ? (
		<Backdrop
			sx={{
				color: '#fff',
				background: colors['brp-black'].main,
				flexDirection: 'column',
				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}
			open={loading}
			// onClick={handleClose}
		>
			<img
				src={jetpower}
				alt="Footer Logo"
				style={{ marginBottom: '1rem', width: 200 }}
			/>
			<CircularProgress color="inherit" />
		</Backdrop>
	) : (
		<RouteHandler />
	);
};

export default App;
