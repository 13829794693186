import React, { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
	BrowserRouter,
	Routes,
	Route,
} from 'react-router-dom';
import { store } from './redux';
import App from './App';
import { ThemeProvider } from './themes/index';
import './styles/general.scss';
import {
	Backdrop,
	CircularProgress,
	GlobalStyles,
} from '@mui/material';
import { ScrollToTop } from './helpers';
import { GenericDialog } from './components';

const container = document.getElementById(
	'root',
) as HTMLElement;

const inputGlobalStyles = <GlobalStyles styles={{}} />;

const root = createRoot(container);
root.render(
	<Provider store={store}>
		<ThemeProvider>
			{inputGlobalStyles}
			<BrowserRouter>
				{/* <Backdrop
					sx={{
						color: '#fff',
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}
					// onClick={handleClose}
				>
					<CircularProgress color="inherit" />
				</Backdrop> */}
				<GenericDialog />
				<ScrollToTop />
				<Routes>
					<Route path="/*" element={<App />} />
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	</Provider>,
);
