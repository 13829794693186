import {
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux';

export const RenderDetails = ({
	payload,
	title,
}: {
	title: string | null;
	payload: object | undefined;
}) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];

	const keysToIgnore = [
		'id',
		'country_id',
		'notes',
		'country',
	];
	if (!payload) return <></>;
	return (
		<Grid
			container
			sx={{
				marginBottom: isSmallScreen ? 1 : 0,
				flexDirection: 'column',
			}}
		>
			{!!title ? (
				<Typography
					variant="body1"
					component="p"
					style={{
						textDecoration: 'underline',
						fontWeight: 'bold',
						width: '100%',
					}}
				>
					{title}
				</Typography>
			) : null}
			{Object.entries(payload).map(([key, value]) => {
				if (keysToIgnore.includes(key)) return;
				return (value !== '' && value !== undefined) ||
					!value ? (
					<Typography
						key={key + value}
						variant="body1"
						component="p"
						sx={key === 'tax_number' ? { mt: 1 } : {}}
					>
						{key === 'tax_number' && value
							? `${translations.forms.tax_number}: `
							: null}
						{value}
					</Typography>
				) : null;
			})}
		</Grid>
	);
};
