import {
	Card,
	CardContent,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import { colors } from '../../themes';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

type ContactInfo = {
	name?: string;
	mobile?: string;
	tel?: string;
	tel2?: string; // Additional telephone number
	email?: string | string[]; // Array of email addresses
	fax?: string;
};

type Business = {
	name?: string;
	contact: ContactInfo;
	products: string[];
	services?: string;
	address: string;
	website?: string;
	facebook?: string;
};
const businesses: Business[] = [
	{
		name: 'Wilker M3 Kft – Fót',
		contact: {
			name: 'László Tibor',
			mobile: '+36 30 300 5870',
			tel: '+36 27 535 425',
			email: ['info@wilker.hu', 'tibor@wilker.hu'],
		},
		products: ['BRP Ski-Doo', 'Can-Am ATV', 'Can-Am SSV'],
		services: 'értékesítés, alkatrészek és szerviz',
		address:
			'2151 Fót, Fehérkő út 4. Budapest M3-M0 csomópont',
		website: 'https://wilker.honda.hu',
		facebook:
			'https://www.facebook.com/Wilker-Honda-M3-motorker%C3%A9kp%C3%A1r-quad-%C3%A9s-motoros-sz%C3%A1n-szalon-729985130371729/',
	},
	{
		name: 'Hajósparadicsom Kft – Budapest',
		contact: {
			name: 'Zilahi Attila',
			tel: '+36 1 276 0151',
			mobile: '+36 70 614 7171',
			fax: '+36 1 700 2342',
			email: 'info@hajosparadicsom.hu',
		},
		products: [
			'Evinrude',
			'Johnson',
			'OMC alkatrészek és szerviz',
		],
		services: 'Hajófelszerelések, Hajózási kiegészítők',
		address:
			'1211 Budapest Szikratávíró út 17-21 E4 épület',
		website: 'http://webaruhaz.hajosparadicsom.hu',
		facebook: 'https://www.facebook.com/Hajosparadicsom',
	},
	{
		name: 'Gál Motorcsónak Kft – Budapest IV.',
		contact: {
			name: 'Gál Nándor',
			tel: '+36 20 946 7118',
			tel2: '+36 1 387 1514',
			email: 'galmotorcsonak@gmail.com',
		},
		products: [
			'Evinrude',
			'Johnson',
			'OMC alkatrészek és szerviz',
		],
		address: '1044 Budapest, Rév utca 2.',
		website: 'http://www.galhajo-motorcsonakszerviz.hu/',
	},
	{
		name: 'S&G Bt – Paks',
		contact: {
			name: 'Schubert Endre',
			tel: '+36 75 510 890',
			mobile: '+36 20 521 4429',
			email: 'schubert.s.g.bt@t-online.hu',
		},
		products: [
			'Evinrude',
			'Johnson',
			'OMC alkatrészek és szerviz',
		],
		address: '7030 Paks, Széchenyi tér 13.',
		website: 'http://www.sandgbt.hu/',
	},
	{
		name: 'Csali Horgászbolt – Rácalmás',
		contact: {
			name: 'Dualszky-Kovács István',
			tel: '+36 25 440 750',
			mobile: '+36 20 932 6445',
			email: 'dualszky@tolna.net',
		},
		products: [
			'Evinrude',
			'Johnson',
			'OMC alkatrészek és szerviz',
		],
		address: '2459 Rácalmás, Széchenyi tér 10/1.',
	},
	{
		name: 'Bodrogvölgyi és Társa Bt – Szigethalom',
		contact: {
			mobile: '+36 70 576 9118',
			email: 'bodrogvölgyi47@freemail.hu',
		},
		products: [
			'Evinrude',
			'Johnson',
			'OMC alkatrészek és szerviz',
		],
		address: '2315 Szigethalom, VII. u. 1.',
	},
	{
		name: 'Kelemen Tibor – Mohács',
		contact: {
			tel: '+36 30 255 2207',
			email: 'kelemenmarine@citromail.hu',
		},
		products: [
			'Evinrude',
			'Johnson',
			'OMC alkatrészek és szerviz',
		],
		address: '7700 Mohács, Eötvös u. 23.',
	},
	{
		name: 'Gelencsér István – Balatonföldvár',
		contact: {
			tel: '+36 70 201 2673',
		},
		products: [
			'Evinrude',
			'Johnson',
			'OMC alkatrészek és szerviz',
		],
		address: '8623 Balatonfölrdvár, Rét u. 17.',
	},
	{
		name: 'Kőműves Tibor – Szeged',
		contact: {
			tel: '+36 30 943 5727',
			email: 'komuves.tibor1@gmail.com',
		},
		products: [
			'Evinrude',
			'Johnson',
			'OMC alkatrészek és szerviz',
		],
		address: '6771 Szeged, Temesvári u. 14.',
	},
	{
		name: 'Fekete Imre – Abádszalók',
		contact: {
			tel: '+36 30 219 1782',
		},
		products: [
			'Evinrude',
			'Johnson',
			'OMC alkatrészek és szerviz',
		],
		address: '5241 Abádszalók, Deák F. u. 34.',
	},
	{
		name: 'Hanácsek Hajószerviz Kft – Abádszalók',
		contact: {
			name: 'Hanácsek György',
			tel: '+36 30 470 0004',
			email: 'hanacsek495@t-online.hu',
		},
		products: [
			'Evinrude',
			'Johnson',
			'OMC alkatrészek és szerviz',
		],
		address: '5241 Abádszalók, József Attila körút 48.',
	},
];

const BusinessCard = ({ business, translations }) => (
	<Card
		variant="outlined"
		square
		sx={{
			border: 'none',
			borderColor: colors['brp-black'].main,
			height: '100%',
		}}
	>
		<CardContent>
			<Typography
				variant="h6"
				style={{ position: 'relative' }}
			>
				{business.name}

				<span
					style={{
						position: 'absolute',
						left: '3%',
						bottom: 0,
						width: '60px',
						height: '4px',
						backgroundColor: colors['brp-yellow'].main,
						// marginLeft: 'calc(100% - 100px - 25%)',
					}}
				></span>
			</Typography>
			<Typography variant="body1" color="textSecondary">
				{business.address}
			</Typography>
			{business.website && (
				<Typography variant="body1">
					<a
						rel="noreferrer"
						target="_blank"
						href={business.website}
					>
						{business.website}
					</a>
				</Typography>
			)}
			<List>
				{business?.contact?.name && (
					<ListItem sx={{ py: 0 }} disableGutters>
						<ListItemText
							primary={`Contact: ${business?.contact?.name}`}
						/>
					</ListItem>
				)}
				{business?.contact?.mobile && (
					<ListItem sx={{ py: 0 }} disableGutters>
						<ListItemText
							primary={`Mobile: ${business?.contact?.mobile}`}
						/>
					</ListItem>
				)}
				{business?.contact?.tel && (
					<ListItem sx={{ py: 0 }} disableGutters>
						<ListItemText
							primary={`Tel: ${business?.contact?.tel}`}
						/>
					</ListItem>
				)}
				{business?.contact?.tel2 && (
					<ListItem sx={{ py: 0 }} disableGutters>
						<ListItemText
							primary={`Tel: ${business?.contact?.tel2}`}
						/>
					</ListItem>
				)}
				{business?.contact?.email && (
					<ListItem sx={{ py: 0 }} disableGutters>
						<ListItemText
							primary={`Email: ${
								Array.isArray(business?.contact?.email)
									? business?.contact?.email.join(', ')
									: business?.contact?.email
							}`}
						/>
					</ListItem>
				)}
			</List>
			<Divider
				sx={{ borderColor: colors['brp-black'].main }}
			/>
			<List>
				<ListItem sx={{ py: 0 }} disableGutters>
					<ListItemText
						primary={`${
							translations.shop.products
						}: ${business.products.join(', ')}`}
					/>
				</ListItem>
				{business.services && (
					<ListItem sx={{ py: 0 }} disableGutters>
						<ListItemText
							primary={`${translations.shop.services}: ${business.services}`}
						/>
					</ListItem>
				)}
			</List>
			{business.facebook && (
				<a
					rel="noreferrer"
					target="_blank"
					href={business.facebook}
					style={{ cursor: 'pointer' }}
				>
					<FontAwesomeIcon
						style={{ fontSize: '24px' }}
						icon={faFacebook}
					/>
				</a>
			)}
		</CardContent>
	</Card>
);

const BusinessList = ({ businesses, translations }) => (
	<>
		{businesses.map((business, index) => (
			<Grid
				container
				key={index}
				// xs={6}
				// md={4}
				// lg={3}
				sx={{
					// this isn't responsive
					// '&:nth-of-type(even) .MuiPaper-root': {
					// 	backgroundColor:
					// 		colors['brp-yellow-checkout'].checkout,
					// },
					// '&:last-of-type .MuiPaper-root': {
					// 	backgroundColor:
					// 		colors['brp-yellow-checkout'].checkout,
					// },
					padding: '4px',
				}}
			>
				<BusinessCard
					key={index}
					translations={translations}
					business={business}
				/>
			</Grid>
		))}
	</>
);

export const Partners = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	return (
		<Grid
			container
			maxWidth="lg"
			justifyContent="space-between"
			sx={{ margin: '0 auto 32px' }}
		>
			<BusinessList
				translations={translations}
				businesses={businesses}
			/>
		</Grid>
	);
};
