import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Footer, InfoLine } from '..';

export const Layout = () => {
	return (
		<>
			<InfoLine />
			<Header />
			<main className="app">
				<Outlet />
			</main>
			<Footer />
		</>
	);
};
