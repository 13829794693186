import {
	Button,
	CardActions,
	CardContent,
	Grid,
	Paper,
	Typography,
} from '@mui/material';
import placeholder from '../../assets/placeholder.png';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import {
	fetchFilteredProducts,
	store,
	toggleCategory,
} from '../../redux';
import { parseHtml } from '../../views/helpers';

export const CategoryCards = ({
	payload,
	hasCta,
	ctaText,
}) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);

	const navigate = useNavigate();
	const handleRedirect = () => {
		handleAttributeToggle(payload.id);
		if (payload.id < 5) {
			navigate(`/shop/vehicles?category=${payload.id}`);
		} else {
			navigate('/shop/vehicles?category=1');
		}
	};

	const handleAttributeToggle = async (
		categoryId: number,
	) => {
		store.dispatch(
			toggleCategory({
				categoryId,
				onlyReceivedCategories: true,
			}),
		);

		const filters =
			store.getState().products.filtersApplied;
		const sort = store.getState().products.sortApplied;

		await store.dispatch(
			fetchFilteredProducts({
				filters,
				sort,
			}),
		);
	};

	return (
		<Paper
			sx={{
				maxWidth: 400,
				boxShadow: 'none',
			}}
		>
			{/*  */}
			<Link to={`/shop/vehicles?category=${payload.id}`}>
				<img
					style={{
						height: 200,
						width: '100%',
						objectFit: 'cover',
					}}
					title={payload.name}
					src={payload?.main_image?.url ?? placeholder}
				/>
			</Link>
			<CardContent sx={{ padding: 0 }}>
				<Grid
					container
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography
						gutterBottom
						variant="h5"
						component="h5"
						sx={{ margin: 0 }}
					>
						{payload.name}
					</Typography>
					{hasCta && (
						<Button
							sx={{
								marginLeft: isSmallScreen ? 0 : 'auto',
							}}
							size="small"
							variant="brp-white"
							onClick={handleRedirect}
						>
							{ctaText}
						</Button>
					)}
				</Grid>
				<section
					style={{
						marginTop: '8px',
						color: 'rgba(0, 0, 0, 0.6)',
					}}
				>
					{parseHtml(payload.description)}
				</section>
			</CardContent>
		</Paper>
	);
};
