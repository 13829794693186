import { TextField } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { LoadingButton } from '@mui/lab';
import {
	RootState,
	applyDiscount,
	store,
} from '../../../../redux';
import { useSelector } from 'react-redux';

export const DiscountField = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const initialValues = {
		discount: '',
	};

	const handleSubmit = async (values) => {
		await store.dispatch(applyDiscount(values.discount));
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ isSubmitting }) => (
				// consider increasing padding here but it causes issues with height
				<Form
					style={{
						paddingBottom: 16,
						width: '100%',
						boxSizing: 'border-box',
					}}
				>
					<Field
						name="discount"
						label={prompts[currentLocale].shop.discount}
						fullWidth
						size="small"
						as={TextField}
						sx={{ flex: 1 }}
						InputProps={{
							endAdornment: (
								<LoadingButton
									type="submit"
									loading={isSubmitting}
									sx={{ minWidth: 'auto', width: '32px' }}
								>
									<FontAwesomeIcon icon={faCheckSquare} />
								</LoadingButton>
							),
						}}
					/>
				</Form>
			)}
		</Formik>
	);
};
