export const getPages = (translations: any) => {
	return [
		{
			id: 1,
			text: translations.navigation.home,
			route: '/',
		},
		{
			id: 2,
			text: translations.navigation.shop,
			route: '/shop/categories', // '/shop',
		},
		{
			id: 4,
			text: translations.navigation.blog,
			route: '/blog',
		},
		{
			id: 5,
			text: translations.navigation.virtual_tour,
			route: '/virtual-tour',
		},
		{
			id: 6,
			text: translations.navigation.contact_us,
			route: '/contact',
		},
	];
};
