import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '../../themes';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

export const PartsFinder = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	return (
		<Grid
			container
			maxWidth="lg"
			sx={{
				minHeight: '40vh',
				background: colors['brp-white'].main,
				justifyContent: 'flex-start',
				marginTop: 1,
				marginLeft: 'auto',
				marginRight: 'auto',
				flexDirection: 'column',
				paddingX: 3,
				paddingBottom: 2,
			}}
		>
			<Typography variant="h3" component="h1">
				{translations.parts_finder.title}
			</Typography>
			<section
				style={{
					width: '100px',
					height: '4px',
					backgroundColor: colors['brp-yellow'].main,
					marginLeft: '50px',
					marginBottom: '32px',
				}}
			></section>
			<Typography
				variant="body1"
				component="p"
				sx={{ marginBottom: 2 }}
				maxWidth="md"
			>
				{translations.parts_finder.paragraph_1}
			</Typography>
			<Link
				rel="noreferrer"
				target="_blank"
				to="https://epc.brp.com/SiteMods/BRP_Public/BRP_Public_Login.aspx?ReturnUrl=%2fIndex.aspx"
				style={{
					marginBottom: 32,
					width: 'max-content',
					fontWeight: 'bolder',
					borderBottom: `2px solid ${colors['brp-yellow'].main}`,
				}}
			>
				{translations.parts_finder.button_1}
			</Link>
			<Typography
				variant="body1"
				component="p"
				sx={{ marginBottom: 2 }}
				maxWidth="md"
			>
				{translations.parts_finder.paragraph_2}
			</Typography>
			<Link
				rel="noreferrer"
				target="_blank"
				to="#"
				style={{
					width: 'max-content',
					fontWeight: 'bolder',
					borderBottom: `2px solid ${colors['brp-yellow'].main}`,
				}}
			>
				{translations.parts_finder.button_2}
			</Link>
		</Grid>
	);
};
