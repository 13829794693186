import { Grid, Typography } from '@mui/material';
import {
	Category,
	SelectedCategory,
	fetchProducts,
	store,
	updateBreadcrumbs,
	updateDisplayableProducts,
	updateProductFetchStatus,
} from '../../../redux';
import { Link } from 'react-router-dom';

export const getBreadcrumbs = (
	breadcrumbItems,
	navigate,
) => {
	const breadcrumbs = breadcrumbItems.map(
		(item, index: number) => {
			return (
				<Grid
					key={item.path}
					container
					sx={{ width: 'max-content' }}
				>
					{index > 0 ? (
						<Typography variant="h6" sx={{ paddingX: 1 }}>
							/
						</Typography>
					) : null}
					<Link to={item.path}>
						<Typography
							sx={{ cursor: 'pointer' }}
							variant="h6"
							onClick={() => {
								store.dispatch(
									updateBreadcrumbs(item.name),
								);
								if (index === 0) {
									store.dispatch(
										updateProductFetchStatus('loading'),
									);
									store.dispatch(
										updateDisplayableProducts(undefined),
									);
									const productItems =
										store.getState().products.items;
									if (productItems.length === 0) {
										store.dispatch(fetchProducts());
									}
									setTimeout(() => {
										store.dispatch(
											updateProductFetchStatus('fulfilled'),
										);
									}, 1000);
									// fetch products if none
									navigate('/shop/products');
								} else {
									navigate(item.path);
								}
							}}
						>
							{item.name}
						</Typography>
					</Link>
				</Grid>
			);
		},
	);
	return (
		<Grid container marginBottom={1} sx={{ paddingX: 3 }}>
			{breadcrumbs}
		</Grid>
	);
};

export const generateParentBreadcrumbs = (
	categories,
	selected,
	breadcrumbs: any[] = [],
) => {
	if (selected && selected.parent_id) {
		const parentCategory = categories.find(
			(category) => category.id === selected.parent_id,
		);

		if (parentCategory) {
			const breadcrumb = {
				name: parentCategory.name,
				path: `/shop/products?category=${parentCategory.id}`,
				category_id: parentCategory.id,
				hasChildren: parentCategory.children.length > 0,
			};

			breadcrumbs.splice(1, 0, breadcrumb);

			// Recursive call to handle parent's parent
			generateParentBreadcrumbs(
				categories,
				parentCategory,
				breadcrumbs,
			);
		}
	}
	console.log(breadcrumbs);
	return breadcrumbs;
};

export const getBreadcrumbs_old = (breadcrumbItems) => {
	const breadcrumbs = breadcrumbItems.map(
		(item, index: number) => {
			return (
				<Grid container sx={{ width: 'max-content' }}>
					{index > 0 ? (
						<Typography variant="h6" sx={{ paddingX: 1 }}>
							/
						</Typography>
					) : null}
					{/* <Link to={item.path}> */}
					<Typography
						sx={{ cursor: 'pointer' }}
						variant="h6"
						onClick={() =>
							store.dispatch(updateBreadcrumbs(item.name))
						}
					>
						{item.name}
					</Typography>
					{/* </Link> */}
				</Grid>
			);
		},
	);
	return (
		<Grid container marginBottom={1} sx={{ paddingX: 2 }}>
			{breadcrumbs}
		</Grid>
	);
};

const createCategory = (
	category: Category,
	parentId?: number,
) => {
	if (category?.children?.length > 0) {
		return {
			...category,
			path: `/shop/products?category=${category.id}`,
			children: category.children.map((categ) =>
				createCategory(categ, categ.id),
			),
		} as SelectedCategory;
	}
	return {
		...category,
		path: `/shop/products?category=${category.id}`,
	} as SelectedCategory;
};
// could probably factor away the wrapper function
export const getRecurringCategories = (
	category: Category,
) => {
	return createCategory(category, undefined);
};
