import * as Yup from 'yup';

export const passwordResetValidationSchema = (
	translations,
) =>
	Yup.object().shape({
		password: Yup.string()
			.required(translations.validation.password.required)
			.min(8, translations.validation.password.min_length),
		password_confirmation: Yup.string()
			.required(
				translations.validation.password
					.confirmation_required,
			)
			.oneOf(
				[Yup.ref('password')],
				translations.validation.password.confirmation_match,
			),
	});
export const signUpValidationSchema = (translations) =>
	Yup.object().shape({
		first_name: Yup.string().required(
			translations.validation.generic.first_name,
		),
		last_name: Yup.string().required(
			translations.validation.generic.last_name,
		),
		email: Yup.string()
			.email(translations.validation.generic.email_invalid)
			.required(translations.validation.generic.email),
		password: Yup.string()
			.required(translations.validation.password.required)
			.min(8, translations.validation.password.min_length),
		password_confirmation: Yup.string()
			.required(
				translations.validation.password
					.confirmation_required,
			)
			.oneOf(
				[Yup.ref('password')],
				translations.validation.password.confirmation_match,
			),
		agreeTerms: Yup.boolean().oneOf(
			[true],
			translations.checkbox.checkout_t_and_c_not_checked,
		),
	});

export const personalDetailsValidationSchema = (
	prompts,
	currentLocale,
	isBusiness?: boolean,
) =>
	Yup.object().shape({
		first_name: isBusiness
			? Yup.string()
			: Yup.string().required(
					prompts[currentLocale].validation.generic
						.first_name,
			  ),
		last_name: isBusiness
			? Yup.string()
			: Yup.string().required(
					prompts[currentLocale].validation.generic
						.last_name,
			  ),
		email: Yup.string()
			.email(
				prompts[currentLocale].validation.generic
					.email_invalid,
			)
			.required(
				prompts[currentLocale].validation.generic.email,
			),
		isBusiness: Yup.boolean(),
		company_name: isBusiness
			? Yup.string().required(
					prompts[currentLocale].validation.generic
						.company_name,
			  )
			: Yup.string(),
		tax_number: isBusiness
			? Yup.string().required(
					prompts[currentLocale].validation.generic
						.tax_number,
			  )
			: Yup.string(),
	});

export const phoneNumberValidation = (
	prompts,
	currentLocale,
) =>
	Yup.object({
		shipping: Yup.object({
			phone_number: Yup.string()
				.required(
					prompts[currentLocale].validation.generic.phone,
				)
				.matches(
					/^(\+36|36|06)(20|30|31|70|50|51)\d{7}$/,
					prompts[currentLocale].validation.generic
						.phone_invalid,
				),
		}),
	});

export const deliveryDetailsValidationSchema = (
	prompts,
	currentLocale,
	hasPhone = false,
) =>
	Yup.object({
		hasPhone: Yup.boolean(),
		shipping: Yup.object({
			street: Yup.string().required(
				prompts[currentLocale].validation.shipping_billing
					.street,
			),
			city: Yup.string().required(
				prompts[currentLocale].validation.shipping_billing
					.city,
			),
			post_code: Yup.string()
				.required(
					prompts[currentLocale].validation.shipping_billing
						.post_code,
				)
				.min(
					4,
					prompts[currentLocale].validation.shipping_billing
						.post_code_min,
				),
			phone_number: Yup.string()
				.required(
					prompts[currentLocale].validation.generic.phone,
				)
				.matches(
					/^(\+36|36|06)(20|30|31|70|50|51)\d{7}$/,
					prompts[currentLocale].validation.generic
						.phone_invalid,
				),
			// phone_number: hasPhone
			// 	? Yup.string().when(
			// 			'hasPhone',
			// 			(hasPhone, schema) => {
			// 				if (hasPhone) {
			// 					return schema
			// 						.required(
			// 							prompts[currentLocale].validation
			// 								.generic.phone,
			// 						)
			// 						.matches(
			// 							/^(\+36|36|06)(20|30|31|70|50|51)\d{7}$/,
			// 							prompts[currentLocale].validation
			// 								.generic.phone_invalid,
			// 						);
			// 				}
			// 				return schema;
			// 			},
			// 	  )
			// 	: Yup.string(),
			// country: Yup.string().required('Country is required'),
		}),
		delivery_method_id: Yup.string().required(
			prompts[currentLocale].validation.shipping_billing
				.delivery_method_id,
		),
	});

export const billingDetailsValidationSchema = (
	prompts,
	currentLocale,
) =>
	Yup.object({
		billing: Yup.object({
			street: Yup.string().required(
				prompts[currentLocale].validation.shipping_billing
					.street,
			),
			city: Yup.string().required(
				prompts[currentLocale].validation.shipping_billing
					.city,
			),
			post_code: Yup.string()
				.required(
					prompts[currentLocale].validation.shipping_billing
						.post_code,
				)
				.min(
					4,
					prompts[currentLocale].validation.shipping_billing
						.post_code_min,
				),
		}),
	});

export const contactValidationSchema = (translations) =>
	Yup.object().shape({
		first_name: Yup.string().required(
			translations.validation.generic.first_name,
		),
		last_name: Yup.string().required(
			translations.validation.generic.last_name,
		),
		email: Yup.string()
			.email(translations.validation.generic.email_invalid)
			.required(translations.validation.generic.email),
		message: Yup.string().required(
			translations.validation.generic.message,
		),
	});
