import React from 'react';
import { ReactComponent as Seadoo } from './seadoo-wave.svg';
import {
	Button,
	Container,
	Grid,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import seadoo from './seadoo.png';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import styles from './subscription.module.scss';
import seadooWave from './seadoo-wave.svg';
import { colors } from '../../themes';
import canam from './CanAm.png';
import { useSelector } from 'react-redux';
import { RootState, store, subscribe } from '../../redux';
import { LoadingButton } from '@mui/lab';

export const SubscriptionForm: React.FC<any> = () => {
	const initialValues = {
		email: '',
	};

	const onSubmit = async (
		values,
		{ setSubmitting, resetForm },
	) => {
		// You can handle form submission logic here
		if (values.email) {
			const response = await store.dispatch(
				subscribe({
					email: values.email,
					showResponse: true,
				}),
			);
			setSubmitting(false);
			if (response.payload === 'successful') {
				resetForm();
			}
		}
	};
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];

	return (
		<Container
			className={styles.subscriptionContainer}
			sx={{ maxWidth: { xs: '100%', sm: '50%' } }}
		>
			<Container
				className={styles.subscriptionTextContainer}
				style={{
					display: 'flex',
				}}
			>
				<Typography
					variant="h4"
					component="h2"
					color="brp-black"
					sx={{ textTransform: 'uppercase' }}
				>
					{translations.text.subscription_title_1}
					{/* <br /> */}
					{translations.text.subscription_title_2}
				</Typography>
				<Typography
					variant="body1"
					sx={{ my: 1 }}
					color="brp-black"
				>
					{translations.text.subscription_detail}
				</Typography>
				<Formik
					initialValues={initialValues}
					onSubmit={onSubmit}
				>
					{({ isSubmitting }) => (
						<Form
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Field
								type="email"
								label={translations.forms.email}
								variant="outlined"
								size="small"
								as={TextField}
								name="email"
							/>
							<LoadingButton
								type="submit"
								variant="brp-yellow"
								sx={{
									marginTop: 2,
									textTransform: 'capitalize',
								}}
								loading={isSubmitting}
							>
								{translations.buttons.subscribe_now}
							</LoadingButton>
						</Form>
					)}
				</Formik>
			</Container>
		</Container>
	);
};

export const SeaDooSubscription: React.FC<any> = React.memo(
	() => {
		return (
			<Grid
				container
				style={{
					// height: '40vh',
					maxHeight: '720px',
					display: 'flex',
					// position: 'relative',
					// background: 'rgb(44,213,196)',
					background:
						'linear-gradient(195deg, rgba(44,213,196,1) 0%, rgba(197,232,108,1) 100%)',
				}}
			>
				<Grid
					item
					xs={12}
					sm={7}
					sx={{
						display: { xs: 'none', lg: 'flex' },
						flex: 1,
					}}
				>
					<svg
						width="100%"
						viewBox="0 0 1000 500"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<defs>
							<clipPath id="seadooClip">
								<path
									d="M0 0H997C987.181 56.4779 982.643 102.18 864.325 135.25C767.386 162.345 726.432 185.872 731.651 270.5C735.889 339.224 715.014 372.137 598.976 405.75C508.263 432.027 573.734 523.997 466.302 541H0V0Z"
									fill="url(#paint0_linear_27_2318)"
								/>
							</clipPath>
							<linearGradient
								id="paint0_linear_27_2318"
								x1="498.5"
								y1="0"
								x2="498.5"
								y2="541"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#2CD5C4" />
								<stop offset="1" stopColor="#C5E86C" />
							</linearGradient>
						</defs>
						<image
							x="0"
							y="0"
							width="100%"
							height="500"
							xlinkHref={seadoo}
							clipPath="url(#seadooClip)"
						/>
					</svg>
				</Grid>
				<Grid
					container
					sx={{
						position: 'relative',
						justifyContent: 'flex-end',
						display: 'flex',
						flex: { xs: 0, lg: 1 },
						paddingY: 2,
					}}
				>
					<SubscriptionForm />
				</Grid>
			</Grid>
		);
	},
);
export const CanAmSubscription: React.FC<any> = React.memo(
	() => {
		const theme = useTheme();
		const isSmallScreen = useMediaQuery(
			theme.breakpoints.down('sm'),
		);
		return (
			<Grid
				container
				style={{
					maxHeight: '720px',
					display: 'flex',
					background: colors['brp-black'].light,
				}}
			>
				<Grid
					container
					maxWidth="lg"
					sx={{
						position: 'relative',
						justifyContent: 'center',
						alignItems: 'center',
						display: 'flex',
						flex: 1,
						paddingY: 4,
						margin: '0 auto',
					}}
				>
					{isSmallScreen ? null : (
						<img
							src={canam}
							style={{ maxWidth: '50%', maxHeight: 400 }}
						/>
					)}
					<SubscriptionForm buttonColour="brp-yellow" />
				</Grid>
			</Grid>
		);
	},
);
