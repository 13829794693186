import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Fade, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	RootState,
	addToCart,
	resetUpdatedQuantityForProduct,
} from '../../redux';
import { useEffect, useState } from 'react';

export const AddToCart = ({
	id,
	isShop = false,
	style = {},
}) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const ButtonContent = () => (
		<Fade in={showButton}>
			<Grid
				container
				padding={0}
				alignItems="center"
				justifyContent="center"
			>
				<FontAwesomeIcon
					icon={faShoppingCart}
					style={{ fontSize: 20, marginRight: 8 }}
				/>
				<p>{prompts[currentLocale].shop.add_to_cart}</p>
			</Grid>
		</Fade>
	);

	const [showButton, setShowButton] = useState(true);
	const [buttonContent, setButtonContent] = useState<
		string | any
	>(<ButtonContent />);

	const contentChangeDebounce = () => {
		setShowButton(false);
		setButtonContent(
			prompts[currentLocale].shop.added_to_cart,
		);

		setTimeout(() => {
			setButtonContent(<ButtonContent />);
			setShowButton(true);
		}, 1500);
	};
	const dispatch = useDispatch();

	useEffect(() => {
		setButtonContent(<ButtonContent />);
	}, [currentLocale]);

	// todo: try and trigger a drawer to open from the right
	// to show cart for a nice ui/ux
	// also needs to reset the quantity for the specified productId
	const handleClick = () => {
		contentChangeDebounce();
		dispatch(addToCart({ id }));
		// todo: implement this reducer below
		// it should reset the quantityButton's counter to 1
		// after adding item to cart
		dispatch(resetUpdatedQuantityForProduct({ id }));
	};

	return (
		<Button
			variant={isShop ? 'brp-white' : 'brp-yellow'}
			size={isShop ? 'small' : 'medium'}
			onClick={handleClick}
			style={{
				...style,
				pointerEvents: !showButton ? 'none' : 'auto',
			}}
			sx={
				isShop ? { marginTop: '8px', width: '100%' } : null
			}
		>
			{buttonContent}
		</Button>
	);
};
