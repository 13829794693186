import {
	Dialog,
	Grid,
	IconButton,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {
	ProductCard,
	ProductPagination,
	ProductsSkeleton,
	QuoteRequestDialog,
	VehicleCard,
	VehiclePagination,
} from '../../components';
import {
	RootState,
	VehicleDetails,
	fetchVehicleCategory,
	fetchVehicles,
	getProductQuantity,
	getUniqueAttributeValues,
	handleViewChange,
	store,
} from '../../redux';
import { useEffect, useState } from 'react';
import {
	Filter,
	PageSizeDropdown,
	ProductView,
	Sort,
	VehicleCategories,
	getBreadcrumbs,
} from './components';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import styles from './shop.module.scss';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { parseFilterParametersFromUrl } from './helpers';

const NoItem = ({ translations }) => (
	<Grid container padding={4}>
		<Typography variant="h6">
			{translations.shop.no_item}
		</Typography>
	</Grid>
);

export const Shop = ({
	isVehicles = false,
}: {
	isVehicles?: boolean;
}) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const areVehiclesBeingFetched = useSelector(
		(state: RootState) =>
			state.generic.vehicles.isBeingFetched,
	);
	const [isFilterDialogOpen, setIsFilterDialogOpen] =
		useState(false);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const isBelowLargeScreen = useMediaQuery(
		theme.breakpoints.down('lg'),
	);
	const isGridView = useSelector(
		(state: RootState) => state.shop.isGridView,
	);

	const [isLoading, setIsLoading] = useState(false);
	const areProductsBeingFetched = useSelector(
		(state: RootState) =>
			// should this be whilst status is equal to loading?
			state.products.status !== 'fulfilled',
	);
	const products = useSelector(
		(state: RootState) =>
			state.products.displayable ?? state.products.items,
	);

	const vehicles = useSelector(
		(state: RootState) => state.generic.vehicles,
	);

	const getVehicles = () => {
		const vehiclesToUse = Array.isArray(
			vehicles.selectedCategory,
		)
			? vehicles.selectedCategory
			: Array.isArray(vehicles.details)
			  ? vehicles.details
			  : [];
		if (vehiclesToUse.length > 0) {
			const vehiclesToDisplay = vehiclesToUse.map(
				(vehicle: VehicleDetails) => {
					return (
						<Grid
							item
							xs={6}
							xl={isGridView ? 3 : 12}
							// filter container is 3
							lg={isGridView ? 4 : 12}
							md={isGridView ? 6 : 12}
							key={vehicle.id}
						>
							<VehicleCard vehicle={vehicle} />
						</Grid>
					);
				},
			);
			return vehiclesToDisplay;
		}
		return [];
	};

	const itemsToBeDisplayed = isVehicles ? (
		getVehicles()
	) : products.length > 0 ? (
		products.map((product) => {
			const productQuantity: number = getProductQuantity(
				store.getState(),
				product.id,
			);
			return (
				<Grid
					item
					xs={6}
					xl={isGridView ? 3 : 12}
					// filter container is 3
					lg={isGridView ? 4 : 12}
					md={isGridView ? 6 : 12}
					key={product.id}
				>
					<ProductCard
						productQuantity={productQuantity}
						// current={productQuantity}
						product={product}
						isShop
					/>
				</Grid>
			);
		})
	) : (
		<NoItem translations={prompts[currentLocale]} />
	);
	const isQuoteRequestFormShown = useSelector(
		(state: RootState) =>
			state.generic.vehicles.isQuoteRequestFormShown,
	);

	const toggleFilterDialog = () => {
		setIsFilterDialogOpen((prevValue) => !prevValue);
	};
	const navigate = useNavigate();

	const { breadcrumbs } = useSelector(
		(state: RootState) => state.generic.categories,
	);

	useEffect(() => {
		store.dispatch(getUniqueAttributeValues());
	}, []);
	//should this be recalculated?
	// }, [itemsToBeDisplayed]);

	useEffect(() => {
		if (isVehicles) {
			const queryParams = new URLSearchParams(
				location.search,
			);
			const categoryId = queryParams.get('category');
			categoryId
				? store.dispatch(fetchVehicleCategory(categoryId))
				: store.dispatch(fetchVehicles());
		}
	}, []);

	useEffect(() => {
		if (isBelowLargeScreen && !isGridView) {
			store.dispatch(handleViewChange('grid'));
		}
	}, [isBelowLargeScreen]);

	const location = useLocation();
	useEffect(() => {
		window.history.replaceState({}, document.title);
		const previousPath = location.state?.from?.pathname;
		parseFilterParametersFromUrl(previousPath);
	}, []);

	return (
		<Grid
			container
			maxWidth="lg"
			sx={{
				margin: '0 auto',
				justifyContent: 'space-between',
			}}
		>
			{isVehicles ? null : (
				<Grid container width="100%">
					{getBreadcrumbs(breadcrumbs, navigate)}
				</Grid>
			)}
			{isSmallScreen ? (
				<Dialog
					open={isFilterDialogOpen}
					onClose={toggleFilterDialog}
					fullScreen
					sx={{ zIndex: 999 }}
				>
					<Toolbar
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<IconButton
							edge="start"
							color="inherit"
							onClick={toggleFilterDialog}
							aria-label="close"
						>
							<GridCloseIcon />
						</IconButton>
						<PageSizeDropdown />
					</Toolbar>
					{isVehicles ? null : (
						<Grid
							container
							paddingX={4}
							flexDirection="column"
						>
							<Typography
								variant="h6"
								component="span"
								style={{ marginBottom: '12px' }}
							>
								{prompts[currentLocale].sort.label}
							</Typography>
							<Sort
								isColumnDisplay={true}
								setIsLoading={setIsLoading}
							/>
						</Grid>
					)}
					{isVehicles ? (
						<VehicleCategories />
					) : (
						<Filter setIsLoading={setIsLoading} />
					)}
				</Dialog>
			) : isVehicles ? (
				<VehicleCategories />
			) : (
				<Filter setIsLoading={setIsLoading} />
			)}
			<Grid
				item
				xs={12}
				md={isVehicles ? 10 : 8}
				lg={isVehicles ? 10 : 9}
				className={styles.gridContainer}
				sx={{ paddingLeft: { lg: 2 } }}
			>
				<Grid
					container
					sx={{
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: isSmallScreen ? 2 : 0,
						paddingBottom: 0,
						marginBottom: isSmallScreen ? 0 : 2,
					}}
				>
					<Typography variant="h4" component="h4">
						{isVehicles
							? prompts[currentLocale].shop.vehicles
							: prompts[currentLocale].shop.all_products}
					</Typography>
					{isBelowLargeScreen ? (
						<FontAwesomeIcon
							style={{
								fontSize: '24px',
								marginTop: '-5px',
								marginRight: 16,
							}}
							icon={faSlidersH}
							onClick={toggleFilterDialog}
						/>
					) : (
						<Grid
							container
							alignItems="center"
							sx={{
								width: 'max-content',
							}}
						>
							<ProductView />
							<PageSizeDropdown />
						</Grid>
					)}
				</Grid>
				{isSmallScreen || isVehicles ? null : (
					<Grid
						item
						md={12}
						height="max-content"
						display="flex"
						alignItems="start"
						justifyContent="space-between"
					>
						<Sort setIsLoading={setIsLoading} />
					</Grid>
				)}
				<Grid
					container
					className={styles.productContainer}
					// spacing={isSmallScreen ? 0 : 2}

					// maybe make it so that its 2 visible on mobile phones
					// xs={
					// 	{
					// 	}
					// }
				>
					{isLoading ||
					areProductsBeingFetched ||
					areVehiclesBeingFetched ? (
						<ProductsSkeleton />
					) : Array.isArray(itemsToBeDisplayed) &&
					  itemsToBeDisplayed.length > 0 ? (
						itemsToBeDisplayed
					) : (
						<NoItem translations={prompts[currentLocale]} />
					)}
					<QuoteRequestDialog
						open={isQuoteRequestFormShown}
					/>
				</Grid>
				{Array.isArray(itemsToBeDisplayed) &&
				itemsToBeDisplayed.length > 0 ? (
					isVehicles ? (
						<VehiclePagination />
					) : (
						<ProductPagination />
					)
				) : null}
			</Grid>
		</Grid>
	);
};
