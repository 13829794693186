import {
	Autocomplete,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	InputAdornment,
	Paper,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFileInvoice,
	faTruck,
} from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../../themes';
import {
	DeliveryDetails as DeliveryDetailsType,
	RootState,
	getIsBillingSame,
	setBillingIsSameAsShipping,
	store,
} from '../../../redux';
import { useSelector } from 'react-redux';
import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { DeliveryMethodDetails } from './DeliveryMethodDetails';
import { RenderErrors } from './components';
import { CountryDropdown } from '../components';
import {
	LockerMap,
	PlacesAutocomplete,
} from './components/Map';
import { SelectedLocker } from './helpers';
import hungary from '../../../assets/hungarian-flag.svg';
import { useLoadScript } from '@react-google-maps/api';
import InputMask from 'react-input-mask';

const libraries: any = ['places'];

export const DeliveryDetails = forwardRef(
	(
		{
			isCheckout = false,
			isDelivery = false,
			ddFormik: formik,
			prompts,
			currentLocale,
			isLocker = false,
			isCod = false,
		}: {
			isCheckout?: boolean;
			isDelivery?: boolean;
			isLocker?: boolean;
			isCod?: boolean;
			ddFormik: any;
			prompts: any;
			currentLocale: string;
		},
		parentRef,
	) => {
		const [selectedAddress, setSelectedAddress] =
			useState() as [any, any];

		const { isLoaded } = useLoadScript({
			googleMapsApiKey:
				process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY!,
			libraries,
		});

		useEffect(() => {
			if (selectedAddress)
				for (const [key, value] of Object.entries(
					selectedAddress,
				)) {
					formik.setFieldValue(`${prefix}.${key}`, value);
				}
		}, [selectedAddress]);

		// todo: there is an issue that the phone_number is not
		// validated when the shipping is the same as billing
		const theme = useTheme();
		const isSmallScreen = useMediaQuery(
			theme.breakpoints.down('md'),
		);
		const childFormikRef = useRef<any>(null);

		const isBillingSame = useSelector(getIsBillingSame);
		const prefix = isDelivery ? 'shipping' : 'billing';

		const selectedLocker = useSelector(
			(state: RootState) =>
				state.checkout.parcelShops.selected,
		);
		useImperativeHandle(parentRef, () => ({
			submitForm: () => {
				if (childFormikRef.current) {
					//@ts-ignore
					childFormikRef.current.submitForm();
				}
			},
			validateForm: async () => {
				if (childFormikRef.current) {
					//@ts-ignore
					return await childFormikRef.current.validateForm();
				}
			},
		}));

		const handleCheckboxChange = ({ target }) => {
			store.dispatch(
				setBillingIsSameAsShipping(target.checked),
			);
		};

		const {
			items: countries,
			isBeingFetched: countriesFetched,
		} = useSelector(
			(state: RootState) => state.checkout.countries,
		);

		return (
			<Paper
				variant="outlined"
				square
				id={
					isDelivery
						? 'delivery-details'
						: 'billing-details'
				}
				sx={
					isSmallScreen
						? { padding: 2, border: 'none' }
						: {
								padding: 4,
								marginBottom: 4,
						  }
				}
				style={
					isCheckout
						? {}
						: {
								marginBottom: 0,
								padding: 0,
								border: 'none',
						  }
				}
			>
				<Formik
					innerRef={childFormikRef}
					initialValues={formik.initialValues}
					onSubmit={formik.handleSubmit}
				>
					{() => (
						<Form>
							{isCheckout ? (
								<Grid
									container
									sx={{
										alignItems: 'flex-start',
										justifyContent: 'space-between',
									}}
								>
									<Grid
										alignItems="center"
										minWidth="200px"
										container
										sx={{
											width: 'max-content',
											marginBottom: 2,
										}}
									>
										<FontAwesomeIcon
											icon={
												isDelivery ? faTruck : faFileInvoice
											}
											style={{
												padding: 8,
												marginRight: 8,
												background:
													colors['brp-yellow'].main,
											}}
										/>
										<Typography
											variant="body1"
											component="span"
											sx={{
												textTransform: 'capitalize',
												color: colors['brp-black'].main,
											}}
										>
											{isDelivery
												? prompts[currentLocale].navigation
														.delivery_details
												: prompts[currentLocale].navigation
														.billing_details}
										</Typography>
									</Grid>

									{/* <LoadingButton
										type="submit"
										loading={isSubmitting}
										variant="brp-yellow-checkout"
									>
										<span>
											{prompts[currentLocale].buttons.save}
										</span>
									</LoadingButton> */}
								</Grid>
							) : null}
							{!isBillingSame || !isDelivery ? (
								<Grid container>
									{isLoaded ? (
										<PlacesAutocomplete
											isAutofill={true}
											setSelected={setSelectedAddress}
											translations={prompts[currentLocale]}
										/>
									) : null}

									<RenderErrors errors={formik.errors} />
									<Grid
										container
										columnGap={2}
										flexDirection={
											isSmallScreen ? 'column' : 'row'
										}
									>
										<Field
											style={{ marginBottom: 16, flex: 1 }}
											name={`${prefix}.street`}
											as={TextField}
											fullWidth
											value={formik.values[prefix].street}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											required
											label={
												prompts[currentLocale].forms.street
											}
											placeholder={
												prompts[currentLocale].placeholder
													.street
											}
										/>
										<Field
											style={{ marginBottom: 16, flex: 1 }}
											name={`${prefix}.street_extra`}
											value={
												formik.values[prefix].street_extra
											}
											as={TextField}
											fullWidth
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											label={
												prompts[currentLocale].forms
													.street_extra
											}
											placeholder={
												prompts[currentLocale].placeholder
													.street_extra
											}
										/>
									</Grid>
									<Grid
										container
										columnGap={2}
										flexDirection={
											isSmallScreen ? 'column' : 'row'
										}
									>
										<Field
											style={{ marginBottom: 16, flex: 1 }}
											name={`${prefix}.city`}
											value={formik.values[prefix].city}
											as={TextField}
											fullWidth
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											required
											label={
												prompts[currentLocale].forms.city
											}
											placeholder={
												prompts[currentLocale].placeholder
													.city
											}
										/>
										<Field
											style={{ marginBottom: 16, flex: 1 }}
											name={`${prefix}.post_code`}
											value={
												formik.values[prefix].post_code
											}
											as={TextField}
											fullWidth
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											required
											label={
												prompts[currentLocale].forms
													.post_code
											}
											placeholder={
												prompts[currentLocale].placeholder
													.post_code
											}
										/>
									</Grid>
									<Grid container columnGap={2}>
										<CountryDropdown
											style={{ marginBottom: 16, flex: 1 }}
											countries={countries}
										/>
									</Grid>
								</Grid>
							) : null}
							{!isDelivery || !isCheckout ? null : (
								<>
									<DeliveryMethodDetails formik={formik} />

									<Grid
										container
										sx={{
											padding: 0,
											margin: 0,
											marginTop: 2,
										}}
										flexDirection="column"
									>
										<Typography
											variant="body1"
											component="p"
											sx={{
												paddingBottom: 0,
												marginBottom: 1,
											}}
										>
											{
												prompts[currentLocale].text
													.phone_number_delivery
											}
										</Typography>
										<RenderErrors
											isTandC
											errors={formik.errors}
										/>
										<InputMask
											mask="+36999999999"
											value={
												formik.values.shipping.phone_number
											}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
										>
											{() => (
												<Field
													name="shipping.phone_number"
													sx={{
														marginTop: 0,
														width: {
															xs: '100%',
															md: '49%',
														},
													}}
													as={TextField}
													fullWidth
													margin="normal"
													required
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<img
																	src={hungary}
																	style={{
																		height: '32px',
																		width: '32px',
																	}}
																/>
															</InputAdornment>
														),
													}}
													value={
														formik.values.shipping
															.phone_number
													}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													label={
														prompts[currentLocale].forms
															.phone_number
													}
													placeholder={
														prompts[currentLocale]
															.placeholder.phone_number
													}
												/>
											)}
										</InputMask>
									</Grid>

									{isLocker &&
									selectedLocker === undefined ? (
										<LockerMap />
									) : isLocker && selectedLocker ? (
										<SelectedLocker
											lockerDetail={selectedLocker}
										/>
									) : null}
								</>
							)}
							{!isCheckout ? null : isDelivery ? null : (
								<FormControlLabel
									control={
										<Checkbox
											checked={isBillingSame}
											onChange={handleCheckboxChange}
											name="isBillingSame"
											color="primary"
										/>
									}
									label={
										prompts[currentLocale].text
											.delivery_is_same
									}
								/>
							)}
						</Form>
					)}
				</Formik>
			</Paper>
		);
	},
);
