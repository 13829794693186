import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Typography,
} from '@mui/material';
import {
	RootState,
	fetchFilteredProducts,
	fetchProducts,
	store,
	updatePageSize,
} from '../../../redux';
import { useSelector } from 'react-redux';
export const PageSizeDropdown = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const pageSize = useSelector(
		(state: RootState) => state.products.pageSize,
	);
	const handleChange = ({ target }) => {
		const { value } = target;
		store.dispatch(updatePageSize(value));
		store.dispatch(
			fetchFilteredProducts({
				filters: store.getState().products.filtersApplied,
				sort: store.getState().products.sortApplied,
			}),
		);
	};
	return (
		<FormControl
			size="small"
			sx={{
				display: 'flex',
				flexDirection: 'row',
				marginBottom: '-10px',
				paddingRight: { xs: 0, md: 2 },
			}}
			variant="standard"
		>
			<Typography variant="body1" sx={{ marginRight: 1 }}>
				{translations.text.page_size}
			</Typography>
			{/* todo: perhaps use <NativeSelect /> */}
			<Select
				value={pageSize}
				sx={{ paddingBottom: 0 }}
				onChange={handleChange}
			>
				<MenuItem value={12}>12</MenuItem>
				<MenuItem value={24}>24</MenuItem>
				<MenuItem value={36}>36</MenuItem>
			</Select>
		</FormControl>
	);
};
